import React from "react"
import Turbolinks from "turbolinks"

import { putRequest } from "../helpers/api"

interface Props {
  url: string
  params: { [s: string]: string }
}

const DismissAction = ({ url, params }: Props) => {
  return (
    <button
      className="button"
      onClick={async () => {
        await putRequest(url, params)
        Turbolinks.visit()
      }}
    >
      Dismiss
    </button>
  )
}

export default DismissAction
