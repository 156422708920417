type SortingFields = { desc: boolean, id: string }[]

const sortingString = (sortBy: SortingFields): string => (
  sortBy.map(field => `${field.desc ? "-" : ""}${field.id}`).join(",")
)

export const makeDefaultTableState = (state = {}) => {
  return Object.assign({
    // Table state
    sorted: [],
    page: 0,
    pageSize: 5,
    minRows: 5,
    expanded: {},
    resized: [],
    filtered: [],
    searchQuery: "",

    // Data
    data: [],
    isLoading: true,
    meta: {}
  }, state)
}

export const buildParams = (state) => {
  const params: { [s: string]: any } = {
    page: state.page + 1,
    per_page: state.pageSize,
    sort_by: sortingString(state.sorted),
    query: state.searchQuery,
  }

  const livingFilter = state.filtered.find(f => f.id === "living")

  if (livingFilter) {
    params.status = livingFilter.value
  }

  return params
}

export default {
  buildParams,
  makeDefaultTableState
}
