import React from "react"
import humanize from "string-humanize"
import { parse as parseDate, format } from "date-fns"
import { connect } from "react-refetch"

import { deleteRequest } from "../../helpers/api"

import Loading from "../Loading"
import MoreMenu from "../MoreMenu"

export const UsersTable = ({ currentRole, assignableRoles, usersFetch, onEdit, onUpdate, usersCount }) => {
  if (usersFetch.pending) return <Loading label="Fetching staff accounts" />
  if (usersFetch.error) return <div>Error fetching users.</div>

  usersCount(usersFetch.value.data.length);

  return (
    <div className="table-wrapper">
      <table className="static-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Last Login</th>
            <th>Date created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {usersFetch.value.data.map(user => (
            <tr key={user.id}>
              <td>{user.full_name}</td>
              <td>
                <a target="_blank" href={`mailto:${user.email}`}>{user.email}</a>
              </td>
              <td>{humanize(user.role)}</td>
              <td>
                {user.last_sign_in_at ?
                  <React.Fragment>
                    {format(parseDate(user.last_sign_in_at), "Do MMM YYYY")}
                  </React.Fragment>
                  :
                  <span className="light-silver">Hasn't signed in yet</span>
                }
              </td>
              <td>{format(parseDate(user.created_at), "Do MMM YYYY")}</td>
              <td>
                {(currentRole !== "user" && assignableRoles.includes(user.role)) &&
                  <MoreMenu>
                    {({ close }) => (
                      <React.Fragment>
                        <a
                          href="#"
                          className="db near-black no-underline dim fw6 f6 mb3"
                          onClick={e => {
                            e.preventDefault()
                            onEdit(user)
                            close()
                          }}
                        >
                          Edit
                        </a>
                        <a
                          href="#"
                          className="db near-black no-underline dim fw6 f6"
                          onClick={async e => {
                            e.preventDefault()
                            close()
                            if (!confirm("Are you sure?")) return
                            await deleteRequest(`/api/users/${user.id}`)
                            onUpdate()
                          }}
                        >
                          Delete
                        </a>
                      </React.Fragment>
                    )}
                  </MoreMenu>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(({ version }) => ({
  usersFetch: {
    url: `/api/users`,
    comparison: `users:${version}`
  }
}))(UsersTable)
