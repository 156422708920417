import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"

type Props = {
  type: string
  message: string
  onClose?(): void
}

const FlashAlert = ({ type, message, onClose }: Props) => {
  const [showing, setShowing] = useState(true)
  return (
    <CSSTransition
      in={showing}
      classNames="fade"
      timeout={300}
      unmountOnExit
    >
      <div className={`flash-alert -${type}`}>
        <span
          className="closebtn"
          onClick={() => {
            setShowing(false)
            onClose && onClose()
          }}
        >
          &times;
        </span>
        <div className="container">
          {message}
        </div>
      </div>
    </CSSTransition>
  )
}

export default FlashAlert
