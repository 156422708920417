import React from "react"
import { parse as dateFnsParse, format as dateFnsFormat } from "date-fns"
import DatePicker from "react-date-picker"

export function isDate(value) {
  return value instanceof Date && !isNaN(value.valueOf());
}

function parseDate(str: string): Date | null {
  try {
    const parsed = dateFnsParse(str)
    if (!isDate(parsed)) return null
    return parsed
  } catch(err) {
    return null
  }
}

function formatDate(date: Date, format: string, locale) {
  return dateFnsFormat(date, format, { locale })
}

interface Props {
  value: string
  onDayChange(value: Date): void
}

export default ({ value, onDayChange }: Props) => (
  <DatePicker
    value={parseDate(value)}
    onChange={date => onDayChange(date as Date)}
    format={"dd/MM/y"}
  />
)
