import React, { useState } from "react"
import Turbolinks from "turbolinks"
import UploadModal from "./UploadModal"

export default ({ clientId }) => {
  const [open, setOpen] = useState(false)
  return (
    <React.Fragment>
      <button className="button -primary -fullwidth" onClick={() => setOpen(true)}>Upload Document</button>

      <UploadModal
        hasDatepicker
        title="Upload Will"
        open={open}
        documentTypes={["will", "codicil"]}
        recordId={clientId}
        recordType="Client"
        group="will"
        onUploadComplete={() => Turbolinks.visit(window.location.pathname)}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  )
}
