import React, { useState, useReducer } from "react"
import { parse as parseDate, format as formatDate } from "date-fns"

import { dictionary, Valuation } from "../../../helpers/types"
import { formatCurrency } from "../../../helpers/string"
import ContentBox from "../../ContentBox"
import ValuationList from "../ValuationList"
import EditForm from "./EditForm"
import DocumentsTable from "../../DocumentsTable"
import UploadModal from "../../UploadModal"
import ValidationStatus from "../ValidationStatus"
import ValidateButton from "../ValidateButton"
import RenewalForm from "./RenewalForm"

interface Props {
  asset: dictionary
  valuations: Array<Valuation>
  role?: string
}

const Vehicle = ({ asset, valuations, role }: Props) => {
  const [showRenewalForm, setShowRenewalForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [docsVersion, incrementDocsVersion] = useReducer(state => state + 1, 0)

  return (
    <>
      <div className="flex flex-column flex-row-ns items-start-ns">
        <div className="flex-auto">
          <span className={`db light-silver fw4 f5 mb2 ttu -${asset.category_label}`}>{asset.category_label}</span>
          <h1 className="page-title">{asset.title}</h1>
        </div>
        {role === "admin" &&
          <ValidateButton id={asset.id} validated={!!asset.validated_at} />
        }
        <button onClick={() => setShowEditForm(true)} className="button -primary -with-icon mv4 mv0-ns">
          <i className="material-icons">edit</i>
          Edit asset
        </button>
      </div>

      <EditForm assetId={asset.id} values={asset} open={showEditForm} onClose={() => setShowEditForm(false)} />

      <ContentBox
        title="Financial"
        grid
        renderHeader={() => (
          <ValidationStatus
            type="asset"
            validated={asset.validated}
            validatedAt={asset.validated_at}
            invalidMessage="Upload V5 or finance agreement to validate."
          />
        )}
      >
        <ul className="data-grid">
          <li>
            <div>
              <strong>Type of Vehicle</strong><br />
              {asset.title}
            </div>
          </li>
          <li>
            <div>
              <strong>Colour</strong><br />
              {asset.colour}
            </div>
          </li>
          {asset.monthly_payment &&
            <li>
              <div>
                <strong>Monthly payment</strong><br />
                {asset.monthly_payment}
              </div>
            </li>
          }
          {asset.purchase_price &&
            <li>
              <div>
                <strong>Purchase amount</strong><br />
                {formatCurrency(asset.purchase_price)}
              </div>
            </li>
          }
        </ul>
      </ContentBox>

      {/* <ValuationList valuations={valuations} /> */}

      <ContentBox title="Vehicle details" grid collapsable extraClasses="mt5">
        <ul className="data-grid">
          <li>
            <div>
              <strong>License Plate</strong><br />
              {asset.vehicle_registration}
            </div>
          </li>
          {asset.year &&
            <li>
              <div>
                <strong>Year</strong><br />
                {asset.year}
              </div>
            </li>
          }
          <li>
            <div>
              <strong>Mileage</strong><br />
              {asset.mileage}
            </div>
          </li>
          <li>
            <div>
              <strong>Present value</strong><br />
              {asset.current_value ?
                formatCurrency(asset.current_value)
                :
                <span className="light-silver">&ndash;</span>
              }
            </div>
          </li>
        </ul>
      </ContentBox>

      <ContentBox title="Renewal dates" grid collapsable extraClasses="mt5">
        <p className="lh-copy">
          <strong>A vehicle can contribute to your overall net worth.</strong><br />
          Recording dates is important for renewal: insurance, tax, MOT or service dates. Having the knowledge of expiry dates in advance has the power to influence how you spend your money.
        </p>

        <ul className="data-grid">
          <li>
            <div>
              <strong>Road tax</strong><br />
              {asset.tax_renewal_date ?
                formatDate(parseDate(asset.tax_renewal_date), "DD/MM/YYYY")
              :
                <span className="light-gray">&ndash;</span>
              }
            </div>
          </li>
          <li>
            <div>
              <strong>MOT</strong><br />
              {asset.mot_renewal_date ?
                formatDate(parseDate(asset.mot_renewal_date), "DD/MM/YYYY")
              :
                <span className="light-gray">&ndash;</span>
              }
            </div>
          </li>
          <li>
            <div>
              <strong>Service</strong><br />
              {asset.service_date ?
                formatDate(parseDate(asset.service_date), "DD/MM/YYYY")
              :
                <span className="light-gray">&ndash;</span>
              }
            </div>
          </li>
          <li>
            <div>
              <strong>Insurance</strong><br />
              {asset.insurance_renewal_date ?
                formatDate(parseDate(asset.insurance_renewal_date), "DD/MM/YYYY")
              :
                <span className="light-gray">&ndash;</span>
              }
            </div>
          </li>
          <li>
            <div>
              <strong>Receive renewal reminders?</strong><br />
              {asset.renewal_reminders ? "Yes" : "No"}
            </div>
          </li>
        </ul>
        <div className="bt b--light-gray pv4">
          <button onClick={() => setShowRenewalForm(true)} className="button">
            Edit renewal dates
          </button>
          <RenewalForm
            assetId={asset.id}
            open={showRenewalForm}
            values={asset}
            onClose={() => setShowRenewalForm(false)}
          />
        </div>
      </ContentBox>

      <ContentBox title="Documents" collapsable extraClasses="mt5">
        <div className="mt4">
          <DocumentsTable
            recordId={asset.id}
            recordType="Asset"
            recordGroup=""
            version={docsVersion}
            onUpdate={incrementDocsVersion}
            onAddNew={() => setShowUploadModal(true)}
          />
        </div>
      </ContentBox>

      <UploadModal
        title="Add document"
        open={showUploadModal}
        documentTypes={["insurance_cover_letter", "v5"]}
        recordId={asset.id}
        recordType="Asset"
        onUploadComplete={incrementDocsVersion}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  )
}

export default Vehicle
