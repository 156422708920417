import React, { ReactNode } from "react"

type Props = {
  active: boolean
  className?: string
  children: ReactNode
  onClick(): void
}

const BigButton = ({ children, active, className, onClick }: Props) => {
  return (
    <button onClick={onClick} className={`pa3 f4 bg-white ${active ? 'b--blue near-black' : 'b--light-gray gray'} outline-0 ba bw1 br3 pointer ${className}`}>
      {children}
    </button>
  )
}

export default BigButton
