import React, { useState, useReducer } from "react"
import humanize from "string-humanize"

import { dictionary, Valuation } from "../../../helpers/types"
import ContentBox from "../../ContentBox"
import ValuationList from "../ValuationList"
import LandlordForm from "./LandlordForm"
import EditForm from "./EditForm"
import DocumentsTable from "../../DocumentsTable"
import UploadModal from "../../UploadModal"
import ValidationStatus from "../ValidationStatus"
import ValidateButton from "../ValidateButton"

interface Props {
  asset: dictionary
  valuations: Array<Valuation>
  role?: string
  mortgages: dictionary[]
}

const Property = ({ asset, mortgages, valuations, role }: Props) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const [showLandlordForm, setShowLandlordForm] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [docsVersion, incrementDocsVersion] = useReducer(state => state + 1, 0)

  return (
    <>
      <div className="flex flex-column flex-row-ns items-start-ns">
        <div className="flex-auto">
          <span className={`db light-silver fw4 f5 mb2 ttu -${asset.category_label}`}>{asset.category_label}</span>
          <h1 className="page-title">{asset.title}</h1>
        </div>
        {role === "admin" &&
          <ValidateButton id={asset.id} validated={!!asset.validated_at} />
        }
        <button onClick={() => setShowEditForm(true)} className="button -primary -with-icon mv4 mv0-ns">
          <i className="material-icons">edit</i>
          Edit asset
        </button>
      </div>

      <EditForm assetId={asset.id} mortgages={mortgages} values={asset} open={showEditForm} onClose={() => setShowEditForm(false)} />

      <ContentBox
        title="Property details"
        grid
        renderHeader={() => (
          <ValidationStatus
            type="asset"
            validated={asset.validated}
            validatedAt={asset.validated_at}
            invalidMessage={asset.rented ? "Upload lease agreement to validate." : "Upload property deed to validate."}
          />
        )}
      >
        <ul className="data-grid">
          <li>
            <div>
              <strong>Property type</strong><br />
              {humanize(asset.asset_sub_type)}
            </div>
            <div>
              <strong>Mortgage</strong><br />
              {asset.mortgage ? "Yes" : "No"}
            </div>
          </li>
          <li>
            <div>
              <strong>Address</strong><br />
              {[asset.address_line_1, asset.address_line_2].filter(a => !!a).join(", ")}
            </div>
            <div>
              <strong>Main residence</strong><br />
              {asset.main_residence ? "Yes" : "No"}
            </div>
          </li>
          <li>
            <div>
              <strong>Town</strong><br />
              {asset.address_town}
            </div>
            <div>
              <strong>Renting to Tenant</strong><br/>
              {asset.renting_to_tenant ? "Yes" : "No"}
            </div>
          </li>
          <li>
            <div>
              <strong>Postcode</strong><br />
              {asset.address_postcode}
            </div>
          </li>
        </ul>
      </ContentBox>

      <ValuationList valuations={valuations} />

      {asset.rented &&
        <ContentBox title="Landlord details" grid collapsable extraClasses="mt5">
          <p>
            Enter your landlord details to help keep track of your home.
          </p>
          <ul className="data-grid">
            {asset.landlord_name &&
              <>
                <li>
                  <div>
                    <strong>Landlord name</strong><br />
                    {asset.landlord_name}
                  </div>
                  <div>
                    <strong>Landlord email</strong><br />
                    {asset.landlord_email}
                  </div>
                </li>
                <li>
                  <div>
                    <strong>Phone</strong><br />
                    {asset.landlord_phone}
                  </div>
                </li>
                <li>
                  <div>
                    <strong>Address</strong><br />
                    {asset.full_landlord_address}
                  </div>
                </li>
              </>
            }
            <div className="bt b--light-gray pv4">
              <button onClick={() => setShowLandlordForm(true)} className="button">
                {asset.landlord_name ?
                  `Edit details`
                :
                  `Add landlord details`
                }
              </button>
              <LandlordForm
                assetId={asset.id}
                open={showLandlordForm}
                values={asset}
                onClose={() => setShowLandlordForm(false)}
              />
            </div>
          </ul>
        </ContentBox>
      }

      <ContentBox title="Documents" collapsable extraClasses="mt5">
        <div className="mt4">
          <DocumentsTable
            recordId={asset.id}
            recordType="Asset"
            recordGroup=""
            version={docsVersion}
            onUpdate={incrementDocsVersion}
            onAddNew={() => setShowUploadModal(true)}
          />
        </div>
      </ContentBox>

      <UploadModal
        title="Add document"
        open={showUploadModal}
        documentTypes={["land_certificate"]}
        recordId={asset.id}
        recordType="Asset"
        onUploadComplete={incrementDocsVersion}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  )
}

export default Property
