import React, { useState } from "react"

import BackArrow from "../BackArrow"
import IconBigButton from "../IconBigButton"
import propertyIcon from "./icons/property.svg"
import bankingIcon from "./icons/banking.svg"
import creditCardIcon from "./icons/credit-card.svg"
import storeCardIcon from "./icons/store-card.svg"
import studentLoanIcon from "./icons/banking.svg"

const DebtTypeSelector = ({ onSelected }) => {
  const backAction = () => Turbolinks.visit("/client/assets")
  const [assetType, setAssetType] = useState(null)
  return (
    <>
      <BackArrow onClick={backAction} />
      <h1 className="page-title">Add debt</h1>

      <strong>Which type of debt are you adding?</strong><br />

      <div className="mt5 flex flex-wrap">
        <IconBigButton
          label="Mortgage"
          icon={propertyIcon}
          onClick={() => setAssetType("mortgage")}
          active={assetType === "mortgage"}
          extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
        />

        <IconBigButton
          label="Bank loan"
          icon={bankingIcon}
          onClick={() => setAssetType("bank_loan")}
          active={assetType === "bank_loan"}
          extraClasses={`ml4-ns mb5 w-100 w-33-ns`}
        />

        <IconBigButton
          label="Credit card"
          icon={creditCardIcon}
          onClick={() => setAssetType("credit_card")}
          active={assetType === "credit_card"}
          extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
        />

        <IconBigButton
          label="Store card"
          icon={storeCardIcon}
          onClick={() => setAssetType("store_card")}
          active={assetType === "store_card"}
          extraClasses={`ml4-ns mb5 w-100 w-33-ns`}
        />

        <IconBigButton
          label="Student loan"
          icon={studentLoanIcon}
          onClick={() => setAssetType("student_loan")}
          active={assetType === "student_loan"}
          extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
        />
      </div>

      <div className="flex items-center">
        <button onClick={() => assetType !== null && onSelected(assetType)} className="button -primary mr3">Continue</button>
        <button onClick={backAction} className="button">Cancel</button>
      </div>
    </>
  )
}

export default DebtTypeSelector
