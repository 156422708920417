import React, { ReactNode } from "react"
import ReactDOM from "react-dom"

type Props = {
  id: string
  children: ReactNode
}

export default ({ id, children }: Props) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById(id)
  )
}
