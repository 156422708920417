import { find, each, reduce } from "lodash"
import React, { useState, useEffect } from "react"
import Turbolinks from "turbolinks"
import Modal from "../../Modal"
import DateInput from "../../inputs/DateInput"
import RadioInput from "../../inputs/RadioInput"
import { putRequest } from "../../../helpers/api"
import { format as formatDate } from "date-fns"

const initialValues = {
  renewal_reminders: false,
  tax_renewal_date: "",
  mot_renewal_date: "",
  service_date: "",
  insurance_renewal_date: "",
}

const RenewalForm = ({ assetId, values, open, onClose }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...initialValues })

  useEffect(() => {
    const defaultValues = reduce(initialValues, (memo, _, key) => {
      if (values[key]) memo[key] = values[key]
      return memo
    }, {})

    setValues(defaultValues)
  }, [assetId])

  return (
    <Modal title="Renewal dates" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          each(form, (value, field) => {
            if (value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="form-field -maxlength">
          <label>Receive renewal reminders?</label>
          <RadioInput
            value={form.renewal_reminders}
            onChange={renewal_reminders => setValues({ ...form, renewal_reminders })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "renewal_reminders") && <span className="hint -error">{find(errors, row => row.field === "renewal_reminders").errors.join(", ")}</span>}
        </div>

        <div className="form-field">
          <label htmlFor="tax_renewal_date">Road tax renews on</label>
          <DateInput
            id="tax_renewal_date"
            value={form.tax_renewal_date}
            onDayChange={date => setValues({ ...form, tax_renewal_date: formatDate(date, 'YYYY-MM-DD') })}
          />
          {find(errors, row => row.field === "tax_renewal_date") && <span className="hint -error">{find(errors, row => row.field === "tax_renewal_date").errors.join(", ")}</span>}
        </div>

        <div className="form-field">
          <label htmlFor="mot_renewal_date">MOT renews on</label>
          <DateInput
            id="mot_renewal_date"
            value={form.mot_renewal_date}
            onDayChange={date => setValues({ ...form, mot_renewal_date: formatDate(date, 'YYYY-MM-DD') })}
          />
          {find(errors, row => row.field === "mot_renewal_date") && <span className="hint -error">{find(errors, row => row.field === "mot_renewal_date").errors.join(", ")}</span>}
        </div>

        <div className="form-field">
          <label htmlFor="service_date">Service due on</label>
          <DateInput
            id="service_date"
            value={form.service_date}
            onDayChange={date => setValues({ ...form, service_date: formatDate(date, 'YYYY-MM-DD') })}
          />
          {find(errors, row => row.field === "service_date") && <span className="hint -error">{find(errors, row => row.field === "service_date").errors.join(", ")}</span>}
        </div>

        <div className="form-field">
          <label htmlFor="insurance_renewal_date">Insurance renews on</label>
          <DateInput
            id="insurance_renewal_date"
            value={form.insurance_renewal_date}
            onDayChange={date => setValues({ ...form, insurance_renewal_date: formatDate(date, 'YYYY-MM-DD') })}
          />
          {find(errors, row => row.field === "insurance_renewal_date") && <span className="hint -error">{find(errors, row => row.field === "insurance_renewal_date").errors.join(", ")}</span>}
        </div>

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default RenewalForm
