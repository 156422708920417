import React, { useState } from "react"
import AutoCompleteInput from "./AutoCompleteInput";

type Props = {
  defaultValue?: string
  onChange(name: string): void
  linkClassNames?: string
  inputContainerClassNames?: string,
  title: string,
  placeholder: string,
  multiline?: boolean,
  autocomplete?: boolean,
  autocompleteValues: string[]
}

export default (
  {
    defaultValue,
    onChange,
    linkClassNames,
    inputContainerClassNames,
    title,
    placeholder,
    multiline,
    autocomplete,
    autocompleteValues
  }: Props
) => {
  const [isRenaming, setIsRenaming] = useState(false)
  const [name, setName] = useState(defaultValue || "")

  if (!isRenaming) return (
    <span onClick={() => setIsRenaming(true)} className={linkClassNames}>{title}</span>
  )

  return (
    <form className={`flex ${inputContainerClassNames}`} onSubmit={e => {
      e.preventDefault()
      if (name === "") return
      onChange(name)
      setIsRenaming(false)
    }}>
      {(() => {
        if (autocomplete) {
          return (
            <AutoCompleteInput
              placeholder={placeholder}
              value={name}
              onChange={value => setName(value)}
              getData={setData => setData(autocompleteValues)}
            />
          )
        } else if (multiline) {
          return (
            <>
              <textarea
                className="pa3 ba br2 b--light-gray outline-0"
                placeholder={placeholder}
                value={name}
                onChange={e => setName(e.target.value)}
                autoFocus={true}
              />
              <input
                className="button -primary"
                type="submit"
                value="Save"
              />
            </>
          )
        } else {
          return (
            <input
              className="pa3 ba br2 b--light-gray outline-0"
              type="text"
              placeholder={placeholder}
              value={name}
              onChange={e => setName(e.target.value)}
              autoFocus={true}
            />
          )
        }
      })()}
    </form>
  )
}
