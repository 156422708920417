import React, { ReactNode, useState } from "react"
import { CSSTransition } from "react-transition-group"

interface Props {
  title?: string
  collapsable?: boolean
  grid?: boolean
  editable?: boolean
  extraClasses?: string
  onEdit?(): void
  children?: ReactNode
  renderHeader?(): ReactNode
}

const ContentBox = ({ title, grid, extraClasses, collapsable, editable, onEdit, children, renderHeader }: Props) => {
  const [showContent, setShowContent] = useState(collapsable ? false : true)

  return (
    <div className={`content-box ${grid ? '-with-grid' : ''} ${collapsable ? '-collapsable' : ''} ${showContent ? '-showing' : ''} ${extraClasses}`}>
      {renderHeader && renderHeader()}
      <div className="inner">
        {collapsable &&
          <i className={`material-icons select-none caret ${showContent ? '-showing' : ''}`} onClick={() => setShowContent(!showContent)}>keyboard_arrow_down</i>
        }
        {title &&
          <>
            {editable ?
              <div className="flex justify-between items-center mb4">
                <h2 onClick={() => setShowContent(!showContent)} className={`mb0 ${collapsable && 'pointer'}`}>{title}</h2>
                <a
                  href="#"
                  className="button -with-icon mr4"
                  onClick={e => {
                    e.preventDefault()
                    onEdit && onEdit()
                  }}
                >
                  <i className="material-icons">edit</i>
                  Edit
                </a>
              </div>
            :
              <h2 onClick={() => setShowContent(!showContent)} className={`${collapsable && 'pointer'}`}>{title}</h2>
            }
          </>
        }
        {collapsable ?
          <CSSTransition
            in={showContent}
            classNames="pop"
            timeout={1500}
          >
            <div className="contents pop-animation">
              {children}
            </div>
          </CSSTransition>
        :
          <div className="contents">
            {children}
          </div>
        }
      </div>
    </div>
  )
}

export default ContentBox
