import React, { useState } from "react"
import { connect } from "react-refetch"
import { parse as parseDate, format } from "date-fns"
import DocumentsMoreMenu from "../DocumentsMoreMenu"
import DocumentDetailsModal from "../DocumentDetailsModal"

export const ImportantDocuments = ({ docsFetch, onUpdate }) => {
  const [viewingDoc, setViewingDoc] = useState(null)

  if (docsFetch.pending) {
    return (
      <div className="mv6 light-silver f3">
        Loading&hellip;
      </div>
    )
  } else if (docsFetch.rejected) {
    return (<div>Error loading documents, try again.</div>)
  } else if (docsFetch.fulfilled) {
    const { data } = docsFetch.value
    if (data.length < 1) return null

    return (
      <div className="content-box -no-margin mr0 mr5-ns">
        {data.map(doc => (
          <div key={doc.id} className="pv4 flex items-start bb b--near-white">
            <span className="icon -file mh3"></span>
            <div className="lh-copy flex-auto">
              <span onClick={() => setViewingDoc(doc)} className="fw7 near-black dim no-underline pointer">
                {doc.title}
              </span>
              <br />
              <span className="silver">{format(parseDate(doc.document_date || doc.created_at), "Do MMMM YY")}</span>
            </div>
            <DocumentsMoreMenu doc={doc} onUpdate={onUpdate} />
          </div>
        ))}
        <DocumentDetailsModal document={viewingDoc} open={!!viewingDoc} onClose={() => setViewingDoc(null)} />
      </div>
    )
  }
}

export default connect(({ clientId, version }) => ({
  docsFetch: {
    url: `/api/documents?record_id=${clientId}&record_type=Client&group=probate&document_type=important&per_page=100`,
    comparison: `${clientId}:${version}`
  }
}))(ImportantDocuments)
