import React, { useState } from "react"
import styled from "@emotion/styled"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import BackArrow from "../BackArrow"

const StyledCarousel = styled(Carousel)`
.carousel-slider .control-arrow {
  background: #0058FF;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel.carousel-slider .control-arrow:hover {
  background: #0058FF;
}

.carousel .control-next.control-arrow {
  right: 15px;
}

.carousel .control-prev.control-arrow {
  left: 15px;
}

.carousel .slide {
  background: #0058FF;
}
`

const Caption = styled.div`
  padding: 20px 20px 40px;
  opacity: 0.9;
  h3 {
    color: #fff;
    font-size: 19px;
    font-weight: 300;
    margin: 0;
    letter-spacing: 1px;
  }
  p {
    color: #fff;
    font-size: 16px;
    margin: 10px 0 0;
  }
`

type Props = {
}

const HelpSlider: React.FC<Props> = ({}) => {
  return (
    <>
      <BackArrow onClick={() => window.history.back()} />
      <h1 className="page-title">Asset validation help</h1>

      <StyledCarousel
        emulateTouch
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <img src="/asset-validation-help/step1.png" />
          <Caption>
            <h3>Step 1. Click on the Asset or Debt you want to Validate</h3>
          </Caption>
        </div>
        <div>
          <img src="/asset-validation-help/step2.png" />
          <Caption>
            <h3>Step 2. Take a photo of the document and upload</h3>
          </Caption>
        </div>
        <div>
          <img src="/asset-validation-help/step3.png" />
          <Caption>
            <h3>Step 3. Select document type and click Save Document<br /></h3>
            <p>Our dedicated team will review and confirm your asset has been added within 24 hours.</p>
          </Caption>
        </div>
      </StyledCarousel>
    </>
  )
}

export default HelpSlider
