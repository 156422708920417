import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  renderHeader?(): ReactNode
}

export default class ClearContainer extends React.Component<Props> {
  render() {
    const { renderHeader, children } = this.props
    return (
      <div>
        {renderHeader && renderHeader()}
        <div className="inner">
          {children}
        </div>
      </div>
    )
  }
}
