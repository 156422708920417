import React, { useState } from "react"
import { rawNumber, formatCurrency } from "../../helpers/string"

type StringyNumber = number | string

type Props = {
  name?: string
  id?: string
  defaultValue?: StringyNumber
  onChange?(value: StringyNumber): void
}

export default ({ name, id, defaultValue, onChange }: Props) => {
  const [value, setValue] = useState<StringyNumber>(defaultValue || "")
  return (
    <React.Fragment>
      <input
        type="text"
        id={id}
        value={formatCurrency(value)}
        onChange={e => {
          const value = rawNumber(e.target.value)
          setValue(value)
          onChange && onChange(value)
        }}
      />
      <input type="hidden" name={name} value={rawNumber(value)} />
    </React.Fragment>
  )
}
