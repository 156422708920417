import React, { useState, useReducer } from "react"
import { parse as parseDate, format as formatDate } from "date-fns"
import humanize from "string-humanize"

import { dictionary } from "../../../helpers/types"
import { formatCurrency } from "../../../helpers/string"
import ContentBox from "../../ContentBox"
// import ValuationList from "../ValuationList"
import EditForm from "./EditForm"
import DocumentsTable from "../../DocumentsTable"
import UploadModal from "../../UploadModal"
import ValidationStatus from "../ValidationStatus"
import ValidateButton from "../ValidateButton"

interface Props {
  asset: dictionary
  properties: dictionary[]
  role?: string
}

const Mortgage = ({ asset, properties, role }: Props) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [docsVersion, incrementDocsVersion] = useReducer(state => state + 1, 0)

  return (
    <>
      <div className="flex flex-column flex-row-ns items-start-ns">
        <div className="flex-auto">
          <span className={`db light-silver fw4 f5 mb2 ttu -${asset.category_label}`}>{asset.category_label}</span>
          <h1 className="page-title">{asset.title}</h1>
        </div>
        {role === "admin" &&
          <ValidateButton id={asset.id} validated={!!asset.validated_at} />
        }
        <button onClick={() => setShowEditForm(true)} className="button -primary -with-icon mv4 mv0-ns">
          <i className="material-icons">edit</i>
          Edit debt
        </button>
      </div>

      <EditForm assetId={asset.id} values={asset} open={showEditForm} onClose={() => setShowEditForm(false)} />

      <ContentBox
        title="Mortgage details"
        grid
        renderHeader={() => (
          <ValidationStatus
            type="debt"
            validated={asset.validated}
            validatedAt={asset.validated_at}
            invalidMessage="Upload mortgage deed or offer letter below to validate."
          />
        )}
      >
        <ul className="data-grid">
          <li>
            <div>
              <strong>Mortgage Provider</strong><br />
              {asset.provider_name}
            </div>
            <div>
              <strong>Mortgage Amount</strong><br />
              {formatCurrency(asset.current_value)}
            </div>
          </li>
          <li>
            <div>
              <strong>Mortgage type</strong><br />
              {humanize(asset.asset_sub_type)}
              {asset.asset_sub_type === "interest_only" &&
                <span> ({asset.interest_rate}%)</span>
              }
            </div>
            <div>
              <strong>Mortgage account number</strong><br />
              {asset.policy_reference ?
                asset.policy_reference
              :
                <span className="light-gray">&ndash;</span>
              }
            </div>
          </li>
          <li>
            <div>
              <strong>Income protection</strong><br />
              {asset.income_protection ? "Yes" : "No"}
            </div>
            <div>
              <strong>Mortgage term (years)</strong><br />
              {asset.mortgage_term ?
                asset.mortgage_term
              :
                <span className="light-gray">&ndash;</span>
              }
            </div>
          </li>
          <li>
            <div>
              <strong>Date mortgage started</strong><br />
              {asset.policy_start_date ?
                formatDate(parseDate(asset.policy_start_date), "DD/MM/YYYY")
              :
                <span className="light-gray">&ndash;</span>
              }
            </div>
            <div>
              <strong>Multiple mortgages on this property</strong><br />
              {asset.multiple_mortgages ? "Yes" : "No"}
            </div>
          </li>
          {asset.property &&
            <li>
              <div className="-fullwidth">
                <strong>Property</strong><br />
                {asset.property.full_address}
              </div>
            </li>
          }
        </ul>
      </ContentBox>

      {/* <ValuationList valuations={valuations} /> */}

      <ContentBox title="Documents" collapsable extraClasses="mt5">
        <div className="mt4">
          <DocumentsTable
            recordId={asset.id}
            recordType="Asset"
            recordGroup=""
            version={docsVersion}
            onUpdate={incrementDocsVersion}
            onAddNew={() => setShowUploadModal(true)}
          />
        </div>
      </ContentBox>

      <UploadModal
        title="Add document"
        open={showUploadModal}
        documentTypes={["mortgage_statement", "mortgage_deed", "mortgage_offer"]}
        recordId={asset.id}
        recordType="Asset"
        onUploadComplete={incrementDocsVersion}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  )
}

export default Mortgage
