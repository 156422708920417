import { find } from "lodash"
import React, { useState } from "react"

import MoneyInput from "../../inputs/MoneyInput"
import IconBigButton from "../../IconBigButton"
import BackArrow from "../../BackArrow"

import lifeIcon from "../icons/insurance-life.svg"
import healthIcon from "../icons/insurance-health.svg"
import homeIcon from "../icons/insurance-home.svg"
import incomeProtectionIcon from "../icons/insurance-income-protection.svg"

import { postRequest } from "../../../helpers/api"

const initialValues = {
  asset_sub_type: "life",
  provider_name: "",
  current_value: ""
}

const Insurance = ({ onBack, onSuccess, hideTitle }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({})
  const [form, setValues] = useState({ ...initialValues })

  return (
    <>
      {!hideTitle &&
        <>
          <BackArrow onClick={onBack} />
          <h1 className="page-title">Add an insurance policy</h1>
        </>
      }

      <form
        onSubmit={async e => {
          e.preventDefault()

          if (isSaving) return

          setIsSaving(true)

          console.log("Submit form", form)

          const resp = await postRequest(`/api/assets`, { asset_category: "insurance", ...form })
          const json = await resp.json()

          if (resp.status === 201) {
            console.log("success", json)
            onSuccess(json.data)
          } else {
            console.log("failed resp", resp)
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="mv5 flex flex-wrap">
          <IconBigButton
            label="Life"
            icon={lifeIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "life" })}
            active={form.asset_sub_type === "life"}
            extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
          />

          <IconBigButton
            label="Health"
            icon={healthIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "health" })}
            active={form.asset_sub_type === "health"}
            extraClasses={`ml4-ns mb5 w-100 w-33-ns`}
          />

          <IconBigButton
            label="Home"
            icon={homeIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "home" })}
            active={form.asset_sub_type === "home"}
            extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
          />

          <IconBigButton
            label="Mortgage Income Protection"
            icon={incomeProtectionIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "income_protection" })}
            active={form.asset_sub_type === "income_protection"}
            extraClasses={`ml4-ns mb5 w-100 w-33-ns`}
          />
        </div>
        {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}

        <div className="form-field -maxlength">
          <label htmlFor="provider_name">Provider name</label>
          <input type="text" id="provider_name" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
          {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Policy amount</label>
          <MoneyInput id="current_value" defaultValue={form.current_value} onChange={current_value => setValues({ ...form, current_value })} />
          {find(errors, row => row.field === "current_value") && <span className="hint -error">{find(errors, row => row.field === "current_value").errors.join(", ")}</span>}
        </div>

        <div className="flex items-center">
          <button disabled={isSaving} type="submit" className="button -primary mr3">
            {isSaving ? 'Saving...' : 'Add policy'}
          </button>
          <button disabled={isSaving} onClick={onBack} className="button">Cancel</button>
        </div>
      </form>
    </>
  )
}

export default Insurance
