import { find } from "lodash"
import React, { useState } from "react"

import RadioInput from "../../inputs/RadioInput"
import MoneyInput from "../../inputs/MoneyInput"
import IconBigButton from "../../IconBigButton"
import BackArrow from "../../BackArrow"
import AutoCompleteInput from "../../inputs/AutoCompleteInput"
import { getMakes, getModels } from "../../../helpers/vehicles"

import carIcon from "../icons/vehicle.svg"
import motorbikeIcon from "../icons/motorbike.svg"
import truckIcon from "../icons/truck.svg"

import { postRequest } from "../../../helpers/api"

const initialValues = {
  asset_sub_type: "car",
  vehicle_registration: "",
  colour: "",
  mileage: "",
  current_value: "",
  make: ""
}

const Vehicle = ({ onBack, onSuccess, hideTitle }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({})
  const [form, setValues] = useState({ ...initialValues })

  return (
    <>
      {!hideTitle &&
        <>
          <BackArrow onClick={onBack} />
          <h1 className="page-title">Add vehicle</h1>
        </>
      }

      <form
        onSubmit={async e => {
          e.preventDefault()

          if (isSaving) return

          setIsSaving(true)

          console.log("Submit form", form)

          const resp = await postRequest(`/api/assets`, { asset_category: "vehicle", ...form })
          const json = await resp.json()

          if (resp.status === 201) {
            console.log("success", json)
            onSuccess(json.data)
          } else {
            console.log("failed resp", resp)
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="mv5 flex flex-wrap">
          <IconBigButton
            label="Car"
            icon={carIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "car" })}
            active={form.asset_sub_type === "car"}
            extraClasses={`mr4 mb4`}
          />

          <IconBigButton
            label="Motorbike"
            icon={motorbikeIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "motorbike" })}
            active={form.asset_sub_type === "motorbike"}
            extraClasses={`mr4 mb4`}
          />

          <IconBigButton
            label="Commercial"
            icon={truckIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "commercial" })}
            active={form.asset_sub_type === "commercial"}
            extraClasses={`mb4`}
          />
        </div>
        {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}

        <div className="form-field -maxlength">
          <label htmlFor="vehicle_registration">License plate</label>
          <input type="text" id="vehicle_registration" value={form.vehicle_registration} onChange={e => setValues({ ...form, vehicle_registration: e.target.value })} />
          {find(errors, row => row.field === "vehicle_registration") && <span className="hint -error">{find(errors, row => row.field === "vehicle_registration").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="colour">Colour</label>
          <input type="text" id="colour" value={form.colour} onChange={e => setValues({ ...form, colour: e.target.value })} />
          {find(errors, row => row.field === "colour") && <span className="hint -error">{find(errors, row => row.field === "colour").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="make">Vehicle make</label>
          <AutoCompleteInput
            placeholder="Select make"
            value={form.make}
            onChange={make => setValues({ ...form, make })}
            getData={setData => {
              getMakes().then(setData)
            }}
          />
          {find(errors, row => row.field === "make") && <span className="hint -error">{find(errors, row => row.field === "make").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="mileage">Approx. Mileage</label>
          <input type="text" id="mileage" value={form.mileage} onChange={e => setValues({ ...form, mileage: e.target.value })} />
          {find(errors, row => row.field === "mileage") && <span className="hint -error">{find(errors, row => row.field === "mileage").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Approx. Value</label>
          <MoneyInput id="current_value"
                      defaultValue={form.current_value}
                      onChange={value => setValues({ ...form, current_value: value })} />
          {find(errors, row => row.field === "current_value") &&
            <span className="hint -error">
              {find(errors, row => row.field === "current_value").errors.join(", ")}
            </span>
          }
        </div>

        <div className="flex items-center">
          <button disabled={isSaving} type="submit" className="button -primary mr3">
            {isSaving ? 'Saving...' : 'Add vehicle'}
          </button>
          <button disabled={isSaving} onClick={onBack} className="button">Cancel</button>
        </div>
      </form>
    </>
  )
}

export default Vehicle
