import React, { useState, useMemo } from "react"
import { subMonths, format as formatDate } from "date-fns"

import { formatCurrency } from "../helpers/string"
import ContentBox from "./ContentBox"
import { formatStatsForDateRange, calculateNPV, AssetValue } from "../helpers/stats"

type Props = {
  data: AssetValue[]
  className?: string
}

const NPV: React.FC<Props> = ({ data, className }) => {
  const monthArray = useMemo(() => {
    const fromDate = subMonths(new Date(), 1)
    return calculateNPV(formatStatsForDateRange(data, fromDate, 1, true))
  }, [])

  const comparisonMonth = monthArray[0]
  const latestMonth = monthArray[monthArray.length - 1]

  return (
    <ContentBox extraClasses={`${className}`}>
      <div className="f4 fw6">Net Present Value</div>
      <div className="mt4 flex justify-between items-center">
        {latestMonth.npv >= comparisonMonth.npv ?
          <span className="icon-container -green fw3">
            <i className="material-icons fw3">arrow_upward</i>
          </span>
        :
          <span className="icon-container -red fw3">
            <i className="material-icons fw3">arrow_downward</i>
          </span>
        }
        <span className="mh4 fw6 f3">{formatCurrency(latestMonth.npv.value)}</span>
        <span className="fw4 f5">{formatDate(new Date(), "D MMM YYYY")}</span>
      </div>

    </ContentBox>
  )
}

export default NPV
