import React from "react"
import Modal from "./Modal"
import { truncateFilename } from "../helpers/string"
import filesize from "filesize"

const DocumentDetailsModal = ({ document, open, onClose }) => {
  return (
    <Modal title={document && document.title} open={open} onClose={onClose} showClose={true}>
      {document &&
        <React.Fragment>
          <div className="flex items-center pa3 bg-white b--silver ba" style={{ borderWidth: 2, borderRadius: 3 }}>
            <i className="material-icons light-blue mr3">folder</i>
            <div style={{ flex: 1 }}>
              <a href={document.file_url} target="_blank">{truncateFilename(document.filename, 18)}</a><br />
              <span className="gray f7">{filesize(document.size_in_bytes)}</span>
            </div>
          </div>

          {document.comments &&
          <div className="lh-copy mt5">
            <strong>Comments:</strong><br />
            {document.comments}
          </div>
          }

          <div className="flex mt5">
            <a href={document.file_url} target="_blank" className="button -primary mr4">Download</a>
            <button onClick={onClose} className="button">Close</button>
          </div>
        </React.Fragment>
      }
    </Modal>
  )
}

export default DocumentDetailsModal
