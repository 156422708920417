import React from "react"
import humanize from "string-humanize"
import { parse as parseDate, format } from "date-fns"

type Document = {
  id: number
  file_url: string
  filename: string
  document_type: string
  created_at: string
  document_date: string
  user: {
    full_name: string
  }
}

type Props = {
  documents: Document[]
  onRemove(id: number): void
}

export default ({ documents, onRemove }: Props) => {
  return (
    <div className="mt4 content-box">
      <div className="inner">

        <div className="table-wrapper">

          <table className="static-table">
            <thead>
              <tr>
                <th>Filename</th>
                <th>Document type</th>
                <th>Date added</th>
                <th>Added by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {documents.map(doc => (
                <tr key={doc.id}>
                  <td>{doc.file_url ? <a href={doc.file_url} target="_blank">{doc.filename}</a> : doc.filename}</td>
                  <td>{humanize(doc.document_type)}</td>
                  <td>{format(parseDate(doc.created_at), "Do MMMM YYYY")}</td>
                  <td>{doc.user.full_name}</td>
                  <td><span className="f3 fw1 light-silver pointer" onClick={() => onRemove(doc.id)}>&times;</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
