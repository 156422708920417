import React from 'react';

import { TimelineItem } from "./types"
import {formatCurrency} from "../../helpers/string";
import {colorNames} from "../../helpers/colors";

interface Props {
  item: TimelineItem
  itemsToShow: (string|number)[]
  onItemToggle(id: string|number, enabled: boolean): void
  onClick(): void
}

interface State {
  showItems: boolean
}

const borderColor = colorNames("")

export default class HeaderItem extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      showItems: false
    }
  }

  onHover = () => {
    this.setState({ showItems: true })
  }

  onLeave = () => {
    this.setState({ showItems: false })
  }

  renderItems() {
    if (!this.state.showItems) return

    const { items } = this.props.item
    if (items.length === 0) return

    const { itemsToShow, onItemToggle } = this.props

    return (
      <div className="timeline-header-items">
        {items.map(({ id, title }) => {
          return (
            <div key={id} className="timeline-header-item">
              <label>
                <input
                  type="checkbox"
                  value={id}
                  checked={itemsToShow.includes(id)}
                  className="mr2"
                  onChange={({ target: { checked } }) => onItemToggle(id, checked)}
                />
                {title}
              </label>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { id, type, title, value } = this.props.item
    const color = borderColor[id] || borderColor[type]

    return (
      <div className="timeline-header-item-container"
           onMouseEnter={this.onHover}
           onMouseLeave={this.onLeave}>
        <div className="timeline-header-top" onClick={this.props.onClick}
        >
          <div>
            {title}
            <span className={`colored_dot -${color}`}/>
          </div>
          {formatCurrency(value)}
        </div>
        {this.renderItems()}
      </div>
    )
  }
}
