import React, { useState } from "react"
import { putRequest } from "../../helpers/api"

import BigButton from "../BigButton"
import BackArrow from "../BackArrow"

const initialValues = {
  relationship_category: null,
  relationship_subcategory: null
}

const Relationship = ({ client, trustedFriend }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [form, setValues] = useState({ ...initialValues })

  return (
    <>
    {(!form.relationship_category || form.relationship_category === "friend") &&
      <>
        <h1 className="page-title">What is your relationship to {client.first_name}?</h1>
        <div className="mt5 flex flex-column items-center">
          <BigButton onClick={() => setValues({ ...form, relationship_category: "family" })} className="w-50 w-33-ns mb4">Family</BigButton>
          <BigButton onClick={() => setValues({ ...form, relationship_category: "professional" })} className="w-50 w-33-ns mb4">Professional</BigButton>
          <BigButton active={form.relationship_category === "friend"} onClick={() => setValues({ ...form, relationship_category: "friend" })} className="w-50 w-33-ns ">Friend</BigButton>
        </div>
      </>
    }
    {form.relationship_category === "family" &&
      <>
        <BackArrow onClick={() => setValues({ ...initialValues })} />
        <h1 className="page-title">What is your family relationship?</h1>
        <div className="mt5 flex flex-wrap justify-center items-center">
          <BigButton active={form.relationship_subcategory === "partner"} onClick={() => setValues({ ...form, relationship_subcategory: "partner" })} className="w-50 w-33-ns mh4 mb4">
            Partner
          </BigButton>
          <BigButton active={form.relationship_subcategory === "spouse"} onClick={() => setValues({ ...form, relationship_subcategory: "spouse" })} className="w-50 w-33-ns mh4 mb4">
            Spouse
          </BigButton>
          <BigButton active={form.relationship_subcategory === "child"} onClick={() => setValues({ ...form, relationship_subcategory: "child" })} className="w-50 w-33-ns mh4 mb4">
            Child
          </BigButton>
          <BigButton active={form.relationship_subcategory === "step-child"} onClick={() => setValues({ ...form, relationship_subcategory: "step-child" })} className="w-50 w-33-ns mh4 mb4">
            Step-child
          </BigButton>
          <BigButton active={form.relationship_subcategory === "sibling"} onClick={() => setValues({ ...form, relationship_subcategory: "sibling" })} className="w-50 w-33-ns mh4 mb4">
            Sibling
          </BigButton>
          <BigButton active={form.relationship_subcategory === "in-law"} onClick={() => setValues({ ...form, relationship_subcategory: "in-law" })} className="w-50 w-33-ns mh4 mb4">
            In-law
          </BigButton>
          <BigButton active={form.relationship_subcategory === "parent"} onClick={() => setValues({ ...form, relationship_subcategory: "parent" })} className="w-50 w-33-ns mh4 mb4">
            Parent
          </BigButton>
          <BigButton active={form.relationship_subcategory === "step-parent"} onClick={() => setValues({ ...form, relationship_subcategory: "step-parent" })} className="w-50 w-33-ns mh4 mb4">
            Step-parent
          </BigButton>
        </div>
      </>
    }
    {form.relationship_category === "professional" &&
      <>
        <BackArrow onClick={() => setValues({ ...initialValues })} />
        <h1 className="page-title">What is your professional relationship?</h1>
        <div className="mt5 flex flex-column items-center">
          <BigButton active={form.relationship_subcategory === "carer"} onClick={() => setValues({ ...form, relationship_subcategory: "carer" })} className="w-50 w-33-ns mb4">
            Carer
          </BigButton>
          <BigButton active={form.relationship_subcategory === "work-colleague"} onClick={() => setValues({ ...form, relationship_subcategory: "work-colleague" })} className="w-50 w-33-ns mb4">
            Work Colleague
          </BigButton>
          <BigButton active={form.relationship_subcategory === "medical"} onClick={() => setValues({ ...form, relationship_subcategory: "medical" })} className="w-50 w-33-ns mb4">
            Medical
          </BigButton>
          <BigButton active={form.relationship_subcategory === "volunteer"} onClick={() => setValues({ ...form, relationship_subcategory: "volunteer" })} className="w-50 w-33-ns mb4">
            Volunteer
          </BigButton>
        </div>
      </>
    }
    {(form.relationship_category === "friend" || (form.relationship_category && form.relationship_subcategory)) &&
      <div className="tc mt5">
        <button
          className="button -primary"
          onClick={async () => {
            setIsSaving(true)
            await putRequest(`/api/trusted_friends/${trustedFriend.id}`, form)
            setIsSaving(false)
            Turbolinks.visit("/")
          }}
        >
          {isSaving ? "Saving...": "Continue"}
        </button>
      </div>
    }
    </>
  )
}

export default Relationship
