import { find } from "lodash"
import React, { useState } from "react"

import FileUpload from "../../inputs/FileUpload"
import BackArrow from "../../BackArrow"

import { postRequest, putRequest } from "../../../helpers/api"
import MoneyInput from "../../inputs/MoneyInput";

const initialValues = {
  asset_sub_type: "",
  provider_name: "",
  employer_name: "",
  policy_document_id: null,
  current_value: ""
}

const Pension = ({ onBack, onSuccess, hideTitle }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({})
  const [form, setValues] = useState({ ...initialValues })

  return (
    <>
      {!hideTitle &&
        <>
          <BackArrow onClick={onBack} />
          <h1 className="page-title">Add a pension</h1>
        </>
      }

      <form
        onSubmit={async e => {
          e.preventDefault()

          if (isSaving) return

          setIsSaving(true)

          console.log("Submit form", form)

          const resp = await postRequest(`/api/assets`, { asset_category: "pension", ...form })
          const json = await resp.json()

          if (resp.status === 201) {
            console.log("success", json)

            // Update doc to attach to asset
            await putRequest(`/api/documents/${form.policy_document_id}`, { record_id: json.data.id, record_type: "Asset" })

            onSuccess(json.data)
          } else {
            console.log("failed resp", resp)
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="form-field -maxlength">
          <label htmlFor="asset_sub_type">Type of account</label>
          <select id="asset_sub_type" value={form.asset_sub_type} onChange={e => setValues({ ...form, asset_sub_type: e.target.value })}>
            <option value="">Please select</option>
            <option value="employer">Employer</option>
            <option value="state">State</option>
            <option value="private">Private</option>
            <option value="sipp">SIPP</option>
            <option value="sass">SASS</option>
          </select>
          {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}
        </div>

        {form.asset_sub_type === "employer" ?
          <>
            <div className="form-field -maxlength">
              <label htmlFor="employer_name">Employer name</label>
              <input type="text" id="employer_name" value={form.employer_name} onChange={e => setValues({ ...form, employer_name: e.target.value })} />
              {find(errors, row => row.field === "employer_name") && <span className="hint -error">{find(errors, row => row.field === "employer_name").errors.join(", ")}</span>}
            </div>
            <div className="form-field -maxlength">
              <label htmlFor="provider_name">Pension plan name</label>
              <input type="text" id="provider_name" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
              {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
            </div>
          </>
        :
          <div className="form-field -maxlength">
            <label htmlFor="provider_name">Provider name</label>
            <input type="text" id="provider_name" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
            {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
          </div>
        }

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Amount</label>
          <MoneyInput id="current_value"
                      defaultValue={form.current_value}
                      onChange={value => setValues({ ...form, current_value: value })} />
          {find(errors, row => row.field === "current_value") &&
            <span className="hint -error">
              {find(errors, row => row.field === "current_value").errors.join(", ")}
            </span>
          }
        </div>

        <FileUpload
          label="Pension Statement"
          single={true}
          documentTypeSelectionRequired={false}
          defaultDocumentType="policy"
          skipLoadingExistingDocs={true}
          onUploadStart={() => setIsSaving(true)}
          onUploadComplete={upload => {
            setValues({ ...form, policy_document_id: upload.id })
            setIsSaving(false)
          }}
          onDocumentRemoved={() => setValues({ ...form, policy_document_id: null })}
        />

        <div className="flex items-center">
          <button disabled={isSaving} type="submit" className="button -primary mr3">
            {isSaving ? 'Saving...' : 'Add pension'}
          </button>
          <button disabled={isSaving} onClick={onBack} className="button">Cancel</button>
        </div>
      </form>
    </>
  )
}

export default Pension
