export const colorNames = (prefix: string = "") => ({
  npv: `${prefix}purple`,
  pension: `${prefix}hot-pink`,
  property: `${prefix}dark-green`,
  vehicle: `${prefix}gold`,
  insurance: `${prefix}dark-blue`,
  debt: `${prefix}red`,
  mortgage: `${prefix}green`,
  credit_card: `${prefix}dark-blue`,
  store_card: `${prefix}yellow`,
  bank_loan: `${prefix}pink`,
})

export const colorHexes = () => ({
  npv: "#5E2CA5",
  pension: "#FF41B4",
  property: "#137752",
  vehicle: "#ffb700",
  insurance: "#00449E",
  debt: "#FF4136",
  mortgage: "#19A974",
  credit_card: "#00449E",
  store_card: "#FFD700",
  bank_loan: "#FF80CC",
  estimation: "#FF0A76"
})
