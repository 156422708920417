import { uniq } from "lodash"
import { getRequest } from "./api"
import { object } from "prop-types"

type VehicleModel = {
  model_id: number
  model: string
  make_id: number
  make: string
}

let vehicleData: VehicleModel[] = []

async function fetchVehicleData() {
  const resp = await getRequest("/api/vehicles", null)
  const json = await resp.json()

  vehicleData = json.data
}

function getAllMakes(): string[] {
  return uniq(vehicleData.map(vehicle => vehicle.make)).sort()
}

export async function getMakes(): Promise<string[]> {
  if (!vehicleData.length) await fetchVehicleData()

  return getAllMakes()
}

export async function getModels(make: string): Promise<string[]> {
  if (!vehicleData.length) await fetchVehicleData()

  let filtered = vehicleData

  if (make.length > 0) {
    filtered = vehicleData.filter(data => data.make.toLocaleLowerCase() === make.toLowerCase())
  }

  return uniq(filtered.map(obj => obj.model)).sort()
}
