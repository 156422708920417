import { find, each, reduce } from "lodash"
import React, { useState } from "react"
import Turbolinks from "turbolinks"

import { getMakes, getModels } from "../../../helpers/vehicles"
import { dictionary } from "../../../helpers/types"
import { putRequest } from "../../../helpers/api"
import Modal from "../../Modal"
import RadioInput from "../../inputs/RadioInput"
import NumberInput from "../../inputs/NumberInput"
import AutoCompleteInput from "../../inputs/AutoCompleteInput"
import IconBigButton from "../../IconBigButton"
import carIcon from "../../NewAsset/icons/vehicle.svg"
import motorbikeIcon from "../../NewAsset/icons/motorbike.svg"
import truckIcon from "../../NewAsset/icons/truck.svg"
import MoneyInput from "../../inputs/MoneyInput"

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
}

function getInitialValues(values: dictionary): dictionary {
  const keys = ["asset_sub_type", "vehicle_registration", "mileage", "colour", "year", "make", "model", "current_value"]

  return reduce(keys, (memo, key) => {
    memo[key] = values[key]
    return memo
  }, {})
}

const EditForm = ({ assetId, open, values, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values) })

  return (
    <Modal title="Edit Vehicle" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          each(form, (value, field) => {
            if (field === "address_line_2") return
            if (value == null || value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="mv5 flex flex-wrap">
          <IconBigButton
            label="Car"
            icon={carIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "car" })}
            active={form.asset_sub_type === "car"}
            extraClasses={`mr4 mb4`}
          />

          <IconBigButton
            label="Motorbike"
            icon={motorbikeIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "motorbike" })}
            active={form.asset_sub_type === "motorbike"}
            extraClasses={`mr4 mb4`}
          />

          <IconBigButton
            label="Commercial"
            icon={truckIcon}
            onClick={() => setValues({ ...form, asset_sub_type: "commercial" })}
            active={form.asset_sub_type === "commercial"}
            extraClasses={`mb4`}
          />
        </div>
        {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}

        <div className="form-field -maxlength">
          <label htmlFor="vehicle_registration">License plate</label>
          <input type="text" id="vehicle_registration" value={form.vehicle_registration} onChange={e => setValues({ ...form, vehicle_registration: e.target.value })} />
          {find(errors, row => row.field === "vehicle_registration") && <span className="hint -error">{find(errors, row => row.field === "vehicle_registration").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="colour">Colour</label>
          <input type="text" id="colour" value={form.colour} onChange={e => setValues({ ...form, colour: e.target.value })} />
          {find(errors, row => row.field === "colour") && <span className="hint -error">{find(errors, row => row.field === "colour").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="year">Year</label>
          <NumberInput value={form.year} min={0} length={4} onChange={year => setValues({ ...form, year })} />
          {find(errors, row => row.field === "year") && <span className="hint -error">{find(errors, row => row.field === "year").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="make">Vehicle make</label>
          <AutoCompleteInput
            placeholder="Select make"
            value={form.make}
            onChange={make => setValues({ ...form, make })}
            getData={setData => {
              getMakes().then(setData)
            }}
          />
          {find(errors, row => row.field === "make") && <span className="hint -error">{find(errors, row => row.field === "make").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="model">Vehicle model</label>
          <AutoCompleteInput
            placeholder="Select model"
            value={form.model || ""}
            filterString={form.make}
            onChange={model => setValues({ ...form, model })}
            getData={setData => {
              getModels(form.make || "").then(setData)
            }}
          />
          {find(errors, row => row.field === "model") && <span className="hint -error">{find(errors, row => row.field === "model").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Current value</label>
          <MoneyInput id="current_value" defaultValue={form.current_value} onChange={current_value => setValues({ ...form, current_value })} />
          {find(errors, row => row.field === "current_value") && <span className="hint -error">{find(errors, row => row.field === "current_value").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="mileage">Approx. Mileage</label>
          <input type="text" id="mileage" value={form.mileage} onChange={e => setValues({ ...form, mileage: e.target.value })} />
          {find(errors, row => row.field === "mileage") && <span className="hint -error">{find(errors, row => row.field === "mileage").errors.join(", ")}</span>}
        </div>

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
