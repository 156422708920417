import React from "react"
import MoreMenu from "./MoreMenu"
import InlineEdit from "./inputs/InlineEdit"
import { putRequest, deleteRequest } from "../helpers/api"
import { DOCUMENT_TYPES } from '../constants/documents.ts'

export default ({ doc, onUpdate }) => {
  return (
    <MoreMenu>
      {({ close }) => (
        <React.Fragment>
          {doc.file_url &&
            <a
              href={doc.file_url}
              target="_blank"
              className="db near-black no-underline dim fw6 f6 mb3"
              onClick={close}
              children="Download"
            />
          }

          {doc.editable &&
            <InlineEdit
              title='Rename'
              placeholder='filename'
              defaultValue={doc.title}
              onChange={async title => {
                await putRequest(`/api/documents/${doc.id}`, { title })
                close()
                onUpdate()
              }}
              inputContainerClassNames="mb3"
              linkClassNames="db near-black no-underline dim fw6 f6 mb3 pointer"
              autocomplete={true}
              autocompleteValues={DOCUMENT_TYPES}
            />
          }

          {doc.editable &&
            <InlineEdit
              title='Edit comments'
              placeholder='Comments'
              multiline
              defaultValue={doc.comments}
              onChange={async comments => {
                await putRequest(`/api/documents/${doc.id}`, { comments })
                close()
                onUpdate()
              }}
              inputContainerClassNames="mb3"
              linkClassNames="db near-black no-underline dim fw6 f6 mb3 pointer"
            />
          }

          {doc.removable &&
            <a
              href="#"
              className="db near-black no-underline dim fw6 f6"
              onClick={async e => {
                e.preventDefault()
                if (!confirm("Are you sure?")) return close()
                await deleteRequest(`/api/documents/${doc.id}`)
                close()
                onUpdate()
              }}
              children="Delete document"
            />
          }
        </React.Fragment>
      )}
    </MoreMenu>
  )
}
