import React, { useState } from 'react'
import Turbolinks from 'turbolinks'
import { find, reduce } from 'lodash'

import { dictionary } from '../../../helpers/types'
import { putRequest } from '../../../helpers/api'

import Modal from '../../Modal'
import AddressFinder, { buildAddress } from '../../AddressFinder'
import RadioInput from '../../inputs/RadioInput'

interface FormError {
  field: string
  errors: string[]
}

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
}

function getInitialValues(values: dictionary): dictionary {
  const keys = [
    'asset_sub_type', 'provider_name', 'company_name', 'address_line_1',
    'address_line_2', 'address_town', 'account_number', 'sort_code',
    'swift_bic', 'joint_account', 'holder_full_name', 'holder_address_line_1',
    'holder_address_line_2', 'holder_address_town', 'holder_email',
    'holder_contact_number'
  ]

  return reduce(keys, (memo, key) => {
    memo[key] = values[key]
    return memo
  }, {})
}

const EditForm = ({ assetId, open, values, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values) })

  async function onSubmit(event) {
    event.preventDefault()

    if (isSaving) {
      return
    } else {
      setIsSaving(true)
    }

    const response = await putRequest(`/api/assets/${assetId}`, form)
    const json = await response.json()

    if (response.status === 200) {
      Turbolinks.visit()
    } else {
      setErrors(json.validation_errors)
    }

    setIsSaving(false)
  }

  function renderFieldErrors(field: string, errors: FormError[]) {
    const fieldErrors = find(errors, row => row.field === field)

    if (fieldErrors) {
      return <span className="hint -error">{fieldErrors.errors.join(', ')}</span>
    }
  }

  return (
    <Modal
      title="Edit bank account"
      open={open}
      onClose={onClose}
      showClose={true}
    >
      <form onSubmit={onSubmit}>
        <div className="form-field -maxlength">
          <label htmlFor="asset_sub_type">Type of account</label>

          <select
            id="asset_sub_type"
            value={form.asset_sub_type}
            onChange={(event) => {
              setValues({ ...form, asset_sub_type: event.target.value })
            }}
          >
            <option value="savings">Savings</option>
            <option value="current">Current</option>
            <option value="credit_union">Credit Union</option>
            <option value="business">Business</option>
          </select>

          {renderFieldErrors('asset_sub_type', errors)}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="provider_name">Bank name</label>

          <input
            type="text"
            id="provider_name"
            placeholder="e.g. Santander"
            value={form.provider_name}
            onChange={(event) => {
              setValues({ ...form, provider_name: event.target.value })
            }}
          />

          {renderFieldErrors('provider_name', errors)}
        </div>

        <div className="form-field -maxlength">
          <AddressFinder
            label="Bank address"
            errors={errors}
            onChange={(address) => {
              setValues({ ...form, ...address })
            }}
          />
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="account_number">Account number</label>

          <input
            type="text"
            id="account_number"
            value={form.account_number}
            onChange={(event) => {
              setValues({ ...form, account_number: event.target.value })
            }}
          />

          {renderFieldErrors('account_number', errors)}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="sort_code">Sort code</label>

          <input
            type="text"
            id="sort_code"
            value={form.sort_code}
            onChange={(event) => {
              setValues({ ...form, sort_code: event.target.value })
            }}
          />

          {renderFieldErrors('sort_code', errors)}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="swift_bic">SWIFT/BIC</label>

          <input
            type="text"
            id="swift_bic"
            value={form.swift_bic}
            onChange={(event) => {
              setValues({ ...form, swift_bic: event.target.value })
            }}
          />

          {renderFieldErrors('swift_bic', errors)}
        </div>

        {form.asset_sub_type === 'business' &&
          <div className="form-field -maxlength">
            <label htmlFor="sort_code">Company name</label>

            <input
              type="text"
              id="company_name"
              value={form.company_name}
              onChange={(event) => {
                setValues({ ...form, company_name: event.target.value })
              }}
            />

            {renderFieldErrors('company_name', errors)}
          </div>
        }

        <div className="form-field -maxlength">
          <label>Is this a Joint account?</label>

          <RadioInput
            options={[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]}
            value={form.joint_account}
            onChange={(isJointAccount) => {
              setValues({ ...form, joint_account: isJointAccount })
            }}
          />
        </div>

        {form.joint_account === true &&
          <>
            <strong className="db mt5">Joint account holder details</strong>

            <div className="form-field -maxlength">
              <label htmlFor="holder_full_name">Full name</label>

              <input
                type="text"
                id="holder_full_name"
                value={form.holder_full_name}
                onChange={(event) => {
                  setValues({ ...form, holder_full_name: event.target.value })
                }}
              />

              {renderFieldErrors('holder_full_name', errors)}
            </div>

            <div className="form-field -maxlength">
              <AddressFinder
                errors={[]}
                label="Address"
                value={buildAddress({
                  address_line_1: form.holder_address_line_1,
                  address_line_2: form.holder_address_line_2,
                  address_town: form.holder_address_town,
                  address_postcode: form.holder_address_postcode,
                  address_country: form.holder_address_country
                })}
                onChange={(address) => {
                  setValues({
                    ...form,
                    holder_address_line_1: address.address_line_1,
                    holder_address_line_2: address.address_line_2,
                    holder_address_town: address.address_town,
                    holder_address_postcode: address.address_postcode,
                    holder_address_country: address.address_country
                  })
                }}
              />
            </div>

            <div className="form-field -maxlength">
              <label htmlFor="holder_email">Email</label>

              <input
                type="text"
                id="holder_email"
                value={form.holder_email}
                onChange={(event) => {
                  setValues({ ...form, holder_email: event.target.value })
                }}
              />
            </div>

            <div className="form-field -maxlength">
              <label htmlFor="holder_contact_number">Contact number</label>

              <input
                type="text"
                id="holder_contact_number"
                value={form.holder_contact_number}
                onChange={(event) => {
                  setValues({ ...form, holder_contact_number: event.target.value })
                }}
              />

              {renderFieldErrors('holder_contact_number', errors)}
            </div>
          </>
        }

        <div className="flex">
          <button
            type="submit"
            className="button -primary mr4"
            disabled={isSaving}
            children={isSaving ? 'Saving...' : 'Save changes'}
          />

          <button
            type="button"
            className="button"
            onClick={onClose}
            children="Cancel"
          />
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
