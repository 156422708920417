import React, { useState } from "react"

function checkValues(tel: string): string {
  tel = tel.replace(/[^+0-9]/g, "")
  tel = tel.replace(/([+\w])\+/g, "$1")
  tel = tel.substr(0, 14)

  return tel.trim()
}

type Props = {
  name?: string
  id?: string
  defaultValue?: string
}

export default ({ name, id, defaultValue }: Props) => {
  const [telephone, setTelephone] = useState(defaultValue || "")
  return (
    <input
      type="tel"
      name={name}
      id={id}
      value={telephone}
      onChange={e => setTelephone(checkValues(e.target.value))}
    />
  )
}
