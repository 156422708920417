import React, { useState } from "react"

import RadioInput from "../inputs/RadioInput"

const Problem = ({ onContinue }) => {
  const [reason, setReason] = useState("")
  const [error, setError] = useState(null)

  return (
    <div>
      <h1 className="page-title">Why is this not your will?</h1>
      <RadioInput
        stacked
        name="will-problem-reason"
        value={reason}
        onChange={value => setReason(value)}
        options={[
          { value: "document_missing", label: "A codicil or will is missing" },
          { value: "will_with_new_solicitor", label: "I have made a new will with another solicitor" },
          { value: "been_updated", label: "I have updated my will" },
          { value: "not_mine", label: "This is not my will" },
        ]}
      />
      {error &&
        <span className="dark-red db mv3">{error}</span>
      }
      <button onClick={() => {
        if (reason.length) {
          setError(null)
          onContinue(reason)
        } else {
          setError("Please select an option")
        }
      }} className="button -primary mt5">Continue</button>
    </div>
  )
}

export default Problem
