import React from "react"
import styled from "@emotion/styled"

type SpinnerProps = {
  color: string
  size: number
  stroke: number
}

const Spinner = styled.div<SpinnerProps>`
display: inline-block;
position: relative;
width: ${props => props.size}px;
height: ${props => props.size}px;
div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${props => props.size - props.stroke}px;
  height: ${props => props.size - props.stroke}px;
  margin: ${props => props.stroke}px;
  border: ${props => props.stroke}px solid ${props => props.color};
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${props => props.color} transparent transparent transparent;
}
div:nth-of-type(1) {
  animation-delay: -0.45s;
}
div:nth-of-type(2) {
  animation-delay: -0.3s;
}
div:nth-of-type(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`

type Props = {
  color?: string
  size?: number
  stroke?: number
  label?: string
  wrapperClassName?: string
}

export default ({ color, size, stroke, label, wrapperClassName }: Props) => {
  const loadingSpinner = (
    <Spinner color={color || "#ABB9C2"} size={size || 40} stroke={stroke || 4}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Spinner>
  )

  if (!label) return loadingSpinner

  return (
    <div className={`flex items-center ${wrapperClassName}`}>
      {loadingSpinner}
      <div className="ml4 f4">{label}</div>
    </div>
  )
}
