import React, { useState } from "react"
import { parse as parseDate, format as formatDate } from "date-fns"

type Props = {
  type: "asset" | "debt"
  validated: boolean
  invalidMessage: string
  validatedAt?: string
}

const ValidationStatus: React.FC<Props> = ({ type, validated, validatedAt, invalidMessage }) => {
  return (
    <header className={`${validated ? 'bg-light-green' : 'bg-light-gray'}`}>
      {validated ?
        <div className="flex items-center">
          <i className="material-icons white mr3">check_circle_outline</i>
          <strong className="mr2">{type === "asset" ? "Asset" : "Debt"} validated</strong> {formatDate(parseDate(validatedAt), "DD/MM/YYYY")}
        </div>
      :
        <div className="flex flex-column flex-row-ns items-center-ns lh-copy">
          <i className="material-icons mid-gray mr3">warning</i>
          <strong className="mr2">Validation required</strong>
          <span>{invalidMessage}</span>
          <span className="flex-auto tr-ns">
            <a href="/asset_validation_help">Help?</a>
          </span>
        </div>
      }
    </header>
  )
}

export default ValidationStatus
