import { times } from "lodash"
import React from "react"

type Props = {
  totalPages: number
  currentPage: number
  className?: string
  onChange(page: number): void
}

export default ({ totalPages, currentPage, onChange, className }: Props) => {
  function changePage(i) {
    if (i > 0 && i <= totalPages) onChange(i)
  }
  const hasPrev = currentPage > 1
  const hasNext = currentPage < totalPages
  return (
    <div className={`pagination-boxes ${className}`}>
      <span className={`prev ${hasPrev ? '' : '-disabled'}`} onClick={() => changePage(currentPage - 1)}>&lsaquo;</span>
      {times(totalPages).map(number => (
        <span
          key={number}
          className={`page ${currentPage === number + 1 ? "-active" : ""}`}
          onClick={() => changePage(number + 1)}
        >
          {number + 1}
        </span>
      ))}
      <span className={`next ${hasNext ? '' : '-disabled'}`} onClick={() => changePage(currentPage + 1)}>&rsaquo;</span>
    </div>
  )
}
