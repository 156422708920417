import React, { useState } from "react"

import BackArrow from "../BackArrow"
import IconBigButton from "../IconBigButton"
import propertyIcon from "./icons/property.svg"
import vehicleIcon from "./icons/vehicle.svg"
import insuranceIcon from "./icons/insurance.svg"
import pensionIcon from "./icons/pension.svg"
import bankAccountIcon from "./icons/banking.svg"

const AssetTypeSelector = ({ onSelected }) => {
  const backAction = () => Turbolinks.visit("/client/assets")
  const [assetType, setAssetType] = useState(null)
  return (
    <>
      <BackArrow onClick={backAction} />
      <h1 className="page-title">Add asset</h1>

      <strong>Which type of asset are you adding?</strong><br />

      <div className="mt5 flex flex-wrap">
        <IconBigButton
          label="Property"
          icon={propertyIcon}
          onClick={() => setAssetType("property")}
          active={assetType === "property"}
          extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
        />

        <IconBigButton
          label="Vehicle"
          icon={vehicleIcon}
          onClick={() => setAssetType("vehicle")}
          active={assetType === "vehicle"}
          extraClasses={`ml4-ns mb5 w-100 w-33-ns`}
        />

        <IconBigButton
          label="Insurance"
          icon={insuranceIcon}
          onClick={() => setAssetType("insurance")}
          active={assetType === "insurance"}
          extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
        />

        <IconBigButton
          label="Pension"
          icon={pensionIcon}
          onClick={() => setAssetType("pension")}
          active={assetType === "pension"}
          extraClasses={`ml4-ns mb5 w-100 w-33-ns`}
        />

        <IconBigButton
          label="Bank Account"
          icon={bankAccountIcon}
          onClick={() => setAssetType("bank_account")}
          active={assetType === "bank_account"}
          extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
        />
      </div>

      <div className="flex items-center">
        <button onClick={() => assetType !== null && onSelected(assetType)} className="button -primary mr3">Continue</button>
        <button onClick={backAction} className="button">Cancel</button>
      </div>
    </>
  )
}

export default AssetTypeSelector
