import {find} from "lodash"
import React, { useState } from "react"

import { postRequest } from "../../helpers/api"
import BigButton from "../BigButton"

const initialValues = {
  has_will: null,
  like_a_will: null,
  allow_contact: null,
  company_id: "",
  company_selected: false,
  enquiry_delivered: false,
}

const NewWill = ({ companies }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [form, setValues] = useState({ ...initialValues })

  return (
    <>
      {form.has_will === null &&
        <>
          <h1 className="page-title tc">Have you made a Will yet?</h1>
          <div className="mt5 flex flex-column justify-center items-center">
            <BigButton onClick={() => setValues({ ...form, has_will: "yes" })} className="w-50 w-33-ns mb4">Yes</BigButton>
            <BigButton onClick={() => setValues({ ...form, has_will: "no" })} className="w-50 w-33-ns mb4">No</BigButton>
          </div>
        </>
      }
      {form.has_will === "yes" &&
        <div className="tc narrow-container">
          <h1 className="page-title tc">To use Asset Nexus your Will needs to be stored with one of our Legal Partners.</h1>
          <a href="/" className="button -primary">Back to Dashboard</a>
        </div>
      }
      {form.has_will === "no" && form.like_a_will === null &&
        <>
          <h1 className="page-title tc">Would you like to make a Will?</h1>
          <div className="mt5 flex flex-column justify-center items-center">
            <BigButton onClick={() => setValues({ ...form, like_a_will: "yes" })} className="w-50 w-33-ns mb4">Yes</BigButton>
            <BigButton onClick={() => Turbolinks.visit("/")} className="w-50 w-33-ns mb4">No</BigButton>
          </div>
        </>
      }
      {form.has_will === "no" && form.like_a_will === "yes" && !form.company_selected &&
        <>
          <h1 className="page-title tc">Please select a firm closest to you to make your Will</h1>
          <div className="mt5 flex flex-column justify-center items-center">
            <div className="form-field w-50 w-33-ns">
              <select value={form.company_id} onChange={e => setValues({ ...form, company_id: e.target.value })}>
                <option value="">Please select</option>
                {companies.map(company => (
                  <option key={company.id} value={company.id}>
                    {company.name}{company.address_town &&
                      `, ${company.address_town}`
                    }
                  </option>
                ))}
              </select>
            </div>

            <button onClick={() => {
              if (form.company_id.length) {
                setValues({ ...form, company_selected: true })
              } else {
                alert("Please select a firm")
              }
            }} className="button -primary">Continue</button>
          </div>
        </>
      }
      {form.has_will === "no" && form.like_a_will === "yes" && form.company_selected && !form.enquiry_delivered &&
        <>
          <h1 className="page-title tc">Would you like to be contacted by {find(companies, { id: parseInt(form.company_id) }).name}?</h1>
          <div className="mt5 flex flex-column justify-center items-center">
            <BigButton
              className="w-50 w-33-ns mb4"
              onClick={async () => {
                if (isSaving) return
                setIsSaving(true)
                await postRequest("/api/enquiries", { company_id: form.company_id })
                setValues({ ...form, enquiry_delivered: true })
                setIsSaving(false)
              }}
            >
              Yes
            </BigButton>
            <BigButton onClick={() => Turbolinks.visit("/")} className="w-50 w-33-ns mb4">No</BigButton>
          </div>
        </>
      }
      {form.has_will === "no" && form.like_a_will === "yes" && form.company_selected && form.enquiry_delivered &&
        <div className="tc">
          <h1 className="page-title tc">Your enquiry has been sent.</h1>
          <p>{find(companies, { id: parseInt(form.company_id) }).name} will be in touch with you soon.</p>
          <a href="/" className="button -primary mt4">Back to Dashboard</a>
        </div>
      }
    </>
  )
}

export default NewWill
