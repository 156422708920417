import React from "react"
import Tooltip from "rc-tooltip"

type Props = {
  icon: string
  tooltipText: string
  className?: string
  placement?: string
}

const IconWithTooltip: React.FC<Props> = ({ icon, tooltipText, className, placement }) => {
  return (
    <Tooltip placement={placement ? placement : 'left'} trigger={["hover"]} overlay={<span>{tooltipText}</span>}>
      <i className={`material-icons ${className}`}>{icon}</i>
    </Tooltip>
  )
}

export default IconWithTooltip
