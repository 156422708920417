import React, { useState } from "react"
import ReactDataGrid from "react-data-grid"
import ContentBox from "../ContentBox"
import { putRequest, getRequest } from "../../helpers/api"

type Document = {
  id: number
  company_id: number
  company: {
    ref_prefix: string
    name: string
    full_address: string
  }
  record_id: number
  record_type: string
  document_type: string
  filename: string
  file_url: string
  size_in_bytes: number
  group?: string
  title?: string
  document_date?: string
  comments?: string
  user_id: number
  user: {
    email: string
    first_name: string
    last_name: string
    role: string
  }
  created_at: string
  updated_at?: string
}

type Props = {
  documents: Document[]
}

const columns = [
  { key: 'id', name: 'ID', width: 50 },
  { key: 'company_name', name: 'Company', formatter: ({ row }: { row: Document }) => row.company.name },
  { key: 'document_type', name: 'Type', width: 160 },
  { key: 'filename', name: 'File', width: 160, formatter: ({ row }: { row: Document }) => (
    <a href={row.file_url} target="_blank">{row.filename}</a>
  ) },
  // { key: 'title', name: 'Title', width: 160 },
  { key: 'group', name: 'Group', width: 160 },
  { key: 'attached', name: 'Attached to', formatter: ({ row }: { row: Document }) => `${row.record_type} ${row.record_id}` },
  { key: 'document_date', name: 'Document date', width: 145, editable: true },
]

const DocumentValidation: React.FC<Props> = ({ documents }) => {
  const [data, setData] = useState(documents)
  return (
    <ContentBox>
      <ReactDataGrid
        columns={columns}
        rowGetter={(i: number) => data[i]}
        rowsCount={data.length}
        minHeight={500}
        enableCellSelect={true}
        onGridRowsUpdated={async ({ fromRow, toRow, updated }) => {
          if (fromRow !== toRow) return console.log("Tried unsupported event")

          const doc = data[fromRow]

          const resp = await putRequest(`/api/documents/${doc.id}`, updated)
          const json = await resp.json()

          console.log("onGridRowsUpdated", json)

          const docsResp = await getRequest("/admin/documents.json", {})
          const newDocs = await docsResp.json()
          setData(newDocs)
        }}
      />
    </ContentBox>
  )
}

export default DocumentValidation
