import React, { useState } from "react"
import Turbolinks from "turbolinks"
import { putRequest } from "../../helpers/api"

type Props = {
  id: number
  validated: boolean
}

const ValidateButton: React.FC<Props> = ({ id, validated }) => {
  return (
    <button
      onClick={async () => {
        if (window.confirm("Are you sure?")) {
          await putRequest(`/api/assets/${id}/${validated ? 'invalidate' : 'validate'}`, {})
          Turbolinks.visit()
        }
      }}
      className="button -with-icon mr3-ns"
    >
      {validated ?
        <>
          <i className="material-icons">close</i>
          Invalidate
        </>
      :
        <>
          <i className="material-icons">check</i>
          Validate
        </>
      }
    </button>
  )
}

export default ValidateButton
