document.addEventListener("DOMContentLoaded", () => {
  document.addEventListener("click", (e) => {
    if (e.target === document.querySelector(".hamburger")) {
      document.querySelector(".sidebar-container").classList.add("-open")
    }

    if (e.target === document.querySelector(".sidebar-container .close-cross")) {
      document.querySelector(".sidebar-container").classList.remove("-open")
    }
  })
})
