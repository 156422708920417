import { find, map, sortBy } from "lodash"
import React, { useState, useEffect } from "react"
import PlacesAutocomplete, { geocodeByPlaceId } from "react-places-autocomplete"
import { getCodeList } from "country-list"

const countries = sortBy(map(getCodeList(), (name, code) => ({ name, code })), c => c.name)

export interface AddressInfo {
  address_line_1: string
  address_line_2: string
  address_town: string
  address_postcode: string
  address_country: string
}

interface Props {
  label?: string
  value?: AddressInfo
  onChange(address: AddressInfo): void
  errors: {
    field: string
    errors: string[]
  }[]
}

export const buildAddress = ({ address_line_1, address_line_2, address_town, address_postcode, address_country }: AddressInfo) => ({
  address_line_1,
  address_line_2,
  address_town,
  address_postcode,
  address_country,
})

const initialValues = {
  address_line_1: "",
  address_line_2: "",
  address_town: "",
  address_postcode: "",
  address_country: "",
}

const AddressFinder = ({ label, value, errors, onChange }: Props) => {
  const [searchValue, setSearchValue] = useState("")
  const [address, setAddress] = useState(value || { ...initialValues })
  const [manualMode, setManualMode] = useState(false)

  useEffect(() => {
    if (value) {
      setAddress(value)
    }
  }, [value])

  function changeValue(newAddress) {
    setAddress(newAddress)
    onChange(newAddress)
    setSearchValue("")
  }

  if (manualMode) {
    return (
      <div className="mb3">
        {label && <label>{label}</label>}

        <div className="form-field -maxlength">
          <label htmlFor="address_line_1">Address building and street</label>
          <input type="text" id="address_line_1" value={address.address_line_1} onChange={e => changeValue({ ...address, address_line_1: e.target.value })} />
          {find(errors, row => row.field === "address_line_1") && <span className="hint -error">{find(errors, row => row.field === "address_line_1").errors.join(", ")}</span>}
          <input type="text" id="address_line_2" value={address.address_line_2} onChange={e => changeValue({ ...address, address_line_2: e.target.value })} className="mt3" />
          {find(errors, row => row.field === "address_line_2") && <span className="hint -error">{find(errors, row => row.field === "address_line_2").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="address_town">Town or city</label>
          <input type="text" id="address_town" value={address.address_town} onChange={e => changeValue({ ...address, address_town: e.target.value })} />
          {find(errors, row => row.field === "address_town") && <span className="hint -error">{find(errors, row => row.field === "address_town").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="address_postcode">Postcode</label>
          <input type="text" id="address_postcode" value={address.address_postcode} onChange={e => changeValue({ ...address, address_postcode: e.target.value })} />
          {find(errors, row => row.field === "address_postcode") && <span className="hint -error">{find(errors, row => row.field === "address_postcode").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="address_country">Country</label>
          <select id="address_country" value={address.address_country} onChange={e => changeValue({ ...address, address_country: e.target.value })}>
            <option value="">Select</option>
            <option value="GB">United Kingdom</option>
            <option value="IE">Ireland</option>
            <option value="US">United States</option>
            <option value="">--</option>
            {map(countries, country => (
              <option key={country.code} value={country.code.toUpperCase()}>{country.name}</option>
            ))}
          </select>
          {find(errors, row => row.field === "address_country") && <span className="hint -error">{find(errors, row => row.field === "address_country").errors.join(", ")}</span>}
        </div>
        <span className="db blue underline mv3 pointer f6" onClick={() => setManualMode(false)}>Search for address</span>
      </div>
    )
  }

  return (
    <div className="mb3">
      <PlacesAutocomplete
        value={searchValue}
        onChange={term => setSearchValue(term)}
        onSelect={(_address, placeID) => {
          geocodeByPlaceId(placeID)
            .then((results) => {
              console.log("results", status, results[0])
              const place = results[0]

              if (!place || !place.address_components) return console.error("Unable to get address components", results)

              let streetNumber = find(place.address_components, comp => comp.types[0] === "street_number")
              let streetName = find(place.address_components, comp => comp.types[0] === "route")
              const town = find(place.address_components, comp => comp.types[0] === "postal_town")
              const country = find(place.address_components, comp => comp.types[0] === "country")
              const postalCode = find(place.address_components, comp => comp.types[0] === "postal_code")

              if (streetNumber) streetNumber = streetNumber.short_name
              if (streetName) streetName = streetName.long_name
              const address = {
                address_line_1: [streetNumber, streetName].join(" "),
                address_line_2: "",
                address_town: town ? town.short_name : "",
                address_postcode: postalCode ? postalCode.short_name : "",
                address_country: country ? country.short_name : ""
              }
              changeValue(address)
            })
        }}
        searchOptions={{
          types: ["address"],
          componentRestrictions: {country: "gb"}
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <div className="form-field -maxlength">
              <label htmlFor="address_search">{label ? label : 'Find an address'}</label>
              <input
                {...getInputProps({
                  autoComplete: 'none',
                  placeholder: 'Search for an address...',
                  id: 'address_search',
                  className: 'location-search-input',
                })}
              />
              <div className="autocomplete-dropdown-container relative">
                <div className="absolute w-100">
                  {loading && <div className="pa3 tc bg-light-gray">Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = "pa3 bb bl br b--light-gray"
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' }
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              {address.address_line_1 &&
                <p className="lh-copy">
                  <span className="fw6 ttu f6 near-black">Address</span><br />
                  {address.address_line_1}<br />
                  {address.address_line_2 &&
                    <span>{address.address_line_2}<br /></span>
                  }
                  {address.address_town}<br />
                  {`${address.address_postcode}, ${address.address_country}`}

                </p>
              }
              <span id="enter_manual_address" className="db blue underline mv3 pointer f6" onClick={() => setManualMode(true)}>Enter address manually</span>
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  )
}

export default AddressFinder
