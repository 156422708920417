import React, { useState } from "react"

import IconBigButton from "../../IconBigButton"
import propertyIcon from "../../NewAsset/icons/property.svg"
import commercialIcon from "../../NewAsset/icons/commercial.svg"
import landIcon from "../../NewAsset/icons/land.svg"

interface Props {
  value: string
  onChange(value: string): void
}

const PropertyTypeSelector = ({ value, onChange }) => {
  const [assetType, setAssetType] = useState(value)
  function changeValue(value: string): void {
    setAssetType(value)
    onChange(value)
  }
  return (
    <div className="mv5 flex flex-wrap">
      <IconBigButton
        label="Residential"
        icon={propertyIcon}
        onClick={() => changeValue("residential")}
        active={assetType === "residential"}
        extraClasses={`mr4 mb4`}
      />

      <IconBigButton
        label="Commercial"
        icon={commercialIcon}
        onClick={() => changeValue("commercial")}
        active={assetType === "commercial"}
        extraClasses={`mr4 mb4`}
      />

      <IconBigButton
        label="Land"
        icon={landIcon}
        onClick={() => changeValue("land")}
        active={assetType === "land"}
        extraClasses={`mb4`}
      />
    </div>
  )
}

export default PropertyTypeSelector
