import React from "react"
import { TimelineItem } from "./types"
import { formatCurrency } from "../../helpers/string"
import { colorNames } from "../../helpers/colors"
import HeaderItem from "./HeaderItem";

type Props = {
  timelineItems: TimelineItem[]
  itemsToShow: (string|number)[]
  onItemToggle(id: string|number, enabled: boolean): void
  changeView(field: string, r: any): void
}

const Headers: React.FC<Props> = (props) => {
  const { timelineItems, itemsToShow, onItemToggle, changeView } = props

  return (
    <div className="ph4 pt3 mb4">
      <div className="timeline-legend flex items-stretch">
        {timelineItems.map(item => {
          return (
            <HeaderItem
              key={item.id}
              item={item}
              itemsToShow={itemsToShow}
              onItemToggle={onItemToggle}
              onClick={() => changeView(item.type, item.sub_type)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Headers
