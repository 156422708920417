import React, { useState } from "react"
import ReactDataGrid from "react-data-grid"
import ContentBox from "../ContentBox"

type Props = {
  assets: any[]
}

const columns = [
  { key: 'id', name: 'ID', width: 50 }
]

const AssetValidation: React.FC<Props> = ({ assets }) => {
  console.log("assets", assets)
  return (
    <ContentBox>
      {/* <ReactDataGrid
        columns={columns}
        rowGetter={(i: number) => documents[i]}
        rowsCount={documents.length}
        minHeight={500}
        enableCellSelect={true}
        onGridRowsUpdated={({ fromRow, toRow, updated }) => {
          console.log("onGridRowsUpdated", fromRow, toRow, updated)
        }}
      /> */}
    </ContentBox>
  )
}

export default AssetValidation
