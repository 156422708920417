import _, { find } from "lodash"
import React  from "react"

import { formatCurrencyShort } from "../../../helpers/string"
import { postRequest, deleteRequest, putRequest } from "../../../helpers/api"
import { dictionary } from "../../../helpers/types"

import PropertyTypeSelector from "../../assets/Property/PropertyTypeSelector"
import RadioInput from "../../inputs/RadioInput"
import MultiSelect from "../../inputs/MultiSelect"
import BackArrow from "../../BackArrow"
import AddressFinder from "../../AddressFinder"
import FlashAlert from "../../FlashAlertWithPortal"

interface PropertyForm {
  asset_sub_type: string,
  owns_property: boolean,
  address_line_1: string,
  address_line_2: string,
  address_town: string,
  address_postcode: string,
  address_country: string,
  current_value: number,
  mortgage: boolean,
  main_residence: boolean,
  rented: boolean,
  renting_to_tenant: boolean,
  mortgage_id: string,
}

interface PreviousResidenceForm {
  id: number
  renting_to_tenant: boolean
  main_residence: boolean
}

interface FormError {
  field: string
  errors: string[]
}

interface Props {
  mortgages: dictionary[]
  residences: dictionary[]
  hideTitle: boolean
  onBack(): void
  onSuccess(): void
}

interface State {
  isSaving: boolean
  errors: FormError[]
  form: PropertyForm
  previousResidence: PreviousResidenceForm
  soldResidencesIds: number[]
  anyPropertySold: boolean
}

export default class Property extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
      errors: [],
      form: {
        asset_sub_type: "residential",
        owns_property: null,
        mortgage: null,
        main_residence: null,
        rented: null,
        renting_to_tenant: null,
        mortgage_id: "",
        address_line_1: "",
        address_line_2: "",
        address_town: "",
        address_postcode: "",
        address_country: "",
        current_value: null,
      },
      previousResidence: {
        id: null,
        main_residence: null,
        renting_to_tenant: null
      },
      soldResidencesIds: [],
      anyPropertySold: null
    }
  }

  mainResidence() {
    const { residences } = this.props
    const main = residences.find(({ main_residence }) => main_residence)
    return main || residences[0]
  }

  setFormValues(values) {
    this.setState({ form: values })
  }

  async deleteResidence(id) {
    let errors = []
    const response = await deleteRequest(`/api/assets/${id}`, {})
    if (response.status >= 400) {
      const data = await response.json()
      errors = data.validation_errors
    }
    return errors
  }

  async updateResidence(id, params) {
    let errors = []
    const response = await putRequest(`/api/assets/${id}`, params)
    if (response.status >= 400) {
      const data = await response.json()
      errors = data.validation_errors
    }
    return errors
  }

  async submitPreviousResidence() {
    const { soldResidencesIds } = this.state
    const { id, renting_to_tenant } = this.state.previousResidence

    let requests = []
    if (soldResidencesIds.length > 0) {
      soldResidencesIds.forEach(id => {
        requests.push(this.deleteResidence(id))
      })
    } else if (id && renting_to_tenant) {
      requests.push(this.updateResidence(id, { renting_to_tenant, main_residence: false }))
    }

    const requestsErrors = await Promise.all(requests)
    return _.flatten(requestsErrors)
  }

  onSubmit = async (event) => {
    event.preventDefault()
    const { onSuccess } = this.props
    const { form, isSaving } = this.state

    if (isSaving) return

    this.setState({ isSaving: true })

    const resp = await postRequest(`/api/assets`, { asset_category: "property", ...form })
    const json = await resp.json()
    const prevResidencesErrors = await this.submitPreviousResidence()

    if (resp.status === 201 && prevResidencesErrors.length < 1) {
      onSuccess()
    } else {
      console.log("failed resp", resp)
      const errors = [...prevResidencesErrors, ...json.validation_errors]
      this.setState({ errors: errors })
    }

    this.setState({ isSaving: false })
  }

  renderAlert() {
    if (this.state.form.owns_property === false) {
      const message = "YOU CAN ONLY ADD ASSETS WHICH YOU OWN." +
        " ASSETS WHICH YOU ARE RENTING DO NOT CARRY ANY MONETARY VALUE TOWARDS YOUR LEGACY"

      return (
        <FlashAlert
          type="error"
          message={message}
          onClose={() => {}}
        />
      )
    }
  }

  renderOwnershipBlock() {
    const { form } = this.state

    return (
      <div className="form-field -maxlength">
        <label>Do you own this property?</label>
        <RadioInput
          options={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          value={form.owns_property}
          onChange={owns_property => {
            const newValues = { ...form, owns_property }
            if (owns_property) newValues.rented = false
            this.setFormValues(newValues)
          }}
        />
      </div>
    )
  }

  renderResidenceBlock() {
    const { form, errors } = this.state

    if (form.owns_property) {
      return (
        <div className="form-field -maxlength">
          <label>Is this property your main residence?</label>
          <RadioInput
            value={form.main_residence}
            onChange={main_residence => this.setFormValues({...form, main_residence})}
            options={[
              {value: true, label: "Yes"},
              {value: false, label: "No"},
            ]}
          />
          {find(errors, row => row.field === "main_residence") &&
          <span
            className="hint -error">{find(errors, row => row.field === "main_residence").errors.join(", ")}</span>
          }
        </div>
      )
    }
  }

  renderMortgageBlock() {
    const { errors, form } = this.state
    const { mortgages } = this.props

    if (!form.owns_property) return null
    if (form.main_residence === null) return null
    if (form.main_residence === false && form.renting_to_tenant === null) return null

    return (
      <>
        <div className="form-field -maxlength">
          <label>Is there a mortgage on this property?</label>
          <RadioInput
            value={form.mortgage}
            onChange={mortgage => this.setFormValues({ ...form, mortgage })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "mortgage") && <span className="hint -error">{find(errors, row => row.field === "mortgage").errors.join(", ")}</span>}
        </div>

        {(mortgages.length > 0 && form.mortgage === true) &&
        <div className="form-field -maxlength">
          <label htmlFor="property_id">Which mortgage belongs to this property?</label>
          <select id="mortgage_id" value={form.mortgage_id || ""} onChange={e => this.setFormValues({ ...form, mortgage_id: e.target.value })}>
            <option value="">Please select</option>
            {mortgages.map(mortgage => <option key={mortgage.id} value={mortgage.id}>{mortgage.title} {formatCurrencyShort(mortgage.current_value)}</option>)}
          </select>
          {find(errors, row => row.field === "mortgage_id") && <span className="hint -error">{find(errors, row => row.field === "mortgage_id").errors.join(", ")}</span>}
        </div>
        }
      </>
    )
  }

  renderRentingBlock() {
    const { form } = this.state

    if (form.owns_property && form.main_residence === false) {
      return (
        <div className="form-field -maxlength">
          <label>Are you renting this property to a tenant?</label>
          <RadioInput
            value={form.renting_to_tenant}
            onChange={renting_to_tenant => this.setFormValues({...form, renting_to_tenant})}
            options={[
              {value: true, label: "Yes"},
              {value: false, label: "No"},
            ]}
          />
        </div>
      )
    }
  }

  renderActionButtons() {
    const { isSaving, form } = this.state
    const { onBack } = this.props

    if (form.owns_property === null) return

    return (
      <div className="flex items-center">
        {form.owns_property &&
          <button disabled={isSaving} type="submit" className="button -primary mr3">
            {isSaving ? 'Saving...' : 'Add property'}
          </button>
        }
        <button disabled={isSaving} onClick={onBack} className="button">Cancel</button>
      </div>
    )
  }

  renderAnyPropertySold() {
    const { residences } = this.props
    const { anyPropertySold, form } = this.state

    if (!(form.asset_sub_type === 'residential' && residences.length > 1)) return

    return (
      <div className="form-field -maxlength">
        <label>Have you sold any property?</label>
        <RadioInput
          value={anyPropertySold}
          onChange={sold => this.setState({ anyPropertySold: sold })}
          options={[
            {value: true, label: "Yes"},
            {value: false, label: "No"},
          ]}
        />
      </div>
    )
  }

  renderPreviousResidenceSold() {
    const { form, soldResidencesIds, anyPropertySold } = this.state
    const { residences } = this.props
    const mainResidence = this.mainResidence()
    const options = residences.map(({ id, full_address }) => ({ value: id, label: full_address }))

    if (!(form.asset_sub_type === 'residential' && residences.length > 0)) return

    if (residences.length < 2) {
      const residenceSold = anyPropertySold === null ? null : _.includes(soldResidencesIds, mainResidence.id)

      return (
        <div className="form-field -maxlength">
          <label>Have you sold <span className="i">{mainResidence.full_address}</span>?</label>
          <RadioInput
            value={residenceSold}
            onChange={sold => {
              if (sold) {
                this.setState({ soldResidencesIds: [mainResidence.id], anyPropertySold: true })
              } else {
                this.setState({ soldResidencesIds: [], anyPropertySold: false })
              }
            }}
            options={[
              {value: true, label: "Yes"},
              {value: false, label: "No"},
            ]}
          />
        </div>
      )
    } else {
      if (this.state.anyPropertySold) {
        return (
          <div className="form-field -maxlength">
            <MultiSelect
              prompt="Choose sold properties"
              options={options}
              value={soldResidencesIds}
              onChange={ids => this.setState({soldResidencesIds: ids})}
            />
          </div>
        )
      }
    }
  }

  renderPreviousResidenceRenting() {
    const { form, previousResidence, anyPropertySold, soldResidencesIds } = this.state
    const residence = this.mainResidence()

    if (form.asset_sub_type === 'residential' && residence &&
      anyPropertySold === false) {
      return (
        <div className="form-field -maxlength">
          <label>Are you renting <span className="i">{residence.full_address}</span> to a tenant?</label>
          <RadioInput
            value={previousResidence.renting_to_tenant}
            onChange={renting_to_tenant => this.setState({
              previousResidence: { ...previousResidence, id: residence.id, renting_to_tenant }
            })}
            options={[
              {value: true, label: "Yes"},
              {value: false, label: "No"},
            ]}
          />
        </div>
      )
    }
  }

  shouldRenderMainForm() {
    const { form, previousResidence, anyPropertySold } = this.state
    const residence = this.mainResidence()

    return form.asset_sub_type !== 'residential' ||
      (residence &&
        (anyPropertySold === true ||
          anyPropertySold === false && previousResidence.renting_to_tenant !== null
        )
      )
  }

  render() {
    const { form, errors } = this.state

    return (
      <>
        {this.renderAlert()}
        {!this.props.hideTitle &&
          <>
            <BackArrow onClick={this.props.onBack} />
            <h1 className="page-title">Add a property</h1>
          </>
        }

        <form onSubmit={this.onSubmit}>
          <PropertyTypeSelector
            value={form.asset_sub_type}
            onChange={asset_sub_type => this.setFormValues({ ...form, asset_sub_type })}
          />
          {find(errors, row => row.field === "asset_sub_type") &&
            <span className="hint -error">
              {find(errors, row => row.field === "asset_sub_type").errors.join(", ")}
            </span>
          }
          {this.renderAnyPropertySold()}
          {this.renderPreviousResidenceSold()}
          {this.renderPreviousResidenceRenting()}

          {this.shouldRenderMainForm() &&
            <>
              <div className="form-field -maxlength">
                <label>What is your new property address?</label>
                <AddressFinder
                  errors={errors}
                  onChange={ address => {this.setFormValues({ ...form, ...address })} }
                />
              </div>
              <div className="form-field -maxlength">
                <label htmlFor="current_value">Purchase Amount</label>
                <input type="number"
                       id="current_value"
                       value={form.current_value || ''}
                       onChange={e => this.setFormValues({ ...form, current_value: e.target.value })} />
              </div>
              {this.renderOwnershipBlock()}
              {this.renderResidenceBlock()}
              {this.renderRentingBlock()}
              {this.renderMortgageBlock()}
            </>
          }

          {this.renderActionButtons()}
        </form>
      </>
    )
  }
}
