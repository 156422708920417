import _ from "lodash"
import React from "react"

type SelectOption = { value: any, label: string }

type Props = {
  name?: string
  prompt?: string
  options: SelectOption[]
  value?: any[]
  onChange?(value: any[]): void
}

export default class MultiSelect extends React.Component<Props> {
  onOptionSelect = ({ target }) => {
    const { onChange, value } = this.props
    const newValue = _.union(value, [target.value])
    onChange(newValue)
  }

  onOptionRemove = (val) => {
    const { onChange, value } = this.props
    const newValue = value.filter(optionValue => optionValue != val)
    onChange(newValue)
  }

  valueLabel(value) {
    const option = this.props.options.find(({ value: val }) => value == val)
    return option ? option.label : value
  }

  renderSelectedValues() {
    const { value: values = [] } = this.props

    return (
      <div className="nested-list-reset">
        <ul>
          {values.map(val => {
            return (
              <li key={val} className="i mb3">
                {this.valueLabel(val)}&nbsp;
                <span
                  className="close-cross"
                  onClick={() => this.onOptionRemove(val)}
                >
                  &times;
                </span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  render() {
    const { options, prompt, value = [] } = this.props

    const notSelectedOptions = options.filter(({ value: optionValue }) => {
      return !value.find(val => val == optionValue)
    })

    return (
      <div className="multiselect-container">
        {this.renderSelectedValues()}
        <select value={''} onChange={this.onOptionSelect}>
          {prompt &&
            <option value="" disabled>{prompt}</option>
          }
          {notSelectedOptions.map(({ value, label }) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            }
          )}
        </select>
      </div>
    )
  }
}
