import React, { useState } from "react"

import AssetTypeSelector from "./AssetTypeSelector"
import DebtTypeSelector from "./DebtTypeSelector"
import Property from "./forms/property"
import Vehicle from "./forms/vehicle"
import Insurance from "./forms/insurance"
import Pension from "./forms/pension"
import BankAccount from "./forms/bankAccount"
import Mortgage from "./forms/mortgage"
import BankLoan from "./forms/bankLoan"
import CreditCard from "./forms/creditCard"
import StoreCard from "./forms/storeCard"
import StudentLoan from "./forms/studentLoan"

const NewAsset = ({ debt, mortgages, residences }) => {
  const [selectedType, setAssetType] = useState(null)

  if (selectedType === null && debt) {
    return (<DebtTypeSelector onSelected={setAssetType} />)
  }

  if (selectedType === null) {
    return (<AssetTypeSelector onSelected={setAssetType} />)
  }

  switch(selectedType) {
    case 'property':
      return <Property
        mortgages={mortgages || []}
        residences={residences || []}
        onSuccess={asset => Turbolinks.visit(`/client/assets`)}
        onBack={() => setAssetType(null)}
      />
    case 'vehicle':
      return <Vehicle onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    case 'insurance':
      return <Insurance onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    case 'pension':
      return <Pension onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    case 'bank_account':
      return <BankAccount onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    case 'mortgage':
      return <Mortgage onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    case 'bank_loan':
      return <BankLoan onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    case 'credit_card':
      return <CreditCard onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    case 'store_card':
      return <StoreCard onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    case 'student_loan':
      return <StudentLoan onSuccess={asset => Turbolinks.visit(`/client/assets`)} onBack={() => setAssetType(null)} />
    default:
      return <span>Invalid selection</span>
  }
}

export default NewAsset
