import React  from 'react'
import { find, each } from 'lodash'

import { postRequest } from '../../../helpers/api'

import BackArrow from '../../BackArrow'
import IconBigButton from '../../IconBigButton'
import RadioInput from '../../inputs/RadioInput'
import AddressFinder, { buildAddress } from '../../AddressFinder'
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

interface FormFields {
  asset_sub_type: string
  provider_name: string
  address_line_1: string
  address_line_2: string
  address_town: string
  address_postcode: string
  address_country: string
  account_number: string
  sort_code: string
  swift_bic: string
  company_name: string
  joint_account: boolean
  holder_full_name: string
  holder_address_line_1: string
  holder_address_line_2: string
  holder_address_town: string
  holder_address_postcode: string
  holder_address_country: string
  holder_email: string
  holder_contact_number: string
}

interface FormError {
  field: string
  errors: string[]
}

interface Props {
  hideTitle?: boolean
  onBack(): void
  onSuccess(): void
}

interface State {
  currentStep: string
  isSaving: boolean
  errors: FormError[]
  form: FormFields
}

export default class BankAccount extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: 'accountType',
      isSaving: false,
      errors: [],
      form: {
        asset_sub_type: '',
        provider_name: '',
        address_line_1: '',
        address_line_2: '',
        address_town: '',
        address_postcode: '',
        address_country: '',
        account_number: '',
        sort_code: '',
        swift_bic: '',
        company_name: '',
        joint_account: false,
        holder_full_name: '',
        holder_address_line_1: '',
        holder_address_line_2: '',
        holder_address_town: '',
        holder_address_postcode: '',
        holder_address_country: '',
        holder_email: '',
        holder_contact_number: '',
      }
    }
  }

  setFormValues(values: FormFields) {
    this.setState({ form: values })
  }

  setStep(step: string) {
    this.setState({ currentStep: step })
  }

  onSubmit = async (event) => {
    event.preventDefault()

    const { onSuccess } = this.props
    const { form, isSaving } = this.state

    if (isSaving) return

    this.setState({ isSaving: true })

    const response = await postRequest(`/api/assets`, {
      asset_category: 'bank_account',
      ...form
    })

    const json = await response.json()

    if (response.status === 201) {
      onSuccess()
    } else {
      this.setState({ errors: json.validation_errors })
    }

    this.setState({ isSaving: false })
  }

  renderHeading() {
    const { hideTitle, onBack } = this.props

    if (hideTitle) return

    return (
      <>
        <BackArrow onClick={onBack} />
        <h1 className="page-title">Add a bank account</h1>
      </>
    )
  }

  renderFieldErrors(field: string, errors: FormError[]) {
    const fieldErrors = find(errors, row => row.field === field)

    if (fieldErrors) {
      return <span className="hint -error">{fieldErrors.errors.join(', ')}</span>
    }
  }

  renderAccountTypeSelector() {
    const { onBack } = this.props
    const { form } = this.state

    return (
      <>
        <div className="mt5 flex flex-wrap">
          <IconBigButton
            label="Savings"
            onClick={() => this.setFormValues({ ...form, asset_sub_type: 'savings' })}
            active={form.asset_sub_type === 'savings'}
            extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
          />

          <IconBigButton
            label="Current"
            onClick={() => this.setFormValues({ ...form, asset_sub_type: 'current' })}
            active={form.asset_sub_type === 'current'}
            extraClasses={`ml4-ns mb5 w-100 w-33-ns`}
          />

          <IconBigButton
            label="Credit Union"
            onClick={() => this.setFormValues({ ...form, asset_sub_type: 'credit_union' })}
            active={form.asset_sub_type === 'credit_union'}
            extraClasses={`mr4-ns mb5 w-100 w-33-ns`}
          />

          <IconBigButton
            label="Business"
            onClick={() => this.setFormValues({ ...form, asset_sub_type: 'business' })}
            active={form.asset_sub_type === 'business'}
            extraClasses={`ml4-ns mb5 w-100 w-33-ns`}
          />
        </div>

        <div className="flex items-center">
          <button
            type="button"
            className="button -primary mr3"
            disabled={form.asset_sub_type === ''}
            onClick={() => this.setStep('jointHolderDetails')}
            children="Continue"
          />

          <button
            type="button"
            className="button"
            onClick={onBack}
            children="Cancel"
          />
        </div>
      </>
    )
  }

  validateJointHolderFields() {
    const { form } = this.state
    const requiredFields = ['holder_full_name', 'holder_contact_number']

    let errors = []

    if (form.joint_account) {
      each(form, (value, field) => {
        if (requiredFields.indexOf(field) === -1) return

        if (value == null || value.length < 1) {
          errors.push({ field, errors: ['is required'] })
        }
      })
    }

    this.setState({ errors })

    return errors.length === 0
  }

  renderJointHolderFields() {
    const { form, errors } = this.state

    return (
      <>
        <div className="form-field -maxlength">
          <label>Is this a Joint account?</label>

          <RadioInput
            options={[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]}
            value={form.joint_account}
            onChange={(isJointAccount) => {
              this.setFormValues({ ...form, joint_account: isJointAccount })
            }}
          />
        </div>

        {form.joint_account === true &&
          <>
            <strong className="db mt5">Joint account holder details</strong>

            <div className="form-field -maxlength">
              <label htmlFor="holder_full_name">Full name</label>

              <input
                type="text"
                id="holder_full_name"
                value={form.holder_full_name}
                onChange={(event) => {
                  this.setFormValues({ ...form, holder_full_name: event.target.value })
                }}
              />

              {this.renderFieldErrors('holder_full_name', errors)}
            </div>

            <div className="form-field -maxlength">
              <AddressFinder
                errors={[]}
                label="Address"
                value={buildAddress({
                  address_line_1: form.holder_address_line_1,
                  address_line_2: form.holder_address_line_2,
                  address_town: form.holder_address_town,
                  address_postcode: form.holder_address_postcode,
                  address_country: form.holder_address_country
                })}
                onChange={(address) => {
                  this.setFormValues({
                    ...form,
                    holder_address_line_1: address.address_line_1,
                    holder_address_line_2: address.address_line_2,
                    holder_address_town: address.address_town,
                    holder_address_postcode: address.address_postcode,
                    holder_address_country: address.address_country
                  })
                }}
              />
            </div>

            <div className="form-field -maxlength">
              <label htmlFor="holder_email">Email</label>

              <input
                type="text"
                id="holder_email"
                value={form.holder_email}
                onChange={(event) => {
                  this.setFormValues({ ...form, holder_email: event.target.value })
                }}
              />
            </div>

            <div className="form-field -maxlength">
              <label htmlFor="holder_contact_number">Contact number</label>

              <input
                type="text"
                id="holder_contact_number"
                value={form.holder_contact_number}
                onChange={(event) => {
                  this.setFormValues({ ...form, holder_contact_number: event.target.value })
                }}
              />

              {this.renderFieldErrors('holder_contact_number', errors)}
            </div>
          </>
        }

        <div className={`flex items-center ${form.joint_account === false && 'mt5'}`}>
          <button
            type="button"
            className="button -primary mr3"
            onClick={() => {
              let isValid = this.validateJointHolderFields()
              if (isValid) this.setStep('accountDetails')
            }}
            children="Continue"
          />

          <button
            type="button"
            className="button"
            onClick={() => this.setStep('accountType')}
            children="Back"
          />
        </div>
      </>
    )
  }

  renderAccountFields() {
    const { form, errors, isSaving } = this.state

    return (
      <>
        <div className="form-field -maxlength">
          <label htmlFor="provider_name">Bank name</label>

          <input
            type="text"
            id="provider_name"
            placeholder="e.g. Santander"
            value={form.provider_name}
            onChange={(event) => {
              this.setFormValues({ ...form, provider_name: event.target.value })
            }}
          />

          {this.renderFieldErrors('provider_name', errors)}
        </div>

        <div className="form-field -maxlength">
          <AddressFinder
            label="Bank address"
            errors={errors}
            onChange={(address) => {
              this.setFormValues({ ...form, ...address })
            }}
          />
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="account_number">Account number</label>

          <input
            type="text"
            id="account_number"
            value={form.account_number}
            onChange={(event) => {
              this.setFormValues({ ...form, account_number: event.target.value })
            }}
          />

          {this.renderFieldErrors('account_number', errors)}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="sort_code">Sort code</label>

          <input
            type="text"
            id="sort_code"
            value={form.sort_code}
            onChange={(event) => {
              this.setFormValues({ ...form, sort_code: event.target.value })
            }}
          />

          {this.renderFieldErrors('sort_code', errors)}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="swift_bic">SWIFT/BIC</label>

          <input
            type="text"
            id="swift_bic"
            value={form.swift_bic}
            onChange={(event) => {
              this.setFormValues({ ...form, swift_bic: event.target.value })
            }}
          />

          {this.renderFieldErrors('swift_bic', errors)}
        </div>

        {form.asset_sub_type === 'business' &&
          <div className="form-field -maxlength">
            <label htmlFor="sort_code">Company name</label>

            <input
              type="text"
              id="company_name"
              value={form.company_name}
              onChange={(event) => {
                this.setFormValues({ ...form, company_name: event.target.value })
              }}
            />

            {this.renderFieldErrors('company_name', errors)}
          </div>
        }

        <div className="flex items-center">
          <button
            type="submit"
            className="button -primary mr3"
            disabled={isSaving}
            children={isSaving ? 'Saving...' : 'Add account'}
          />

          <button
            type="button"
            className="button"
            disabled={isSaving}
            onClick={() => this.setStep('jointHolderDetails')}
            children="Back"
          />
        </div>
      </>
    )
  }

  renderSteps() {
    const { currentStep } = this.state

    switch (currentStep) {
      case 'accountType':
        return this.renderAccountTypeSelector()
      case 'jointHolderDetails':
        return this.renderJointHolderFields()
      case 'accountDetails':
        return this.renderAccountFields()
      default:
        return null
    }
  }

  render() {
    return (
      <>
        {this.renderHeading()}

        <form
          onSubmit={this.onSubmit}
          children={this.renderSteps()}
        />
      </>
    )
  }
}
