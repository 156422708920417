import React, { useState } from "react"

import AddressFinder, { AddressInfo } from "./AddressFinder"

interface Props {
  fieldWrap?: string
  fieldPrefix?: string
  defaultValue?: AddressInfo
}

const AddressFinderWithInputs = ({ fieldWrap, fieldPrefix, defaultValue }: Props) => {
  const [address, setAddress] = useState(defaultValue || {
    address_line_1: "",
    address_line_2: "",
    address_town: "",
    address_postcode: "",
    address_country: "",
  })

  function generateFieldName(name: string): string {
    if (fieldPrefix) name = `${fieldPrefix}_${name}`
    if (!fieldWrap) return name

    return `${fieldWrap}[${name}]`
  }

  return (
    <>
      <AddressFinder
        errors={[]}
        value={address as AddressInfo}
        onChange={address => {
          setAddress(address)
        }}
      />
      <input type="hidden" name={generateFieldName(`address_line_1`)} value={address.address_line_1} />
      <input type="hidden" name={generateFieldName(`address_line_2`)} value={address.address_line_2} />
      <input type="hidden" name={generateFieldName(`address_town`)} value={address.address_town} />
      <input type="hidden" name={generateFieldName(`address_postcode`)} value={address.address_postcode} />
      <input type="hidden" name={generateFieldName(`address_country`)} value={address.address_country} />
    </>
  )
}

export default AddressFinderWithInputs
