import React, { CSSProperties } from "react"

type Props = {
  label: string
  icon?: any
  onClick(): void
  active: boolean
  extraClasses?: string
}

const IconBigButton = ({ label, icon, onClick, active, extraClasses }: Props) => {
  const containerClass = `content-box pointer ${extraClasses}`
  let style: CSSProperties = {}
  if (active) {
    style.outline = "solid 2px #0058FF"
  }
  return (
    <div className={containerClass} style={style} onClick={onClick}>
      <div className="inner flex items-center">
        {icon && <img src={icon} alt={label} className="mr4" />}
        <h2 className="mb0">{label}</h2>
      </div>
    </div>
  )
}

export default IconBigButton
