import { sortBy } from "lodash"
import React from "react"
import { parse as parseDate, format as formatDate } from "date-fns"
import { formatCurrency } from "../../helpers/string"
import ContentBox from "../ContentBox"

interface Props {
  valuations: {
    id: number
    source: string
    value: number
    created_at: string
  }[]
}

const ValuationList = ({ valuations }: Props) => {
  valuations = sortBy(valuations, val => val.created_at).reverse()
  return (
    <ContentBox title="Valuation" grid collapsable extraClasses="mt5">
      <ul className="data-grid">
        {valuations.map(valuation => (
          <li key={valuation.id}>
            <div className="-fullwidth">
              <strong>{formatDate(parseDate(valuation.created_at), "DD/MM/YYYY")}</strong><br />
              {valuation.source}<br />
              {formatCurrency(valuation.value)}
            </div>
          </li>
        ))}
      </ul>
    </ContentBox>
  )
}

export default ValuationList
