import React, { useState, useReducer } from 'react'
import humanize from 'string-humanize'

import { dictionary } from '../../../helpers/types'
import { formatCurrency } from '../../../helpers/string'

import EditForm from './EditForm'
import ContentBox from '../../ContentBox'
import DocumentsTable from '../../DocumentsTable'
import UploadModal from '../../UploadModal'
import ValidationStatus from '../ValidationStatus'
import ValidateButton from '../ValidateButton'

interface Props {
  asset: dictionary
  role?: string
}

const StudentLoan = ({ asset, role }: Props) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [docsVersion, incrementDocsVersion] = useReducer(state => state + 1, 0)

  return (
    <>
      <div className="flex flex-column flex-row-ns items-start-ns">
        <div className="flex-auto">
          <span className={`db light-silver fw4 f5 mb2 ttu -${asset.category_label}`}>{humanize(asset.category_label)}</span>
          <h1 className="page-title">{asset.title}</h1>
        </div>

        {role === 'admin' && <ValidateButton id={asset.id} validated={!!asset.validated_at}/>}

        <button onClick={() => setShowEditForm(true)} className="button -primary -with-icon mv4 mv0-ns">
          <i className="material-icons">edit</i>
          Edit debt
        </button>
      </div>

      <EditForm
        assetId={asset.id}
        values={asset}
        open={showEditForm}
        onClose={() => setShowEditForm(false)}
      />

      <ContentBox
        title="Loan details"
        grid
        renderHeader={() => (
          <ValidationStatus
            type="debt"
            validated={asset.validated}
            validatedAt={asset.validated_at}
            invalidMessage="Upload a loan statement below to validate."
          />
        )}
      >
        <ul className="data-grid">
          <li>
            <div>
              <strong>Account reference number</strong>
              <br />
              {asset.account_reference_number}
            </div>

            <div>
              <strong>Amount</strong>
              <br />
              {formatCurrency(asset.current_value)}
            </div>
          </li>
        </ul>
      </ContentBox>

      <ContentBox
        title="Documents"
        collapsable
        extraClasses="mt5"
      >
        <div className="mt4">
          <DocumentsTable
            recordId={asset.id}
            recordType="Asset"
            recordGroup=""
            version={docsVersion}
            onUpdate={incrementDocsVersion}
            onAddNew={() => setShowUploadModal(true)}
          />
        </div>
      </ContentBox>

      <UploadModal
        title="Add document"
        open={showUploadModal}
        documentTypes={["loan_statement"]}
        recordId={asset.id}
        recordType="Asset"
        onUploadComplete={incrementDocsVersion}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  )
}

export default StudentLoan
