import { find, each, reduce } from "lodash"
import React, { useState } from "react"
import Turbolinks from "turbolinks"

import { dictionary } from "../../../helpers/types"
import { putRequest } from "../../../helpers/api"
import Modal from "../../Modal"
import MoneyInput from "../../inputs/MoneyInput"

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
}

function getInitialValues(values: dictionary): dictionary {
  const keys = ["asset_sub_type","provider_name","current_value","policy_reference","employer_name","pension_plan_type"]

  return reduce(keys, (memo, key) => {
    memo[key] = values[key]
    return memo
  }, {})
}

const EditForm = ({ assetId, open, values, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values) })

  return (
    <Modal title="Edit Pension" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          each(form, (value, field) => {
            if (["policy_reference", "policy_claim_number", "policy_start_date", "policy_end_date", "pension_plan_type", "employer_name"].indexOf(field) > -1 ) return
            if (value == null || value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="form-field -maxlength">
          <label htmlFor="asset_sub_type">Type of account</label>
          <select id="asset_sub_type" value={form.asset_sub_type} onChange={e => setValues({ ...form, asset_sub_type: e.target.value })}>
            <option value="">Please select</option>
            <option value="employer">Employer</option>
            <option value="state">State</option>
            <option value="private">Private</option>
            <option value="sipp">SIPP</option>
            <option value="sass">SASS</option>
          </select>
          {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}
        </div>

        {form.asset_sub_type === "employer" &&
          <>
            <div className="form-field -maxlength">
              <label htmlFor="employer_name">Employer name</label>
              <input type="text" id="employer_name" value={form.employer_name} onChange={e => setValues({ ...form, employer_name: e.target.value })} />
              {find(errors, row => row.field === "employer_name") && <span className="hint -error">{find(errors, row => row.field === "employer_name").errors.join(", ")}</span>}
            </div>
            <div className="form-field -maxlength">
              <label htmlFor="provider_name">Pension plan name</label>
              <input type="text" id="provider_name" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
              {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
            </div>
          </>
        }

        <div className="form-field -maxlength">
          <label htmlFor="provider_name">Provider name</label>
          <input type="text" id="provider_name" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
          {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Pension amount</label>
          <MoneyInput id="current_value" defaultValue={form.current_value} onChange={current_value => setValues({ ...form, current_value })} />
          {find(errors, row => row.field === "current_value") && <span className="hint -error">{find(errors, row => row.field === "current_value").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="policy_reference">Policy number</label>
          <input type="text" id="policy_reference" value={form.policy_reference || ""} onChange={e => setValues({ ...form, policy_reference: e.target.value })} />
          {find(errors, row => row.field === "policy_reference") && <span className="hint -error">{find(errors, row => row.field === "policy_reference").errors.join(", ")}</span>}
        </div>

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
