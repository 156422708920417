import React, { useState } from "react"
import { postRequest } from "../helpers/api"
import Modal from "./Modal"
import FlashAlert from "./FlashAlertWithPortal"

type Props = {
  open: boolean
  onClose(): void
}

type FormProps = {
  problem?: string
  comments?: string
}

const initialState: FormProps = {
  problem: "",
  comments: ""
}

const HelpModal: React.FC<Props> = ({ open, onClose }) => {
  const [form, setValues] = useState<FormProps>({ ...initialState })
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState<{ [s: string]: any }>({})
  const [success, setSuccess] = useState(false)

  return (
    <>
      {success &&
        <FlashAlert
          type="notice"
          message="Your support request has been sent."
          onClose={() => setSuccess(false)}
        />
      }
      <Modal title="Report a problem" onClose={onClose} open={open} showClose={true}>
        <form
          onSubmit={async e => {
            e.preventDefault()

            const checkErrors: FormProps = {}

            if (form.problem === "") checkErrors.problem = "can't be blank"
            if (form.comments === "") checkErrors.comments = "can't be blank"

            setErrors(checkErrors)

            if (Object.keys(checkErrors).length) return

            setIsSaving(true)

            const resp = await postRequest(`/api/help`, {
              ...form
            })

            setIsSaving(false)

            if (resp.status < 300) {
              setValues({ ...initialState })
              setSuccess(true)
              onClose()
            } else {
              checkErrors.problem = "unknown error occurred, please try again"
              setErrors(checkErrors)
            }
          }}
        >
          <div className="form-field">
            <label htmlFor="help_problem">What does the problem relate to?</label>
            <select id="help_problem" value={form.problem} onChange={e => setValues({ ...form, problem: e.target.value })}>
              <option value="">Please select</option>
              <option value="feature_request">Feature request</option>
              <option value="bug">Something is broken</option>
              <option value="other">Other</option>
            </select>
            {errors.problem && <span className="hint -error">{errors.problem}</span>}
          </div>

          <div className="form-field">
            <label htmlFor="help_comments">Describe the problem in detail...</label>
            <textarea id="help_comments" rows={3} value={form.comments} onChange={e => setValues({ ...form, comments: e.target.value })} placeholder="Comments..." />
            {errors.comments && <span className="hint -error">{errors.comments}</span>}
          </div>

          <div className="flex items-center">
            <button
              type="submit"
              className="button -primary mr4"
              disabled={isSaving}
            >
              { isSaving ? "Saving..." : "Submit" }
            </button>
            <button
              className="button"
              disabled={isSaving}
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default HelpModal
