import React, { useState } from "react"

type Props = {
  name?: string
  label: string
  defaultValue?: boolean
  onChange?(value: boolean): void
}

export default ({ name, label, defaultValue, onChange }: Props) => {
  const [checked, setChecked] = useState<boolean>(defaultValue || false)

  function onToggle() {
    const newChecked = !checked
    setChecked(newChecked)
    onChange && onChange(newChecked)
  }

  return (
    <div className="big-checkbox pointer select-none" onClick={onToggle}>
      <div className="box">
        {checked &&
          <i className="material-icons">check</i>
        }
      </div>
      <span className="label fw6">{label}</span>
      <input type="hidden" name={name} value={checked.toString()} />
    </div>
  )
}
