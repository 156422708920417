import React, { useState, useReducer } from "react"
import { parse as parseDate, format as formatDate } from "date-fns"
import humanize from "string-humanize"

import { dictionary, Valuation } from "../../../helpers/types"
import { formatCurrency } from "../../../helpers/string"
import ContentBox from "../../ContentBox"
// import ValuationList from "../ValuationList"
import EditForm from "./EditForm"
import DocumentsTable from "../../DocumentsTable"
import UploadModal from "../../UploadModal"
import ValidationStatus from "../ValidationStatus"
import ValidateButton from "../ValidateButton"

interface Props {
  asset: dictionary
  valuations: Array<Valuation>
  role?: string
}

const Pension = ({ asset, valuations, role }: Props) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [docsVersion, incrementDocsVersion] = useReducer(state => state + 1, 0)

  return (
    <>
      <div className="flex flex-column flex-row-ns items-start-ns">
        <div className="flex-auto">
          <span className={`db light-silver fw4 f5 mb2 ttu -${asset.category_label}`}>{asset.category_label}</span>
          <h1 className="page-title">{asset.title}</h1>
        </div>
        {role === "admin" &&
          <ValidateButton id={asset.id} validated={!!asset.validated_at} />
        }
        <button onClick={() => setShowEditForm(true)} className="button -primary -with-icon mv4 mv0-ns">
          <i className="material-icons">edit</i>
          Edit asset
        </button>
      </div>

      <EditForm assetId={asset.id} values={asset} open={showEditForm} onClose={() => setShowEditForm(false)} />

      <ContentBox
        title="Pension details"
        grid
        renderHeader={() => (
          <ValidationStatus
            type="asset"
            validated={asset.validated}
            validatedAt={asset.validated_at}
            invalidMessage="Upload pension policy to validate."
          />
        )}
      >
        <ul className="data-grid">
          <li>
            <div>
              <strong>Type of account</strong><br />
              {humanize(asset.asset_sub_type)}
            </div>
          </li>
          {asset.asset_sub_type === "employer" &&
            <>
              <li>
                <div>
                  <strong>Employer name</strong><br />
                  {asset.employer_name}
                </div>
              </li>
              <li>
                <div>
                  <strong>Plan name</strong><br />
                  {asset.pension_plan_type}
                </div>
              </li>
            </>
          }
          <li>
            <div>
              <strong>Provider name</strong><br />
              {asset.provider_name}
            </div>
          </li>
          <li>
            <div>
              <strong>Policy number</strong><br />
              {asset.policy_reference}
            </div>
          </li>
          <li>
            <div>
              <strong>Policy amount</strong><br />
              {formatCurrency(asset.current_value)}
            </div>
          </li>
        </ul>
      </ContentBox>

      {/* <ValuationList valuations={valuations} /> */}

      <ContentBox title="Documents" collapsable extraClasses="mt5">
        <div className="mt4">
          <DocumentsTable
            recordId={asset.id}
            recordType="Asset"
            recordGroup=""
            version={docsVersion}
            onUpdate={incrementDocsVersion}
            onAddNew={() => setShowUploadModal(true)}
          />
        </div>
      </ContentBox>

      <UploadModal
        title="Add document"
        open={showUploadModal}
        documentTypes={["policy_document"]}
        recordId={asset.id}
        recordType="Asset"
        onUploadComplete={incrementDocsVersion}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  )
}

export default Pension
