import { truncate } from "underscore.string"
import React, { useState } from "react"
import { connect } from "react-refetch"
import { parse as parseDate, format } from "date-fns"
import DocumentsMoreMenu from "./DocumentsMoreMenu"
import DocumentDetailsModal from "./DocumentDetailsModal"
import Pagination from "./Pagination"
import Loading from "./Loading"
import { truncateFilename } from "../helpers/string"

export const DocumentsTable = ({ docsFetch, fetchPage, hasComments, onUpdate, onAddNew }) => {
  const [viewingDoc, setViewingDoc] = useState(null)

  if (docsFetch.pending) {
    console.log("docsFetch.pending")
    return (
      <Loading label="Loading documents" wrapperClassName="mv6 justify-center" />
    )
  } else if (docsFetch.rejected) {
    return (<div>Error loading documents, try again.</div>)
  } else if (docsFetch.fulfilled) {
    const { data, meta: { total_pages, current_page } } = docsFetch.value
    return (
      <React.Fragment>
        {data.length < 1 &&
          <div className="mv6 light-silver f3">
            No documents uploaded yet.
          </div>
        }
        {data.length > 0 &&
          <div className="table-wrapper">
            <table className="static-table -fullwidth">
              <thead>
                <tr>
                  <th>Date added</th>
                  <th>Name</th>
                  <th>Date of document</th>
                  <th>Added by</th>
                  {hasComments && <th>Comments</th>}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map(doc => (
                  <tr key={doc.id}>
                    <td>{format(parseDate(doc.created_at), "Do MMM YY")}</td>
                    <td>
                      <span onClick={() => setViewingDoc(doc)} className="dim pointer near-black fw6">
                        {doc.title ? truncate(doc.title, 20) : truncateFilename(doc.filename, 15)}
                      </span>
                    </td>
                    <td>
                      {doc.document_date ?
                        format(parseDate(doc.document_date), "Do MMM YY")
                      :
                        <span className="light-gray">&ndash;</span>
                      }
                    </td>
                    <td>{doc.user.full_name}</td>
                    {hasComments && <td width={220}>{truncate(doc.comments, 60)}</td>}
                    <td>
                      <DocumentsMoreMenu doc={doc} onUpdate={onUpdate} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }

        <div className="flex flex-column flex-row-ns items-center mt4">
          <button className="button -with-icon order-1 order-0-ns mt4 mt0-ns" onClick={onAddNew}>
            <span className="icon -upload"></span>
            Add Document
          </button>

          <span className="flex-auto"></span>

          <Pagination
            totalPages={total_pages}
            currentPage={current_page}
            onChange={fetchPage}
            className="order-0 order-last-ns"
          />
        </div>

        <DocumentDetailsModal document={viewingDoc} open={!!viewingDoc} onClose={() => setViewingDoc(null)} />
      </React.Fragment>
    )
  }
}

const perPage = 5

export default connect(({ recordId, recordType, recordGroup, version }) => ({
  docsFetch: {
    url: `/api/documents?record_id=${recordId}&record_type=${recordType}&group=${recordGroup}&per_page=${perPage}`,
    comparison: `${recordId}:${version}`
  },
  fetchPage: page => ({
    docsFetch: {
      url: `/api/documents?record_id=${recordId}&record_type=${recordType}&group=${recordGroup}&page=${page}&per_page=${perPage}`,
      comparison: `${recordId}:${version}:${page}`,
      refreshing: true
    }
  })
}))(DocumentsTable)
