import React, { useState } from "react"

import RadioInput from "../inputs/RadioInput"
import NewStoreCard from "../NewAsset/forms/storeCard"

interface Props {
  onNext(): void
}

const StoreCard = ({ onNext }: Props) => {
  const [hasItem, setHasItem] = useState("")

  return (
    <>
      <span className="db light-silver fw4 f5 mb2">Add Debts</span>
      <h1 className="page-title">Do you have a store card?</h1>
      <RadioInput
        options={[
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ]}
        value={hasItem}
        onChange={val => setHasItem(val)}
      />
      {hasItem === "yes" &&
        <div className="mt5">
          <NewStoreCard hideTitle onBack={() => setHasItem("no")} onSuccess={onNext} />
        </div>
      }
      {hasItem === "no" &&
        <button onClick={onNext} className="mt5 button -primary">Continue</button>
      }
    </>
  )
}

export default StoreCard
