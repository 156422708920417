import React from "react"
import Modal from "../Modal"
import ContactForm from "./ContactForm"

const AddTrustedFriend = ({ open, onSave, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} showClose={true} title="Add Trusted Friend">
      {open &&
        <ContactForm
          nameChangable={true}
          showInviteText={true}
          onSave={onSave}
          onClose={onClose}
        />
      }
    </Modal>
  )
}

export default AddTrustedFriend
