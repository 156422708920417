import React, { useState, useReducer } from 'react'
import humanize from 'string-humanize'

import { dictionary } from '../../../helpers/types'

import EditForm from './EditForm'
import ContentBox from '../../ContentBox'
import DocumentsTable from '../../DocumentsTable'
import UploadModal from '../../UploadModal'
import ValidationStatus from '../ValidationStatus'
import ValidateButton from '../ValidateButton'

interface Props {
  asset: dictionary
  role?: string
}

const BankAccount = ({ asset, role }: Props) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [docsVersion, incrementDocsVersion] = useReducer(state => state + 1, 0)

  return (
    <>
      <div className="flex flex-column flex-row-ns items-start-ns">
        <div className="flex-auto">
          <span className={`db light-silver fw4 f5 mb2 ttu -${asset.category_label}`}>{humanize(asset.category_label)}</span>
          <h1 className="page-title">{asset.title}</h1>
        </div>

        {role === 'admin' && <ValidateButton id={asset.id} validated={!!asset.validated_at}/>}

        <button onClick={() => setShowEditForm(true)} className="button -primary -with-icon mv4 mv0-ns">
          <i className="material-icons">edit</i>
          Edit asset
        </button>
      </div>

      <EditForm
        assetId={asset.id}
        values={asset}
        open={showEditForm}
        onClose={() => setShowEditForm(false)}
      />

      <ContentBox
        title="Account details"
        grid
        renderHeader={() => (
          <ValidationStatus
            type="asset"
            validated={asset.validated}
            validatedAt={asset.validated_at}
            invalidMessage="Upload a statement to validate."
          />
        )}
      >
        <ul className="data-grid">
          <li>
            <div>
              <strong>Bank name</strong>
              <br />
              {asset.provider_name}
            </div>

            <div>
              <strong>Account type</strong>
              <br />
              {humanize(asset.asset_sub_type)}
            </div>
          </li>

          <li>
            <div>
              <strong>Bank address</strong>
              <br />
              {asset.address_line_1 ?
                [asset.address_line_1, asset.address_line_2, asset.address_town]
                  .filter(a => !!a)
                  .join(', ')
                :
                <span className="light-gray">&ndash;</span>
              }
            </div>

            <div>
              <strong>Account number</strong>
              <br />
              {asset.account_number ? asset.account_number :
                <span className="light-gray">&ndash;</span>
              }
            </div>
          </li>

          <li>
            <div>
              <strong>Sort code</strong>
              <br />
              {asset.sort_code ? asset.sort_code :
                <span className="light-gray">&ndash;</span>
              }
            </div>

            <div>
              <strong>SWIFT/BIC</strong>
              <br />
              {asset.swift_bic ? asset.swift_bic :
                <span className="light-gray">&ndash;</span>
              }
            </div>
          </li>

          {asset.asset_sub_type === 'business' &&
            <li>
              <div>
                <strong>Company name</strong>
                <br />
                {asset.company_name}
              </div>
            </li>
          }
        </ul>
      </ContentBox>

      <ContentBox
        title="Joint holder details"
        grid={asset.joint_account === true}
        extraClasses="mt5"
      >
        {asset.joint_account === false ?
          <div className="mt4">
            <div className="mt6 mb4 light-silver f3">
              This account doesn't have a joint holder.
            </div>
          </div>
          :
          <>
            <ul className="data-grid">
              <li>
                <div>
                  <strong>Full name</strong>
                  <br />
                  {asset.holder_full_name ? asset.holder_full_name :
                    <span className="light-gray">&ndash;</span>
                  }
                </div>

                <div>
                  <strong>Address</strong>
                  <br />
                  {asset.holder_address_line_1 ?
                    [asset.holder_address_line_1, asset.holder_address_line_2, asset.holder_address_town]
                      .filter(a => !!a)
                      .join(', ')
                    :
                    <span className="light-gray">&ndash;</span>
                  }
                </div>
              </li>

              <li>
                <div>
                  <strong>Email</strong>
                  <br />
                  {asset.holder_email ? asset.holder_email :
                    <span className="light-gray">&ndash;</span>
                  }
                </div>

                <div>
                  <strong>Contact number</strong>
                  <br />
                  {asset.holder_contact_number ? asset.holder_contact_number :
                    <span className="light-gray">&ndash;</span>
                  }
                </div>
              </li>
            </ul>
          </>
        }
      </ContentBox>

      <ContentBox
        title="Documents"
        collapsable
        extraClasses="mt5"
      >
        <div className="mt4">
          <DocumentsTable
            recordId={asset.id}
            recordType="Asset"
            recordGroup=""
            version={docsVersion}
            onUpdate={incrementDocsVersion}
            onAddNew={() => setShowUploadModal(true)}
          />
        </div>
      </ContentBox>

      <UploadModal
        title="Add document"
        open={showUploadModal}
        documentTypes={['statement']}
        recordId={asset.id}
        recordType="Asset"
        onUploadComplete={incrementDocsVersion}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  )
}

export default BankAccount
