import React from "react"
import { addYears, subDays, format, startOfYear } from "date-fns"
import { TimelineItem, TimelineEntry } from "./types"
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Dot, AreaChart, Area
} from "recharts"

import Headers from "./Headers"
import ClearContainer from "../ClearContainer"
import { getRequest } from "../../helpers/api"
import { formatCurrency } from "../../helpers/string"
import { colorHexes } from "../../helpers/colors"
import IconWithTooltip from "../IconWithTooltip"

const lineColor = colorHexes()

type TimelineData = {
  timeline_items: TimelineItem[]
  timeline_items_flat: TimelineItem[]
  timeline: TimelineEntry[]
}

type State = {
  view: string|number
  data: TimelineData
  disabledItems: (string|number)[]
  activeLine: string|number
  activeAsset: string|number
  selectedAsset: string|number
  tooltipEstimation: boolean
  displayYear: string
  displayYears: number
}

const VALIDATED_LINE_WIDTH = "3"
const UNVALIDATED_LINE_WIDTH = "2"
const UNVALIDATED_LINE_DASHARRAY = "20 5"

const CustomizedDot = (props) => {
  if (props.hidden) return null

  const { cx, cy, r, strokeWidth, fill, stroke, points, index } = props

  const previousPoint = points[index - 1]
  if (!(previousPoint && previousPoint.y !== cy)) return null

  return (
    <circle cx={cx} cy={cy} r={r} stroke={stroke} strokeWidth={strokeWidth} fill={fill}/>
  )
}

export default class Timeline extends React.Component<{}, State> {
  constructor(props) {
    super(props)
    this.state = {
      view: 'default',
      data: null,
      disabledItems: [],
      activeLine: null,
      activeAsset: null,
      selectedAsset: null,
      tooltipEstimation: false,
      displayYear: format(new Date(), 'YYYY'),
      displayYears: 1
    }
  }

  async componentDidMount() {
    this.fetchTimeline()
  }

  fetchTimeline = async () => {
    const { view, displayYear, displayYears } = this.state
    const startDate = startOfYear(new Date(displayYear))
    const endDate = addYears(startDate, displayYears)
    const params = {
      type: view,
      start_date: format(startDate, 'YYYY-MM-DD'),
      end_date: format(endDate, 'YYYY-MM-DD')
    }
    const resp = await getRequest("/api/timeline", params)
    const json = await resp.json()
    this.setState({ data: json.data })
  }

  yearsArray() {
    const { displayYears } = this.state

    const fromDate = subDays(new Date(), 7)
    let startDate = fromDate
    const endDate = addYears(fromDate, displayYears)
    let values = []

    while (endDate > startDate || format(startDate, 'YYYY') === format(endDate, 'YYYY')) {
      values.push(format(startDate, 'YYYY'));
      startDate = addYears(startDate, 1);
    }
    return values;
  }

  changeView = (field: string|number, r: any) => {
    this.setState({ view: field }, () => {
      this.fetchTimeline()
    })
  }

  changeYear = (year) => {
    this.setState({ displayYear: year }, () => {
      this.fetchTimeline()
    })
  }

  changeYears = (years) => {
    this.setState({ displayYears: years }, () => {
      this.fetchTimeline()
    })
  }

  toggleItemShow = (id: string|number, enabled: boolean) => {
    const { disabledItems } = this.state
    if (enabled) {
      this.setState({ disabledItems: disabledItems.filter(enabledId => enabledId !== id) })
    } else {
      this.setState({ disabledItems: [...disabledItems, id] })
    }
  }

  setActiveAsset(assetId) {
    this.setState({ activeAsset: assetId })
  }

  selectAsset(assetId) {
    this.setState({ selectedAsset: assetId })
  }

  setActiveLine(lineKey) {
    this.setState({ activeLine: lineKey })
  }

  setTooltipEstimation(value) {
    this.setState({ tooltipEstimation: value })
  }

  enabledItems() {
    const { disabledItems, data: { timeline_items_flat } } = this.state

    return timeline_items_flat.filter(({ id }) => !disabledItems.includes(id))
  }

  tooltipRenderer = (event) => {
    const { activeAsset, tooltipEstimation, data } = this.state
    const { timeline_items_flat } = data

    const assetItem = timeline_items_flat.find(({id}) => id === activeAsset)

    if (assetItem && event.active && event.payload != null && event.payload[0] != null) {
      const data = event.payload[0].payload
      const color = lineColor[activeAsset] || lineColor[assetItem.type]

      let segment
      if (tooltipEstimation) {
        segment = assetItem.estimations.find(({timeline}) => timeline[data.date])
      } else {
        segment = assetItem.valuations.find(({timeline}) => timeline[data.date])
      }

      if (!segment) return null

      const value = segment.timeline[data.date]

      return (
        <div className="content-box pa3 lh-copy f6">
          <strong className="ttu">{data.date}</strong><br/>
          <div className="flex items-center">
            <div style={{backgroundColor: color, width: 10, height: 10}}/>
            <span className="ml3">
              {assetItem.title}: {formatCurrency(value)}
            </span>
          </div>
        </div>
      )
    }
  }

  getFillOpacity(isSelected: boolean, isActive: boolean): number {
    if (isSelected) {
      return 0.3
    } else if (isActive) {
      return 0.1
    } else {
      return 0
    }
  }

  renderAssetLine(asset, segment, segmentNumber, estimation = false) {
    const { activeLine, selectedAsset } = this.state
    const { id, type } = asset
    const lineKey = estimation ? `${id}-estimation-${segmentNumber}` : `${id}-${segmentNumber}`

    const isActive = activeLine == null || activeLine === id
    const isActiveAsset = activeLine === id
    const isSelectedAsset = selectedAsset === id
    const isValidated = !estimation && segment.validated
    const strokeWidth = isValidated ? VALIDATED_LINE_WIDTH : UNVALIDATED_LINE_WIDTH
    const strokeDasharray = isValidated ? null : UNVALIDATED_LINE_DASHARRAY
    const color = estimation ? lineColor['estimation'] : lineColor[id] || lineColor[type]

    return (
      <Line
        key={lineKey}
        type="monotone"
        onClick={() => {
          this.selectAsset(id);
        }}
        dataKey={month => {
          if (!segment.timeline) return null
          return segment.timeline[month.date]
        }}
        stroke={color}
        opacity={isActive || selectedAsset ? 1 : 0.3}
        fill={color}
        dot={(props) => <CustomizedDot {...props} hidden={estimation} />}
        fillOpacity={this.getFillOpacity(isSelectedAsset, isActiveAsset)}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        onMouseOver={() => {
          this.setActiveLine(id);
          this.setActiveAsset(id);
        }}
        onMouseOut={() => {
          this.setActiveLine(null);
          this.setActiveAsset(null);
        }}
        activeDot={dotProps => (
          <Dot {...dotProps}
            r={8}
            onClick={() => {
              this.selectAsset(id);
            }}
            opacity={isActive || selectedAsset ? 1 : 0}
            onMouseOver={() => {
              this.setActiveLine(id);
              this.setActiveAsset(id);
              this.setTooltipEstimation(estimation);
            }}
            onMouseOut={() => {
              this.setActiveLine(null);
              this.setActiveAsset(null);
              this.setTooltipEstimation(null);
            }}
          />
        )}
      />
    )
  }

  renderEstimationLines() {
    if (this.state.view === 'property') {
      return this.enabledItems().map(item => {
        return item.estimations.map((segment, index) => {
          return this.renderAssetLine(item, segment, index, true)
        })
      })
    }
  }

  renderAssetLines() {
    return this.enabledItems().map(item => {
      return item.valuations.map((segment, index) => {
        return this.renderAssetLine(item, segment, index)
      })
    })
  }

  renderValidateTooltip() {
    const tooltipMessage = 'Validated means you need to providing supporting documents to demonstrate that information displayed is accurate. To validate a mortgage requires a copy of your mortgage deed. To validate the valuation of your property requires a survey report.'

    return (
      <div className="validate-tooltip">
        <span className="validate-tooltip__element">Key:</span>
        <span className="validate-tooltip__element -solid">Validated</span>
        <span className="validate-tooltip__element -dashed">Unvalidated</span>
        <span className="validate-tooltip__element -icon pointer">
          <IconWithTooltip
            icon="help_outline"
            tooltipText={tooltipMessage}
            placement="bottom"
          />
        </span>
      </div>
    )
  }

  render() {
    if (!this.state.data) return <span>Loading...</span>

    const { timeline, timeline_items } = this.state.data
    const { displayYears, displayYear } = this.state

    const groupedView = timeline_items.length === 1 && timeline_items[0].grouped
    const parentItem = groupedView ? timeline_items[0] : null
    const itemsForHeaders = parentItem ? parentItem.items : timeline_items
    const itemsToShow = this.enabledItems().map(({ id }) => id)

    return (
      <ClearContainer>
        <div className="mb4 ml4">
          {groupedView &&
          <div className="flex items-start mb3">
              <span className="timeline-category-title">
                <span className="tl pointer dim mr2" onClick={() => this.changeView("default", null)}>⟵</span>
                {parentItem.title}
              </span>
          </div>
          }
        </div>
        <Headers
          timelineItems={itemsForHeaders}
          itemsToShow={itemsToShow}
          onItemToggle={this.toggleItemShow}
          changeView={this.changeView}
        />
        {this.renderValidateTooltip()}
        <ResponsiveContainer height={400} debounce={100}>
          <LineChart
            data={timeline}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <XAxis dataKey="date_label" stroke="#8d9396" interval="preserveStartEnd"/>
            <YAxis type="number" hide domain={['dataMin - 1000', 'dataMax + 1000']}/>
            <Tooltip
              cursor={false}
              content={this.tooltipRenderer}
            />

            {this.renderEstimationLines()}
            {this.renderAssetLines()}

          </LineChart>
        </ResponsiveContainer>
        <div className="mw9 center ph3-ns">
          <div className="cf ph2-ns">
            <div className="fl w-100 w-50-ns pa2 flex justify-start">
              {this.yearsArray().map(year => {
                return <span
                  key={year}
                  className={`ph2 dateRangeFilter ${displayYear === year ? 'active' : ''}`}
                  onClick={() => this.changeYear(year)}>{year}</span>
              })}
            </div>
            <div className="fl w-100 w-50-ns pa2 flex justify-around">
              <span
                className={`dateRangeFilter ${displayYears === 1 ? 'active' : ''}`}
                onClick={() => {
                  this.changeYears(1)
                }}>1 Year</span>
              <span
                className={`dateRangeFilter ${displayYears === 3 ? 'active' : ''}`}
                onClick={() => {
                  this.changeYears(3)
                }}>3 Years</span>
              <span
                className={`dateRangeFilter ${displayYears === 5 ? 'active' : ''}`}
                onClick={() => {
                  this.changeYears(5)
                }}>5 Years</span>
            </div>
          </div>
        </div>
        <div className="flex items-stretch">

        </div>
      </ClearContainer>
    )
  }
}
