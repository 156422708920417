import React, { useState } from "react"
import humanize from "string-humanize"
import Modal from "./Modal"
import FileUpload from "./inputs/FileUpload"
import CheckboxInput from "./inputs/CheckboxInput"
import AutoCompleteInput from "./inputs/AutoCompleteInput"
import { putRequest, deleteRequest } from "../helpers/api"

const initialUploadForm = {
  title: "",
  comments: "",
  document_type: ""
}

export default ({ title, open, recordId, recordType, group, documentTypes, showImportant, hasTitle, hasComments, onUploadComplete, onClose, defaultValues, hasDatepicker, titleFieldValues, titleFieldLabel, titleFieldPlaceholder }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [uploaded, setUploaded] = useState(null)
  const [form, setValues] = useState({ ...initialUploadForm, ...defaultValues })
  const [errors, setErrors] = useState({})

  if (showImportant && !form.document_type) setValues({ ...form, document_type: "normal" })

  return (
    <Modal title={title || "Upload document"} open={open} onClose={onClose}>
      {showImportant ?
        <div className="form-field">
          <CheckboxInput label="This document is important" onChange={checked => setValues({ ...form, document_type: checked ? "important" : "normal" })} />
        </div>
      :
        <div className="form-field">
          <label htmlFor="upload_document_type">Document type</label>
          <select id="upload_document_type" value={form.document_type} onChange={e => setValues({ ...form, document_type: e.target.value })}>
            <option value="">Select document type</option>
            {documentTypes.map(label => <option key={label} value={label}>{humanize(label)}</option>)}
          </select>
          {errors.document_type && <span className="hint -error">{errors.document_type}</span>}
        </div>
      }

      {hasTitle &&
        <div className="form-field">
          <label htmlFor="upload_title">{titleFieldLabel || "Title"}</label>
          {(titleFieldValues && titleFieldValues.length > 0) ?
            <AutoCompleteInput
              placeholder={titleFieldPlaceholder || ""}
              value={form.title}
              onChange={title => setValues({ ...form, title })}
              getData={setData => {
                setData(titleFieldValues)
              }}
            />
          :
            <input type="text" id="upload_title" placeholder={titleFieldPlaceholder || ""} value={form.title} onChange={e => setValues({ ...form, title: e.target.value })} />
          }
          {errors.title && <span className="hint -error">{errors.title}</span>}
        </div>
      }

      <FileUpload
        hasDatepicker={hasDatepicker}
        label="Upload document"
        single={true}
        recordId={recordId}
        recordType={recordType}
        group={group}
        documentTypeSelectionRequired={false}
        defaultDocumentType="unknown"
        skipLoadingExistingDocs={true}
        onUploadComplete={upload => setUploaded(upload)}
      />
      {errors.upload &&
        <div className="form-field"><span className="hint -error">{errors.upload}</span></div>
      }

      {hasComments &&
        <div className="form-field">
          <label htmlFor="upload_comments">Comments</label>
          <textarea id="upload_comments"
                    rows={3}
                    value={form.comments}
                    onChange={e => setValues({ ...form, comments: e.target.value })}
                    placeholder="Comments..." />
          {errors.comments && <span className="hint -error">{errors.comments}</span>}
        </div>
      }

      <div className="flex items-center">
        <button
          className="button -primary mr4"
          disabled={isSaving}
          onClick={async () => {
            const checkErrors = {}

            if (!uploaded) checkErrors.upload = "please select a file"
            if (form.document_type === "") checkErrors.document_type = "can't be blank"

            if (hasTitle) {
              if (form.title === "") {
                checkErrors.title = "can't be blank"
              } else if (titleFieldValues && !titleFieldValues.includes(form.title)) {
                checkErrors.title = "must be one of the given values"
              }
            }

            setErrors(checkErrors)

            if (Object.keys(checkErrors).length) return

            setIsSaving(true)
            const resp = await putRequest(`/api/documents/${uploaded.id}`, {
              ...form
            })
            const json = await resp.json()
            setIsSaving(false)
            setValues({ ...initialUploadForm })
            onUploadComplete(json)
            onClose()
          }}
        >
          { isSaving ? "Saving..." : "Save document" }
        </button>
        <button
          className="button"
          disabled={isSaving}
          onClick={async () => {
            if (uploaded) {
              if (confirm("Are you sure?")) {
                await deleteRequest(`/api/documents/${uploaded.id}`)
                onClose()
              }
            } else {
              onClose()
            }
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}
