import React, { useState, useReducer } from "react"

import { DOCUMENT_TYPES } from '../../constants/documents.ts'
import StatusList from "./StatusList"
import PrimaryCTA from "../PrimaryCTA"
import AddStatusUpdate from "./AddStatusUpdate"
import UploadModal from "../UploadModal"
import DocumentsTable from "../DocumentsTable"
import ImportantDocuments from "./ImportantDocuments"
import ContentBox from "../ContentBox"

export default ({ clientId, currentUserId }) => {
  const [editing, setEditing] = useState(null)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [statusVersion, incrementStatusVersion] = useReducer(state => state + 1, 0)
  const [docsVersion, incrementDocsVersion] = useReducer(state => state + 1, 0)

  return (
    <div>
      <PrimaryCTA id="updates-primary-cta">
        <button className="button -primary" onClick={() => setShowAddModal(true)}>
          Update Status
        </button>
      </PrimaryCTA>

      <h1 className="page-title">Document history</h1>

      <ContentBox extraClasses="mt5">
        <DocumentsTable
          hasComments
          recordId={clientId}
          recordType="Client"
          recordGroup="probate"
          version={docsVersion}
          onUpdate={incrementDocsVersion}
          onAddNew={() => setShowUploadModal(true)}
        />
      </ContentBox>

      <div className="flex flex-column flex-row-ns mt6">
        <div className="w-30-ns mb6 mb0-ns">
          <ImportantDocuments clientId={clientId} version={docsVersion} onUpdate={incrementDocsVersion} />
        </div>
        <div className="w-70-ns">
          <StatusList clientId={clientId} currentUserId={currentUserId} onEdit={setEditing} version={statusVersion} />
        </div>
      </div>

      {(showAddModal || !!editing) && <AddStatusUpdate
        clientId={clientId}
        existing={editing}
        open={true}
        version={docsVersion}
        onSave={incrementStatusVersion}
        onClose={() => { setShowAddModal(false); setEditing(null); }}
      /> }

      <UploadModal
        showImportant
        hasTitle
        hasComments
        hasDatepicker
        title="Add document"
        open={showUploadModal}
        documentTypes={["probate"]}
        recordId={clientId}
        recordType="Client"
        group="probate"
        onUploadComplete={incrementDocsVersion}
        onClose={() => setShowUploadModal(false)}
        titleFieldLabel="Document type"
        titleFieldPlaceholder="Grant of Probate"
        titleFieldValues={DOCUMENT_TYPES}
      />
    </div>
  )
}
