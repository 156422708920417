import React from "react"
import ReactDOM from "react-dom"
import FlashAlert from "./FlashAlert"

type Props = {
  type: string
  message: string
  onClose(): void
}

const FlashAlertWithPortal: React.FC<Props> = ({ type, message, onClose }) => {
  // "#flash-portal"
  return ReactDOM.createPortal((
    <FlashAlert type={type} message={message} onClose={onClose} />
  ), document.getElementById("flash-portal"))
}

export default FlashAlertWithPortal
