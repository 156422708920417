import React from "react"

import Card from "./Card"

const SuggestedFriends = ({ friends }) => {
  return (
    <div className="flex">
      {friends.map(friend => <Card key={friend.id} friend={friend} />)}
    </div>
  )
}

export default SuggestedFriends
