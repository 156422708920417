import React from "react"
import filesize from "filesize"
import { truncateFilename } from "../../helpers/string"
import humanize from "string-humanize"
import ImagePreview from "../ImagePreview";

const DisplayDocuments = ({ documents, solicitor, onConfirmation, onProblem }) => {
  console.log(documents)
  return (
    <>
      <h1 className="page-title">Confirm your will</h1>
      <p className="lh-copy">
        Your will has been uploaded to Asset Nexus by {solicitor.name}.
        Please review and confirm it is yours.
      </p>

      <div className="mt5">
        {documents.map(document => (
          <div key={document.id} className="mb4">
            <div className="flex items-center pa3 bg-white b--silver ba" style={{ borderWidth: 2, borderRadius: 3 }}>
              <div style={{ flex: 1 }}>
                {/*<span className="near-black db mb1 f7 fw7 ttu">{humanize(document.document_type)}</span>*/}
                {/*<a href={document.file_url}>{truncateFilename(document.filename, 40)}</a>*/}
                {/*<br />*/}
                {/*<span className="gray f7">{filesize(document.size_in_bytes)}</span>*/}
                {document.file_preview_image_url &&
                  <ImagePreview
                    image_url={document.file_preview_image_url}
                    image_ratio={document.file_preview_image_ratio}
                  />
                }
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-start mt5">
        <button onClick={onConfirmation} className="button -primary mr4">Yes, this is my will</button>
        <button onClick={onProblem} className="button">No, there is a problem</button>
      </div>
    </>
  )
}

export default DisplayDocuments
