import { find, each, reduce } from "lodash"
import React, { useState } from "react"
import Turbolinks from "turbolinks"
import { format as formatDate } from "date-fns"

import { dictionary } from "../../../helpers/types"
import { putRequest } from "../../../helpers/api"
import Modal from "../../Modal"
import MoneyInput from "../../inputs/MoneyInput"
import DateInput from "../../inputs/DateInput"
import RadioInput from "../../inputs/RadioInput"

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
}

function getInitialValues(values: dictionary): dictionary {
  const keys = ["provider_name", "current_value", "policy_reference", "interest_rate", "promotional_offer", "offer_expires", "offer_expires_reminder"]

  return reduce(keys, (memo, key) => {
    memo[key] = values[key]
    return memo
  }, {})
}

const EditForm = ({ assetId, open, values, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values) })

  return (
    <Modal title="Edit store card" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          console.log("submit form", form)

          each(form, (value, field) => {
            if (["policy_reference","interest_rate", "promotional_offer", "offer_expires", "offer_expires_reminder"].indexOf(field) > -1 ) return
            if (value == null || value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            console.log("errors with form", errors)
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="form-field -maxlength">
          <label htmlFor="provider_name">Provider name</label>
          <input type="text" id="provider_name" placeholder="e.g. Capital One" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
          {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Credit amount</label>
          <MoneyInput id="current_value" defaultValue={form.current_value} onChange={current_value => setValues({ ...form, current_value })} />
          {find(errors, row => row.field === "current_value") && <span className="hint -error">{find(errors, row => row.field === "current_value").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="policy_reference">Account number</label>
          <input type="text" id="policy_reference" value={form.policy_reference || ""} onChange={e => setValues({ ...form, policy_reference: e.target.value })} />
          {find(errors, row => row.field === "policy_reference") && <span className="hint -error">{find(errors, row => row.field === "policy_reference").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="interest_rate">Interest rate</label>
          <input type="text" id="interest_rate" value={form.interest_rate} onChange={e => setValues({ ...form, interest_rate: e.target.value })} />
          {find(errors, row => row.field === "interest_rate") && <span className="hint -error">{find(errors, row => row.field === "interest_rate").errors.join(", ")}</span>}
        </div>

        {parseInt(form.interest_rate) === 0 &&
          <div className="form-field -maxlength">
            <label>Is this a promotional offer?</label>
            <RadioInput
              value={form.promotional_offer}
              onChange={promotional_offer => setValues({ ...form, promotional_offer })}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
            />
            {find(errors, row => row.field === "promotional_offer") && <span className="hint -error">{find(errors, row => row.field === "promotional_offer").errors.join(", ")}</span>}
          </div>
        }

        {(parseInt(form.interest_rate) === 0 && form.promotional_offer) &&
          <>
            <div className="form-field -maxlength">
              <label htmlFor="offer_expires">Promo offer expires</label>
              <DateInput value={form.offer_expires || ""} onDayChange={date => setValues({ ...form, offer_expires: formatDate(date, "YYYY-MM-DD") })} />
              {find(errors, row => row.field === "offer_expires") && <span className="hint -error">{find(errors, row => row.field === "offer_expires").errors.join(", ")}</span>}
            </div>

            <div className="form-field -maxlength">
              <label>Would you like a reminder 1 month before offer expires?</label>
              <RadioInput
                value={form.offer_expires_reminder}
                onChange={offer_expires_reminder => setValues({ ...form, offer_expires_reminder })}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />
              {find(errors, row => row.field === "offer_expires_reminder") && <span className="hint -error">{find(errors, row => row.field === "offer_expires_reminder").errors.join(", ")}</span>}
            </div>
          </>
        }

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
