import React from "react"

type Props = {
  onClick(): void
  marginClass?: string
}

const BackArrow = ({ onClick, marginClass }: Props) => {
  if (!marginClass) marginClass = "mb5"
  return (
    <span className={`blue db tl pointer dim ${marginClass}`} onClick={onClick}>⟵ Back</span>
  )
}

export default BackArrow
