import React, { useState } from "react"
import Modal from "./Modal"
import FileUpload from "./inputs/FileUpload"

const CodicilUpload = ({ clientId }) => {
  const [open, setOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [uploaded, setUploaded] = useState(null)

  function onClose() {
    setOpen(false)
    setUploaded(null)
  }

  return (
    <React.Fragment>
      <button className={"button -fullwidth"} onClick={() => setOpen(true)}>Upload Codicil</button>

      <Modal title="Upload Codicil" open={open} onClose={onClose}>
        <FileUpload
          label="Upload document"
          single={true}
          recordId={clientId}
          recordType={"Client"}
          group="will"
          documentTypeSelectionRequired={false}
          defaultDocumentType="codicil"
          skipLoadingExistingDocs={true}
          onUploadStart={upload => setIsSaving(true)}
          onUploadComplete={upload => {
            setUploaded(upload)
            setIsSaving(false)
          }}
          onDocumentRemoved={() => setUploaded(null)}
        />

        {uploaded &&
          <div className="flash-alert -notice lh-copy">
            <strong>Thanks, your Codicil has been uploaded</strong><br />
            Your Solicitor has been notified and will review and make appropriate changes to your legacy.
          </div>
        }

        {uploaded ?
          <button
            className="button -primary"
            onClick={onClose}
          >
            Done
          </button>
        :
          <button
            className="button"
            disabled={isSaving}
            onClick={onClose}
          >
            {isSaving ? "Uploading...": "Cancel"}
          </button>
        }
      </Modal>
    </React.Fragment>
  )
}

export default CodicilUpload
