import React, { useState } from "react"
import Turbolinks from "turbolinks"

import CreditCard from "./CreditCard"
import StoreCard from "./StoreCard"
import BankLoan from "./BankLoan"
import Mortgage from "./Mortgage"

const steps = [
  "credit_card",
  "store_card",
  "bank_loan",
  "mortgage",
  "finished"
]

const DebtOnboarding = ({ assetTypesAdded }) => {
  let userSteps = steps.filter(step => {
    switch (step) {
      case "credit_card":
        return assetTypesAdded.indexOf("Debts::CreditCard") < 0
      case "store_card":
        return assetTypesAdded.indexOf("Debts::StoreCard") < 0
      case "bank_loan":
        return assetTypesAdded.indexOf("Debts::BankLoan") < 0
      case "mortgage":
        return assetTypesAdded.indexOf("Debts::Mortgage") < 0
      default:
        return true
    }
  })

  if (userSteps.length === 1) {
    Turbolinks.visit("/client/assets/onboarding")
    return null
  }

  const [step, setStep] = useState(userSteps[0])

  function nextStep(current) {
    const index = userSteps.indexOf(current)
    const next = userSteps[index + 1]
    setStep(next)
  }

  return (
    <div>
      {step === "credit_card" &&
        <CreditCard onNext={() => nextStep("credit_card")} />
      }
      {step === "store_card" &&
        <StoreCard onNext={() => nextStep("store_card")} />
      }
      {step === "bank_loan" &&
        <BankLoan onNext={() => nextStep("bank_loan")} />
      }
      {step === "mortgage" &&
        <Mortgage onNext={() => nextStep("mortgage")} />
      }
      {step === "finished" &&
        <>
          <span className="db light-silver fw4 f5 mb2">Add Debts</span>
          <h1 className="page-title">Thanks for adding your Debts</h1>
          <p>You're one step closer to tracking your financial legacy.</p>
          <button onClick={() => Turbolinks.visit("/client/assets/onboarding")} className="button -primary mt5">Continue to add assets</button>
        </>
      }
    </div>
  )
}

export default DebtOnboarding
