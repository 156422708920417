import React, { ReactNode } from "react"
import ReactDOM from "react-dom"
import styled from "@emotion/styled"

const ModalBG = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background: rgba(255,255,255,0.7);
`

const ModalBox = styled.div`
  width: 600px;
  max-width: 95vw;
  box-shadow: 0 2px 10px 0 rgba(20,23,26,0.10);
  border-radius: 3px;
`

const Header = styled.h1`
  margin: 0;
  padding: 26px 30px;
  font-size: 24px;
  font-weight: 600;
  background: white;
`

const Content = styled.section`
  padding: 30px;
  max-height: 70vh;
  overflow-y: auto;
`

interface Props {
  title: string
  open: boolean
  onClose(): void
  showClose?: boolean
  children?: ReactNode
}

export default ({ title, open, children, onClose, showClose }: Props) => {
  if (!open) return null

  return ReactDOM.createPortal(
    (
      <ModalBG>
        <ModalBox className="bg-near-white ba bw2 b--light-gray">
          <Header className="bb b--silver flex items-center">
            <span style={{ flex:1 }}>{title}</span>
            {showClose &&
              <span onClick={onClose} className="db f2 pa3 pointer light-silver fw3">&times;</span>
            }
          </Header>
          <Content>
            {children}
          </Content>
        </ModalBox>
      </ModalBG>
    ),
    document.getElementById("modal-container")
  )
}
