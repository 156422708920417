import _ from "lodash"
import React, { useState } from "react"

type NullableNumber = number | null

type Props = {
  value: NullableNumber
  onChange(value: NullableNumber): void
  min?: number
  max?: number
  length?: number
}

function onlyNumber(input: string): NullableNumber {
  input = input.replace(/[^0-9\.]/g, "")
  const float = parseFloat(input)
  if (isNaN(float)) return null
  return float
}

const NumberInput: React.FC<Props> = ({ value, min, max, length, onChange }) => {
  function changeValue(newValue: number) {
    if (!newValue) newValue = 0

    if (length !== undefined) {
      newValue = parseFloat(String(newValue).substr(0, length))
    }

    if (min !== undefined) newValue = _.max([newValue, min])
    if (max !== undefined) newValue = _.min([newValue, max])
    onChange(newValue)
  }

  return (
    <div className="number-input">
      <input type="text" value={value === null ? "" : value} onChange={e => changeValue(onlyNumber(e.target.value))} />
      <div className="buttons">
        <span onClick={() => changeValue(value + 1)}>
          <i className="material-icons">keyboard_arrow_up</i>
        </span>
        <span onClick={() => changeValue(value - 1)}>
          <i className="material-icons">keyboard_arrow_down</i>
        </span>
      </div>
    </div>
  )
}

export default NumberInput
