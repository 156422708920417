import React from "react"
import Modal from "../Modal"
import ContactForm from "./ContactForm"

const EditContact = ({ contact, open, nameChangable, onSave, onClose }) => {
  return (
    <Modal open={open} showClose={true} onClose={onClose} title="Edit contact">
      {open &&
        <ContactForm
          nameChangable={nameChangable}
          contact={contact}
          onSave={onSave}
          onClose={onClose}
        />
      }
    </Modal>
  )
}

export default EditContact
