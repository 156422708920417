import _ from "lodash"
import React, { useState, useEffect } from "react"

type RadioOption = { value: any, label: string }

type Props = {
  name?: string
  options: RadioOption[]
  stacked?: boolean
  value?: any
  onChange?(value: any): void
}

const RadioInput = ({ name, options, stacked, value, onChange }: Props) => {
  const [selected, setSelected] = useState<RadioOption>({ value: undefined, label: "" })

  useEffect(() => {
    if (value || value === false) {
      setSelected(_.find(options, opt => opt.value === value) || {})
    }
  }, [value])

  return (
    <div className={`flex mv3 select-none ${stacked ? 'flex-column items-start': 'items-center'}`}>
      {options.map(option => (
        <div className={`option pointer flex items-center ${stacked ? 'mb4': 'mr5'}`} key={`opt-${option.value}`} onClick={() => {
          setSelected(option)
          if (onChange) onChange(option.value)
        }}>
          <span className={`circle flex justify-stretch pa2 bg-white ba bw1 ${option.value === selected.value ? 'b--near-black' : 'b--silver'} br-100`} style={{ width: 26, height: 26 }}>
            {option.value === selected.value &&
              <span className="db w-100 br-100 bg-near-black"></span>
            }
          </span>
          <span className="label ml3">{option.label}</span>
        </div>
      ))}
      <input type="hidden" name={name} value={selected.value || ""} />
    </div>
  )
}

export default RadioInput
