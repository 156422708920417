import { times, map, isNil } from "lodash"
import { isBefore, addMonths, parse as parseDate, format as formatDate } from "date-fns"

type ValueObject = {
  value: number
  category: string
  id?: number
  asset?: boolean
  title?: string
  type: "grouped" | "single" | string
}

export type MonthlyAsset = {
  date: string
  label?: string
  property?: ValueObject
  vehicle?: ValueObject
  insurance?: ValueObject
  pension?: ValueObject
  mortgage?: ValueObject
  bank_loan?: ValueObject
  credit_card?: ValueObject
  store_card?: ValueObject
  student_loan?: ValueObject
  debt?: ValueObject
  npv?: ValueObject
  life?: ValueObject
  health?: ValueObject
  home?: ValueObject
  income_protection?: ValueObject
}

export type AssetValue = {
  id: number
  category: string
  title?: string
  asset?: boolean
  sub_type? :string
  sub_type_title?: string
  valuations: Valuations
  estimations?: Valuations
}

type Valuations = {
  date: string, value: number
}[]

export function assetValueAtDateRange(valuations: Valuations, month: number, year: number, forceValue?: boolean): number | null {
  const atTime = new Date(year, month - 1, 28)

  const found = valuations.find(({ date, value }) => {
    const compare = parseDate(date)

    if (isBefore(compare, atTime)) return true
    return false
  })

  if (found) return found.value

  if (forceValue && valuations[valuations.length - 1]) return valuations[valuations.length - 1].value

  return null
}

export function formatStatsForDateRange(data: AssetValue[], fromDate: Date, forMonths: number, forceValue?: boolean) {
  let nextDate = fromDate
  let byMonth = {}
  times(forMonths, index => {
    if (index > 0) {
      nextDate = addMonths(fromDate, index)
    }

    const key = formatDate(nextDate, "YYYY-MM")

    byMonth[key] = {}

    data.forEach(asset => {
      const value = assetValueAtDateRange(
        asset.valuations,
        nextDate.getMonth() + 1,
        nextDate.getFullYear(),
        forceValue
      )
      const estimation = assetValueAtDateRange(
        asset.estimations || [],
        nextDate.getMonth() + 1,
        nextDate.getFullYear(),
        forceValue
      )

      // Store grouped values
      if (!byMonth[key][asset.category]) {
        byMonth[key][asset.category] = {
          value,
          estimation,
          category: asset.category,
          type: "grouped"
        }
      } else if (value != null) {
        byMonth[key][asset.category].value = byMonth[key][asset.category].value + value
      } else if (estimation != null) {
        byMonth[key][asset.category].estimation = byMonth[key][asset.category].estimation + estimation
      }

      // Store sub category values
      if (!byMonth[key][asset.sub_type]) {
        byMonth[key][asset.sub_type] = {
          value,
          estimation,
          category: asset.sub_type,
          type: "sub_category"
        }
      } else if (value != null) {
        byMonth[key][asset.sub_type].value = byMonth[key][asset.sub_type].value + value
      } else if (estimation != null) {
        byMonth[key][asset.sub_type].estimation = byMonth[key][asset.sub_type].estimation + estimation
      }

      // Store individual values
      byMonth[key][asset.title] = {
        value,
        estimation,
        category: asset.category,
        title: asset.title,
        id: asset.id,
        asset: asset.asset,
        type: "single"
      }
    })
  })

  return map(byMonth, (value, date) => ({
    ...value,
    date
  }))
}

export function combineDebt(data: MonthlyAsset[]): MonthlyAsset[] {
  return data.map(month => ({
    ...month,
    debt: {
      title: "Debt",
      category: "debt",
      type: "grouped",
      value: totalDebts(month)
    }
  }))
}

export function calculateNPV(data: MonthlyAsset[]): MonthlyAsset[] {
  return data.map(month => ({
    ...month,
    npv: {
      title: "NPV",
      category: "npv",
      type: "grouped",
      value: isNil(totalAssets(month)) ? null : totalAssets(month) - totalDebts(month)
    }
  }))
}

export function getValues(data: AssetValue[], category: string) {
  return data.filter(asset => asset.hasOwnProperty('category') && asset.category === category && asset.valuations.length)
    .map(asset => {
      return {
        key: 'category ' + asset.id,
        label: asset.title,
        value: asset.valuations[0].value
      }
    })
}

export function getSubTypeValues(data: AssetValue[], sub_type: string) {
  return data.filter(asset => asset.hasOwnProperty('sub_type') && asset.sub_type === sub_type && asset.valuations.length)
    .map(asset => {
      return {
        key: 'sub-type ' + asset.id,
        label: asset.title,
        value: asset.valuations[0].value
      }
    })
}

export function addLabels(data: MonthlyAsset[]): MonthlyAsset[] {
  return data.map(month => ({
    ...month,
    label: formatDate(parseDate(month.date), "MMM YY")
  }))
}

function totalAssets(month: MonthlyAsset): number {
  if (isNil(month.property && month.property.value) && isNil(month.pension && month.pension.value) && isNil(month.insurance && month.insurance.value) && isNil(month.vehicle && month.vehicle.value)) {
    return null
  } else {
    return ((month.property && month.property.value) || 0) + ((month.pension && month.pension.value) || 0) + ((month.insurance && month.insurance.value) || 0) + ((month.vehicle && month.vehicle.value) || 0)
  }
}

function totalDebts(month: MonthlyAsset): number {
  if (
    isNil(month.bank_loan && month.bank_loan.value) &&
    isNil(month.credit_card && month.credit_card.value) &&
    isNil(month.store_card && month.store_card.value) &&
    isNil(month.student_loan && month.student_loan.value) &&
    isNil(month.mortgage && month.mortgage.value)
  ) {
    return null
  } else {
    return ((month.bank_loan && month.bank_loan.value) || 0) +
      ((month.credit_card && month.credit_card.value) || 0) +
      ((month.store_card && month.store_card.value) || 0) +
      ((month.student_loan && month.student_loan.value) || 0) +
      ((month.mortgage && month.mortgage.value) || 0)
  }
}
