import { find, each, reduce } from "lodash"
import React, { useState } from "react"
import Turbolinks from "turbolinks"

import { dictionary } from "../../../helpers/types"
import { putRequest } from "../../../helpers/api"
import { formatCurrencyShort } from "../../../helpers/string"
import Modal from "../../Modal"
import RadioInput from "../../inputs/RadioInput"
import PropertyTypeSelector from "./PropertyTypeSelector"
import AddressFinder, { buildAddress, AddressInfo } from "../../AddressFinder"

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
  mortgages: dictionary[]
}

function getInitialValues(values: dictionary): dictionary {
  const keys = [
    "asset_sub_type", "address_line_1", "address_line_2",
    "address_town", "address_postcode", "address_country",
    "mortgage", "mortgage_id", "main_residence", "renting_to_tenant"
  ]

  return reduce(keys, (memo, key) => {
    memo[key] = values[key]
    return memo
  }, {})
}

const EditForm = ({ assetId, mortgages, open, values, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values) })

  return (
    <Modal title="Edit Property" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          each(form, (value, field) => {
            if (field === "address_line_2") return
            if (field === 'mortgage_id') return
            if (field === 'renting_to_tenant' && form.main_residence === true) return
            if (value == null || value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <PropertyTypeSelector value={form.asset_sub_type} onChange={asset_sub_type => setValues({ ...form, asset_sub_type })} />
        {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}

        <AddressFinder
          errors={errors}
          value={form as AddressInfo}
          onChange={address => {
            setValues({ ...form, ...address })
          }}
        />

        <div className="form-field -maxlength">
          <label>Is there a mortgage on this property?</label>
          <RadioInput
            value={form.mortgage}
            onChange={mortgage => setValues({ ...form, mortgage })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "mortgage") && <span className="hint -error">{find(errors, row => row.field === "mortgage").errors.join(", ")}</span>}
        </div>

        {(mortgages.length > 0 && form.mortgage === true) &&
          <div className="form-field -maxlength">
            <label htmlFor="property_id">Which mortgage belongs to this property?</label>
            <select id="mortgage_id" value={form.mortgage_id || ""} onChange={e => setValues({ ...form, mortgage_id: e.target.value })}>
              <option value="">Please select</option>
        {mortgages.map(mortgage => <option key={mortgage.id} value={mortgage.id}>{mortgage.title} {formatCurrencyShort(mortgage.current_value)}</option>)}
            </select>
            {find(errors, row => row.field === "mortgage_id") && <span className="hint -error">{find(errors, row => row.field === "mortgage_id").errors.join(", ")}</span>}
          </div>
        }

        <div className="form-field -maxlength">
          <label>Is this property your main residence?</label>
          <RadioInput
            value={form.main_residence}
            onChange={main_residence => setValues({ ...form, main_residence, renting_to_tenant: false })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "main_residence") && <span className="hint -error">{find(errors, row => row.field === "main_residence").errors.join(", ")}</span>}
        </div>

        {form.main_residence === false &&
          <div className="form-field -maxlength">
            <label>Are you renting this property to a tenant?</label>
            <RadioInput
              value={form.renting_to_tenant}
              onChange={renting_to_tenant => setValues({ ...form, renting_to_tenant })}
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
            />
            {find(errors, row => row.field === "renting_to_tenant") &&
              <span className="hint -error">
                {find(errors, row => row.field === "renting_to_tenant").errors.join(", ")}
              </span>
            }
          </div>
        }

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
