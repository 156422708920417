import React, { useState } from "react"
import humanize from "string-humanize"
import { connect } from "react-refetch"
import { parse as parseDate, format as formatDate } from "date-fns"

import { deleteRequest } from "../../helpers/api"
import PrimaryCTA from "../PrimaryCTA"
import MoreMenu from "../MoreMenu"
import ContactList from "./ContactList"
import AddTrustedFriend from "./AddTrustedFriend"
import EditContact from "./EditContact"

const ManageWill = ({ documents, contactsFetch, reloadContacts }) => {
  const [activeTab, setActiveTab] = useState("trusted_friend")
  const [showAddModal, setShowAddModal] = useState(false)
  const [editing, setEditing] = useState(null)

  return (
    <>
      <PrimaryCTA id="will-primary-cta">
        <MoreMenu
          icon={({ toggleMenu }) => (
            <span className="button -primary -fullwidth border-box tc mb4 mb0-ns" onClick={toggleMenu}>View will</span>
          )}
        >
          {({ close }) => (
            <>
              {documents.map((document, index) => (
                <div key={document.id} className={(index + 1) >= documents.length ? "" : "mb3"}>
                  <a href={document.file_url} target="_blank" className="db near-black nowrap no-underline dim fw6 f6">
                    {humanize(document.document_type)}

                    <span className="fw4 light-silver ml2">
                      {formatDate(parseDate(document.document_date || document.created_at), "Do MMM YY")}
                    </span>
                  </a>
                </div>
              ))}
            </>
          )}
        </MoreMenu>
      </PrimaryCTA>

      <ul className="tab-bar">
        <li className={activeTab === "trusted_friend" ? "active" : ""}>
          <a href="#" onClick={e => {
            e.preventDefault()
            setActiveTab("trusted_friend")
          }}>Trusted Friend</a>
        </li>

        <li className={activeTab === "executor" ? "active" : ""}>
          <a href="#" onClick={e => {
            e.preventDefault()
            setActiveTab("executor")
          }}>Executors</a>
        </li>

        <li className={activeTab === "trustee" ? "active" : ""}>
          <a href="#" onClick={e => {
            e.preventDefault()
            setActiveTab("trustee")
          }}>Trustees</a>
        </li>

        <li className={activeTab === "beneficiary" ? "active" : ""}>
          <a href="#" onClick={e => {
            e.preventDefault()
            setActiveTab("beneficiary")
          }}>Beneficiaries</a>
        </li>
      </ul>

      {contactsFetch.pending &&
        <span>Loading...</span>
      }

      {contactsFetch.rejected &&
        <span>Error loading contacts, try again.</span>
      }

      {contactsFetch.fulfilled &&
        <ContactList
          contacts={contactsFetch.value.data.filter(contact => contact.role === activeTab)}
          onEdit={contact => setEditing(contact)}
          onDelete={async contact => {
            await deleteRequest(`/api/contacts/${contact.id}`)
            reloadContacts()
          }}
        />
      }

      {activeTab === "trusted_friend" &&
        <button className="button -primary mt5" onClick={() => setShowAddModal(true)}>Add a Trusted Friend</button>
      }

      <EditContact
        nameChangable={activeTab === "trusted_friend"}
        open={editing}
        contact={editing}
        onSave={reloadContacts}
        onClose={() => setEditing(null)}
      />

      <AddTrustedFriend
        open={showAddModal}
        onSave={reloadContacts}
        onClose={() => setShowAddModal(false)}
      />
    </>
  )
}

export default connect(props => ({
  contactsFetch: `/api/contacts`,
  reloadContacts: () => ({
    contactsFetch: {
      url: `/api/contacts`,
      force: true,
      refreshing: true,
    },
  })
}))(ManageWill)
