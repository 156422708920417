import React, { useState, ReactNode } from "react"
import { formatCurrency } from "../../helpers/string"
import backArrow from '../../../assets/images/icons/arrow-left.svg'

type CircleItem = {
  key: string
  label: string
  value: number
}

type Props = {
  zIndex: number
  hideBackButton?: boolean
  pulsate?: boolean
  onClick(key: string): void
  options: {
    key: string
    label: string
    value: number
    items?: CircleItem[]
  }[]
}

const CircleGroup: React.FC<Props> = ({ zIndex, options, onClick, hideBackButton, pulsate }) => {
  return (
    <div className="circle-group" style={{ zIndex }}>
      {!hideBackButton && <a className="backButton" onClick={() => onClick(null)}>
        <span className="icon">
          <img src={backArrow} />
        </span>
        Back
      </a>}
      {options.map<ReactNode>((obj, index) => (
        <div
          key={obj.key}
          onClick={() => onClick(obj.key)}
          className={
            `${obj.key} circle -n${index} ` +
              `${index === (options.length - 1) ? '-inner' : '-outer'} ` +
              `${(pulsate && index === 0) ? 'pulse' : ''}`
          }
          style={{zIndex: index - 3}}
        >
          <div className="text">
            <span className="label">{obj.label}</span>
            <span className="amount">{formatCurrency(obj.value)}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CircleGroup
