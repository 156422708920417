import React, { useState, useEffect } from "react"

import NPV from "./NPV"

import Radial from "./Radial"
import Loading from "./Loading"
import { getRequest } from "../helpers/api"
import { AssetValue } from "../helpers/stats"

type Props = {
}

const EndUserDashboard: React.FC<Props> = ({}) => {
  const [data, setData] = useState<AssetValue[]>(null)

  useEffect(() => {
    async function getData() {
      const resp = await getRequest("/api/assets/stats", null)
      const json = await resp.json()
      setData(json.data)
    }
    getData()
  }, [])

  if (!data) return <Loading wrapperClassName="mv6 justify-center" label="Loading dashboard..." />

  return (
    <div className="mb7">
      <NPV data={data} className="dashboard-npv" />
      <Radial data={data} />
    </div>
  )
}

export default EndUserDashboard
