import { find } from "lodash"
import React, { useState } from "react"

import MoneyInput from "../../inputs/MoneyInput"
import BackArrow from "../../BackArrow"

import { postRequest } from "../../../helpers/api"

const initialValues = {
  asset_sub_type: "",
  provider_name: "",
  current_value: ""
}

const StoreCard = ({ onBack, onSuccess, hideTitle }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({})
  const [form, setValues] = useState({ ...initialValues })

  return (
    <>
      {!hideTitle &&
        <>
          <BackArrow onClick={onBack} />
          <h1 className="page-title">Add store card</h1>
        </>
      }

      <form
        onSubmit={async e => {
          e.preventDefault()

          if (isSaving) return

          setIsSaving(true)

          console.log("Submit form", form)

          const resp = await postRequest(`/api/assets`, { asset_category: "store_card", ...form })
          const json = await resp.json()

          if (resp.status === 201) {
            console.log("success", json)
            onSuccess(json.data)
          } else {
            console.log("failed resp", resp)
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="form-field -maxlength">
          <label htmlFor="provider_name">Provider name</label>
          <input type="text" id="provider_name" placeholder="e.g. Next" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
          {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Amount</label>
          <MoneyInput id="current_value" defaultValue={form.current_value} onChange={current_value => setValues({ ...form, current_value })} />
          {find(errors, row => row.field === "current_value") && <span className="hint -error">{find(errors, row => row.field === "current_value").errors.join(", ")}</span>}
        </div>

        <div className="flex items-center">
          <button disabled={isSaving} type="submit" className="button -primary mr3">
            {isSaving ? 'Saving...' : 'Add store card'}
          </button>
          <button disabled={isSaving} onClick={onBack} className="button">Cancel</button>
        </div>
      </form>
    </>
  )
}

export default StoreCard
