import React, { useState } from "react"
import { postRequest } from "../../helpers/api"

const Card = ({ friend }) => {
  const [addState, setAddState] = useState("default")

  const avatar = friend.trusted_friend.avatar

  return (
    <div className="mr4 ba b--light-gray br2 pa3 tc" style={{ minWidth: 190 }}>
      <div className="flex justify-center">
        {avatar ?
          <span className="avatar" style={{ backgroundImage: `url('${avatar}')` }}></span>
        :
          <span className="avatar flex items-center justify-center"><i className="material-icons">person</i></span>
        }
      </div>
      <h5>{friend.full_name}</h5>

      <button
        className={`button ${addState !== "requested" && "-primary"}`}
        disabled={addState !== "default"}
        onClick={async () => {
          setAddState("pending")
          const resp = await postRequest("/api/contacts", friend)
          const json = await resp.json()
          console.log("Trusted Friend added.", json)
          setAddState("requested")
        }}
      >
        {addState === "default" && "Add trusted friend"}
        {addState === "pending" && "Inviting..."}
        {addState === "requested" && "Request sent"}
      </button><br />
      {addState === "default" &&
        <span className="db f6 gray underline pointer tc mt3">Ignore</span>
      }
    </div>
  )
}

export default Card
