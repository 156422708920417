import React from "react"

interface Props {
  image_url: string
  image_ratio: number
}

interface State {
  enlarged: boolean
}

export default class ImagePreview extends React.Component<Props, State> {
  private containerRef = React.createRef<HTMLDivElement>()

  constructor(props) {
    super(props)

    this.state = {
      enlarged: false
    }
  }

  toggleImageEnlarge = () => {
    this.setState({ enlarged: !this.state.enlarged })
  }

  render() {
    const { image_url, image_ratio } = this.props
    const { enlarged } = this.state

    return (
      <>
        <div className={"image-preview-container"}
             onClick={this.toggleImageEnlarge}
             ref={this.containerRef}>
          <div className="image-preview-image-container"
               onClick={this.toggleImageEnlarge}
               style={{
                 backgroundImage: `url(${image_url}`,
                 paddingTop: `${image_ratio ? 100 / image_ratio : 141}%`
               }}
          />
        </div>
        {enlarged &&
          <div className="image-preview-container -enlarged"
               onClick={this.toggleImageEnlarge}
               ref={this.containerRef}>
            <div className="image-preview-image-container"
                 onClick={this.toggleImageEnlarge}
                 style={{
                   backgroundImage: `url(${image_url}`,
                   paddingTop: `${image_ratio ? 100 / image_ratio : 141}%`
                 }}
            />
          </div>
        }
      </>
    )
  }
}
