import { truncate, strRightBack, strLeftBack } from "underscore.string"
import stringHumanize from "string-humanize"
import NumberHelpers from "number_helpers"

type StringyNumber = number | string

export const rawNumber = (number: StringyNumber): StringyNumber => {
  number = parseFloat(String(number).replace(/[^-?0-9.]/g, ""))
  if (isNaN(number)) return ""
  return number
}

export const formatCurrencyShort = (number) => {
  return "£" + NumberHelpers.number_to_human(number, {
    space_label: false,
    labels: { thousand: 'K', million: 'M', billion: 'B' }
  }).replace(/\.000/, "")
}

export const formatCurrency = (number) => {
  number = rawNumber(number)
  if (number === "") return number
  number = Math.round(number)
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }).format(number)
}

export const truncateFilename = (filename: string, desiredLength: number): string => {
  const ext = strRightBack(filename, ".")
  const truncated = truncate(strLeftBack(filename, "."), desiredLength, "..")

  return `${truncated}.${ext}`
}

export const humanize = (value: string, special: { [s: string]: string } = {}): string => {
  if (special[value]) return special[value]
  return stringHumanize(value)
}

export default {
  truncateFilename,
  rawNumber,
  formatCurrency
}
