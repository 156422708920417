import _ from "lodash"
import React from "react"
import Dropzone from "react-dropzone"
import humanize from "string-humanize"
import filesize from "filesize"
import { getRequest, postRequest, deleteRequest } from "../../../helpers/api"
import { truncateFilename } from "../../../helpers/string"
import FileList from "./FileList"
import DateInput from "../DateInput"
import AutoCompleteInput from "../AutoCompleteInput";
import { DOCUMENT_TYPES } from "../../../constants/documents"
import { format as formatDate } from "date-fns"

export default class FileUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      documentType: this.props.defaultDocumentType || "",
      isUploading: false,
      filename: null,
      documentDate: "",
      existingDocs: [],
      error: "",
    }
  }

  componentWillMount() {
    this.fetchExistingDocs()
  }

  async fetchExistingDocs() {
    const { recordId, recordType, group, skipLoadingExistingDocs } = this.props

    if (skipLoadingExistingDocs) return

    const resp = await getRequest("/api/documents", {
      record_id: recordId,
      record_type: recordType,
      group
    })

    const respJson = await resp.json()

    this.setState({ existingDocs: respJson.data })
  }

  removeDocument = async (id) => {
    const { existingDocs } = this.state

    if (!confirm("Are you sure?")) return

    await deleteRequest(`/api/documents/${id}`)

    _.remove(existingDocs, { id })

    this.setState({ existingDocs })

    this.props.onDocumentRemoved && this.props.onDocumentRemoved()
  }

  handleClick = (e) => {
    const { documentTypeSelectionRequired, hasDatepicker } = this.props
    const { documentType, documentDate } = this.state

    if (documentTypeSelectionRequired && documentType === "") {
      e.preventDefault()
      return alert("Please select document type first")
    }
    if (hasDatepicker && documentDate === "") {
      e.preventDefault()
      return alert("Please input document date first")
    }
  }

  getCSRFToken = () => (
    document.querySelector('meta[name="csrf-token"]').content
  )

  uploadDocument = async (url, formData) => {
    return await fetch(url, {
      method: 'POST',
      headers: {
        "X-CSRF-Token": this.getCSRFToken()
      },
      credentials: "same-origin",
      body: formData
    })
  }


  onDrop = async (files) => {
    try {
      const { recordId, recordType, documentTypeSelectionRequired, hasDatepicker, group, onUploadComplete } = this.props
      const { documentType, documentDate, existingDocs } = this.state
      const file = files[0]

      if (documentTypeSelectionRequired && documentType === "") {
        return alert("Please select document type first")
      }
      if (hasDatepicker && documentDate === "") {
        return alert("Please input document date first")
      }

      this.setState({ isUploading: true, filename: file.name, error: null })
      this.props.onUploadStart && this.props.onUploadStart({ filename: file.name })

      const form = new FormData()
      form.append("file", file)
      form.append("record_id", recordId)
      form.append("record_type", recordType)
      form.append("document_type", documentType)
      form.append("group", group)
      if (hasDatepicker) {
        form.append("document_date",  documentDate)
      }

      const upload = await this.uploadDocument("/api/documents", form)
      const uploadJson = await upload.json()

      if (!upload.ok) {
        throw new Error(uploadJson.full_errors);
      }

      existingDocs.push(uploadJson.data)

      this.setState({ existingDocs, isUploading: false })

      onUploadComplete && onUploadComplete(uploadJson.data)
    } catch (error) {
      console.error("Error uploading file", error)
      this.setState({ isUploading: false, error: error.message })
    }
  }

  renderDropzone() {
    const { documentTypeSelectionRequired } = this.props
    const { filename, isUploading, documentType, error } = this.state
    const typeNeedsPicked = (documentTypeSelectionRequired && documentType === "")
    return (
      <React.Fragment>
        <Dropzone onDrop={this.onDrop} disabled={isUploading || typeNeedsPicked}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps()} className="flex items-center pa3 bg-white pointer b--silver ba" style={{ borderWidth: 2, borderRadius: 3 }}>
              <input {...getInputProps()} />
              <span className="icon -upload mr4"></span>
              {isUploading ?
                <span>Uploading {filename}...</span>
                :
                (isDragActive ? "Release to upload." : "Upload from computer")
              }
            </div>
          )}
        </Dropzone>
        {error && <span className="hint -error">{error}</span>}
        <span className="hint">File size must be less than 10mb</span>
      </React.Fragment>
    )
  }

  renderDocumentTypeInput() {
    const { documentTypeSelectionMethod, documentTypes, documentTypePlaceholder } = this.props
    const { documentType } = this.state

    if (documentTypeSelectionMethod === "text") {
      return (
        <input type="text"
               placeholder={documentTypePlaceholder}
               value={documentType}
               onChange={e => this.setState({ documentType: e.target.value })}
        />
      )
    } else if (documentTypeSelectionMethod === "document_type_autocomplete") {
      return (
        <AutoCompleteInput
          placeholder={documentTypePlaceholder || ""}
          value={documentType}
          onChange={value => this.setState({ documentType: value })}
          getData={setData => setData(DOCUMENT_TYPES)}
        />
      )
    } else {
      return (
        <select id="document_type"
                value={documentType}
                onChange={e => this.setState({ documentType: e.target.value })}
        >
          <option value="">Please select</option>
          {documentTypes.map(documentType => (
            <option key={documentType} value={documentType}>{humanize(documentType)}</option>
          ))}
        </select>
      )
    }
  }

  render() {
    const { label, single, documentTypeSelectionRequired, documentTypeSelectionMethod, documentTypes, hasDatepicker } = this.props
    const { existingDocs, documentType, documentDate } = this.state
    const allowMultiple = !single
    const showingSingleFile = single && existingDocs[0]
    const typeNeedsPicked = (documentTypeSelectionRequired && documentType === "")
    const dateNeedsPicked = (hasDatepicker && documentDate === "")
    return (
      <React.Fragment>
        <div className="flex flex-column flex-row-ns">
          <div className="form-field -maxlength w-50-ns pr4-ns" style={{ position: "relative" }}>
            {(!showingSingleFile && (typeNeedsPicked || dateNeedsPicked)) &&
              <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} onClick={this.handleClick}></div>
            }
            {label &&
              <label>{label}</label>
            }
            {allowMultiple && this.renderDropzone()}
            {single &&
              <React.Fragment>
                {showingSingleFile ?
                  <React.Fragment>
                    <div className="flex items-center pa3 bg-white b--silver ba" style={{ borderWidth: 2, borderRadius: 3 }}>
                      <i className="material-icons light-blue mr3">folder</i>
                      <div style={{ flex: 1 }}>
                        <a href={existingDocs[0].file_url} target="_blank">{truncateFilename(existingDocs[0].filename, 18)}</a><br />
                        <span className="gray f7">{filesize(existingDocs[0].size_in_bytes)}</span>
                      </div>
                      <i className="material-icons gray pointer ml3" onClick={() => this.removeDocument(existingDocs[0].id)}>close</i>
                    </div>
                  </React.Fragment>
                  :
                  this.renderDropzone()
                }
              </React.Fragment>
            }
          </div>
          {(!showingSingleFile && documentTypeSelectionRequired) &&
            <div className="form-field -maxlength w-25-ns pl4-ns">
              <label htmlFor="document_type">Document type</label>
              {this.renderDocumentTypeInput()}
            </div>
          }
          {(!showingSingleFile && hasDatepicker) &&
            <div className="form-field -maxlength flex-fill pl4-ns">
              <label htmlFor="document_type">Document date</label>
              <DateInput value={documentDate} onDayChange={date => this.setState({ documentDate: formatDate(date, 'YYYY-MM-DD') })} />
            </div>
          }
        </div>

        {(allowMultiple && existingDocs.length > 0) &&
          <FileList documents={existingDocs} onRemove={this.removeDocument} />
        }
      </React.Fragment>
    )
  }
}
