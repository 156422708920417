import React, { useState } from "react"
import { getRequest } from "../helpers/api"
import { formatCurrency } from "../helpers/string"

type Props = {
}

const PropertyValue: React.FC<Props> = ({}) => {
  const [data, setData] = useState(null)
  const [address, setAddress] = useState("")
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div className="flex faux-field">
        <input type="text" placeholder="Address" value={address} onChange={e => setAddress(e.target.value)} />
        <button
          className="button -primary ml4"
          onClick={async () => {
            setLoading(true)
            const resp = await getRequest("/api/property_value", { address })
            if (resp.status === 200) {
              const json = await resp.json()
              setData(json)
            }
            setLoading(false)
          }}
        >
          Fetch
        </button>
      </div>

      <div className="mt5">
        {loading && <p>Loading&hellip;</p>}
        {data && data.value &&
          <>
            <span className="db fw6 f3 mb3">{formatCurrency(data.value)}</span>
            {data.accuracy === "street" &&
              `Pricing based on average values in street`
            }
            {data.accuracy === "exact" &&
              `Pricing based on property estimate`
            }
            {data.accuracy === "area" &&
              `Pricing based on area zed index`
            }
          </>
        }
        {data && data.error &&
          <>
            <span className="db fw6 f3 mb3">Error</span>
            {data.error}
          </>
        }
      </div>
    </>
  )
}

export default PropertyValue
