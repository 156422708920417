import { find, without, map, intersection } from "lodash"
import React, { useState, useEffect } from "react"
import { connect } from "react-refetch"
import { parse as parseDate, format as formatDate } from "date-fns"
import Modal from "../Modal"
import DateInput from "../inputs/DateInput"
import humanize from "string-humanize"
import { getRequest, postRequest, putRequest} from "../../helpers/api"

const plainStatusUpdate = {
  subject: "",
  comments: "",
  recipients: [],
  attachment_ids: [],
  next_review_on: ""
}

const AddStatusUpdate = ({ existing, clientId, open, onSave, onClose, docsFetch }) => {
  const [contacts, setContacts] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({})
  const [status, setStatus] = useState({ ...plainStatusUpdate, ...existing })

  function toggleRecipients(ids) {
    ids.forEach(id => {
      if (status.recipients.includes(id)) {
        setStatus({ ...status, recipients: without(status.recipients, id) })
      } else {
        setStatus({ ...status, recipients: [...status.recipients, id] })
      }
    })
  }

  function addAllRecipients(ids) {
    setStatus({ ...status, recipients: [...status.recipients, ...ids] })
  }

  function removeAllRecipients(ids) {
    setStatus({ ...status, recipients: without(status.recipients, ...ids) })
  }

  async function loadContacts() {
    const resp = await getRequest(`/api/status_updates/contacts`, { client_id: clientId })
    const json = await resp.json()
    console.log("Resp came back", json)
    setContacts(json)
  }

  useEffect(() => { loadContacts() }, [clientId])

  return (
    <Modal title="Update status" open={open} onClose={onClose}>
      <div className="form-field">
        <label htmlFor="status_update_subject">Subject</label>
        <input type="text" id="status_update_subject" value={status.subject} onChange={e =>  setStatus({ ...status, subject: e.target.value })} />
        {find(errors, row => row.field === "subject") && <span className="hint -error">{find(errors, row => row.field === "subject").errors.join(", ")}</span>}
      </div>

      <div className="form-field">
        <label>Recipients</label>

        {map(contacts, (items, group) => {
          const allIds = items.map(item => Object.keys(item)[0])
          const allPicked = intersection(status.recipients, allIds).length === allIds.length

          return (
            <div key={group} className="mt3 mb3">
              <label>
                <input
                  type="checkbox"
                  className="mr3"
                  checked={allPicked}
                  onChange={e => {
                    if (allPicked) {
                      removeAllRecipients(allIds)
                    } else {
                      addAllRecipients(allIds)
                    }
                  }}
                />
                {humanize(group)}
              </label>
              <div className="pl4 pv2">
                {items.map(item => {
                  const id = Object.keys(item)[0]
                  const email = item[id]
                  return (
                    <label key={id} className="">
                      <input type="checkbox" className="mr3" checked={status.recipients.includes(id)} onChange={e => toggleRecipients([id])} />
                      {email}
                    </label>
                  )
                })}
              </div>
            </div>
          )
        })}

        {find(errors, row => row.field === "recipients") && <span className="hint -error">{find(errors, row => row.field === "recipients").errors.join(", ")}</span>}
      </div>

      <div className="form-field">
        <label htmlFor="status_update_comments">Comments</label>
        <textarea id="status_update_comments" rows={3} value={status.comments} onChange={e => setStatus({ ...status, comments: e.target.value })}></textarea>
        {find(errors, row => row.field === "comments") && <span className="hint -error">{find(errors, row => row.field === "comments").errors.join(", ")}</span>}
      </div>

      <div className="form-field">
        <label htmlFor="status_update_attachments">Attach document(s)</label>
        {docsFetch.pending &&
          <>
            Loading documents&hellip;
          </>
        }
        {docsFetch.fulfilled &&
          <>
            {docsFetch.value.data.map(document => (
              <label key={document.id}>
                <input
                  type="checkbox"
                  className="mr3"
                  checked={status.attachment_ids.includes(document.id)}
                  onChange={e => {
                    if (e.target.checked) {
                      setStatus({ ...status, attachment_ids: status.attachment_ids.concat([document.id]) })
                    } else {
                      setStatus({ ...status, attachment_ids: without(status.attachment_ids, document.id) })
                    }
                  }}
                />
                <span className="fw4">
                  {document.title || document.filename}
                </span>
                <span className="ml2 fw4 gray">
                  {formatDate(parseDate(document.document_date), "Do MMMM YY")}
                </span>
              </label>
            ))}
          </>
        }
      </div>

      <div className="form-field">
        <label htmlFor="">Next review on</label>
        <DateInput value={status.next_review_on} onDayChange={date => setStatus({ ...status, next_review_on: formatDate(date, 'YYYY-MM-DD') })} />
        <span className="hint">You will receive a notification on due date</span>
        {`${status.next_review_on}`}
        {find(errors, row => row.field === "next_review_on") && <span className="hint -error">{find(errors, row => row.field === "next_review_on").errors.join(", ")}</span>}
      </div>

      <div className="flex">
        <button
          className="button -primary mr4"
          disabled={isSaving}
          onClick={async () => {
            setErrors({})

            setIsSaving(true)

            let resp
            if (existing) {
              resp = await putRequest(`/api/status_updates/${existing.id}`, {
                ...status,
                client_id: clientId
              })
            } else {
              resp = await postRequest(`/api/status_updates`, {
                ...status,
                client_id: clientId
              })
            }

            const json = await resp.json()
            setIsSaving(false)

            if (resp.status === 422) {
              setErrors(json.validation_errors)
              return
            }

            setStatus({ ...plainStatusUpdate })
            onSave(json)
            onClose()
          }}
        >
          { isSaving ? "Saving..." : "Update status" }
        </button>
        <button onClick={onClose} className="button">Cancel</button>
      </div>
    </Modal>
  )
}

export default connect(({ clientId, version }) => ({
  docsFetch: {
    url: `/api/documents?record_id=${clientId}&record_type=Client&group=probate&per_page=100`,
    comparison: `${clientId}:${version}`
  }
}))(AddStatusUpdate)
