import React, { useState } from 'react'
import Turbolinks from 'turbolinks'
import { find, reduce } from 'lodash'

import { dictionary } from '../../../helpers/types'
import { putRequest } from '../../../helpers/api'

import Modal from '../../Modal'
import MoneyInput from '../../inputs/MoneyInput'

interface FormError {
  field: string
  errors: string[]
}

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
}

function getInitialValues(values: dictionary): dictionary {
  const keys = ['account_reference_number', 'current_value']

  return reduce(keys, (memo, key) => {
    memo[key] = values[key]
    return memo
  }, {})
}

const EditForm = ({ assetId, open, values, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values) })

  async function onSubmit(event) {
    event.preventDefault()

    if (isSaving) {
      return
    } else {
      setIsSaving(true)
    }

    const response = await putRequest(`/api/assets/${assetId}`, form)
    const json = await response.json()

    if (response.status === 200) {
      Turbolinks.visit()
    } else {
      setErrors(json.validation_errors)
    }

    setIsSaving(false)
  }

  function renderFieldErrors(field: string, errors: FormError[]) {
    const fieldErrors = find(errors, row => row.field === field)

    if (fieldErrors) {
      return <span className="hint -error">{fieldErrors.errors.join(', ')}</span>
    }
  }

  return (
    <Modal
      title="Edit student loan"
      open={open}
      onClose={onClose}
      showClose={true}
    >
      <form onSubmit={onSubmit}>
        <div className="form-field -maxlength">
          <label htmlFor="account_reference_number">Account reference number</label>

          <input
            type="text"
            id="account_reference_number"
            value={form.account_reference_number}
            onChange={(event) => {
              setValues({ ...form, account_reference_number: event.target.value })
            }}
          />

          {renderFieldErrors('account_reference_number', errors)}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Loan amount</label>

          <MoneyInput
            id="current_value"
            defaultValue={form.current_value}
            onChange={(current_value) => setValues({ ...form, current_value })}
          />

          {renderFieldErrors('current_value', errors)}
        </div>

        <div className="flex">
          <button
            type="submit"
            className="button -primary mr4"
            disabled={isSaving}
            children={isSaving ? 'Saving...' : 'Save changes'}
          />

          <button
            type="button"
            className="button"
            onClick={onClose}
            children="Cancel"
          />
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
