import React, { useRef, useState, ReactNode, CSSProperties } from "react"
import ReactDOM from 'react-dom'
import ClickOutside from "@phawk/react-clickoutside-component"

type IconFnProps = {
  toggleMenu(): void
}

type ChildrenFnProps = {
  close(): void
}

type Props = {
  children(obj: ChildrenFnProps): ReactNode
  icon(obj: IconFnProps): ReactNode
  style: CSSProperties
  menuStyle: CSSProperties
}

export default ({ children, icon, style, menuStyle }: Props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const buttonRef = useRef(null)

  const dropdownPositionStyles = () => {
    const position = buttonRef.current.getBoundingClientRect();
    return {
      left: position.right + window.scrollX,
      top: position.bottom + window.scrollY
    }
  }

  return (
    <div ref={buttonRef} className={`relative select-none`} style={style || {}}>
      {!icon ?
        <span
          className={`db pa2 ba br2 pointer flex justify-center ${menuOpen ? 'b--light-blue' : 'b--light-gray'}`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className="icon -more"></span>
        </span>
      :
        icon({ toggleMenu: () => setMenuOpen(!menuOpen) })
      }
      {menuOpen &&
        ReactDOM.createPortal(
          <div
            className="absolute"
            style={{ minWidth: 75, ...dropdownPositionStyles(), ...menuStyle }}>
            <ClickOutside onClickOutside={(event) => {
              // ignore click on actions button
              if (buttonRef.current.contains(event.target)) return
              setMenuOpen(false)
            }}>
            <div
              className="lh-copy bg-white pv3 ph4 z-1 shadow-3 br2 mt3"
              style={{ position: 'relative', display: 'inline-block', left: '-100%' }}
            >{children({ close: () => setMenuOpen(false) })}</div>
          </ClickOutside>
        </div>,
          document.body
        )
      }
    </div>
  )
}
