import { find, each, reduce } from "lodash"
import React, { useState, useEffect } from "react"
import Turbolinks from "turbolinks"
import Modal from "../../Modal"
import { putRequest } from "../../../helpers/api"
import AddressFinder, { buildAddress, AddressInfo } from "../../AddressFinder"

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: { [s: string]: string }
}

const initialValues = {
  landlord_name: "",
  landlord_phone: "",
  landlord_email: "",
  landlord_address_line_1: "",
  landlord_address_line_2: "",
  landlord_address_town: "",
  landlord_address_postcode: "",
  landlord_address_country: "",
}

const LandlordForm = ({ assetId, values, open, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...initialValues })

  useEffect(() => {
    const defaultValues = reduce(initialValues, (memo: { [s: string]: string }, _, key: string) => {
      if (values[key]) memo[key] = values[key]
      return memo
    }, {})

    setValues(defaultValues)
  }, [assetId])

  return (
    <Modal title="Landlord details" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          each(form, (value, field) => {
            if (field === "landlord_address_line_2") return
            if (value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="form-field">
          <label htmlFor="landlord_name">Landlord’s Name</label>
          <input type="text" id="landlord_name" value={form.landlord_name} onChange={e =>  setValues({ ...form, landlord_name: e.target.value })} />
          {find(errors, row => row.field === "landlord_name") && <span className="hint -error">{find(errors, row => row.field === "landlord_name").errors.join(", ")}</span>}
        </div>

        <div className="form-field">
          <label htmlFor="landlord_email">Email</label>
          <input type="email" id="landlord_email" value={form.landlord_email} onChange={e =>  setValues({ ...form, landlord_email: e.target.value })} />
          {find(errors, row => row.field === "landlord_email") && <span className="hint -error">{find(errors, row => row.field === "landlord_email").errors.join(", ")}</span>}
        </div>

        <div className="form-field">
          <label htmlFor="landlord_phone">Telephone</label>
          <input type="text" id="landlord_phone" value={form.landlord_phone} onChange={e =>  setValues({ ...form, landlord_phone: e.target.value })} />
          {find(errors, row => row.field === "landlord_phone") && <span className="hint -error">{find(errors, row => row.field === "landlord_phone").errors.join(", ")}</span>}
        </div>

        <AddressFinder
          errors={errors}
          value={buildAddress({
            address_line_1: form.landlord_address_line_1,
            address_line_2: form.landlord_address_line_2,
            address_town: form.landlord_address_town,
            address_postcode: form.landlord_address_postcode,
            address_country: form.landlord_address_country
          })}
          onChange={address => {
            setValues({
              ...form,
              landlord_address_line_1: address.address_line_1,
              landlord_address_line_2: address.address_line_2,
              landlord_address_town: address.address_town,
              landlord_address_postcode: address.address_postcode,
              landlord_address_country: address.address_country
            })
          }}
        />

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default LandlordForm
