import { sortBy } from "lodash"
import React, { useMemo, useState } from "react"
import { subMonths } from "date-fns"
import { CSSTransition } from "react-transition-group"

import CircleGroup from "./CircleGroup"
import {
  formatStatsForDateRange,
  combineDebt,
  addLabels,
  calculateNPV,
  AssetValue,
  getSubTypeValues,
  getValues
} from "../../helpers/stats"

type Props = {
  data: AssetValue[]
}

const Radial: React.FC<Props> = ({ data }) => {
  const [screen, setScreen] = useState<string | null>(null)

  const latestMonth = useMemo(() => {
    const fromDate = subMonths(new Date(), 1)
    return addLabels(calculateNPV(combineDebt(formatStatsForDateRange(data, fromDate, 1, true))))[0]
  }, [])

  const assetValues = sortBy([
    {
      key: "property",
      label: "Property",
      value: (latestMonth.property && latestMonth.property.value) || null,
    },
    {
      key: "pension",
      label: "Pensions",
      value: (latestMonth.pension && latestMonth.pension.value) || null,
    },
    {
      key: "vehicle",
      label: "Vehicles",
      value: (latestMonth.vehicle && latestMonth.vehicle.value) || null,
    }
  ].filter(d => d.value && d.value > 0), d => -d.value)

  const propertyValues = getValues(data, "property")
  const pensionValues = getValues(data, "pension")
  const vehicleValues = getValues(data, "vehicle")

  const debtValues = sortBy([
    {
      key: "mortgage",
      label: "Mortgages",
      value: (latestMonth.mortgage && latestMonth.mortgage.value) || null,
    },
    {
      key: "bank_loan",
      label: "Bank loans",
      value: (latestMonth.bank_loan && latestMonth.bank_loan.value) || null,
    },
    {
      key: "credit_card",
      label: "Credit cards",
      value: (latestMonth.credit_card && latestMonth.credit_card.value) || null,
    },
    {
      key: "store_card",
      label: "Store cards",
      value: (latestMonth.store_card && latestMonth.store_card.value) || null,
    },
    {
      key: "student_loan",
      label: "Student loans",
      value: (latestMonth.student_loan && latestMonth.student_loan.value) || null,
    }
  ].filter(d => d.value && d.value > 0), d => -d.value)

  const mortgageValues = getValues(data, "mortgage")
  const bankLoanValues = getValues(data, "bank_loan")
  const creditCardValues = getValues(data, "credit_card")
  const storeCardValues = getValues(data, "store_card")
  const studentLoanValues = getValues(data, "student_loan")

  const insuranceValues = sortBy([
    {
      key: "life",
      label: "Life",
      value: (latestMonth.life && latestMonth.life.value) || null,
    },
    {
      key: "health",
      label: "Health",
      value: (latestMonth.health && latestMonth.health.value) || null,
    },
    {
      key: "home",
      label: "Home",
      value: (latestMonth.home && latestMonth.home.value) || null,
    },
    {
      key: "income_protection",
      label: "MIP",
      value: (latestMonth.income_protection && latestMonth.income_protection.value) || null,
    }
  ].filter(d => d.value && d.value > 0), d => -d.value)

  const lifeValues = getSubTypeValues(data, "life")
  const healthValues = getSubTypeValues(data, "health")
  const homeValues = getSubTypeValues(data, "home")
  const mipValues = getSubTypeValues(data, "income_protection")

  const dashboardData = {
    assets: assetValues,
    debt: debtValues,
    insurance: insuranceValues,
    property: propertyValues,
    pension: pensionValues,
    vehicle: vehicleValues,
    mortgage: mortgageValues,
    bank_loan: bankLoanValues,
    credit_card: creditCardValues,
    store_card: storeCardValues,
    student_loan: studentLoanValues,
    life: lifeValues,
    health: healthValues,
    home: homeValues,
    income_protection: mipValues
  }

  const renderCircleGroup = (dashboardData) => {
    return Object.keys(dashboardData).map((key, index) => {
      return <CSSTransition
        key={index}
        in={screen === key}
        timeout={1000}
        classNames="shrink"
        unmountOnExit
      >
        <CircleGroup
          zIndex={index}
          onClick={key => setScreen(screen === "assets" || screen === "debt" || screen === "insurance" ? key : null)}
          options={dashboardData[key]}
        />
      </CSSTransition>
    })
  }

  return (
    <div className="circle-overall-container ml-auto mr-auto">
      {screen === null && <CircleGroup
        hideBackButton
        pulsate
        zIndex={0}
        options={sortBy([
          {
            key: "insurance",
            label: "Insurance",
            value: (latestMonth.insurance && latestMonth.insurance.value) || 0,
            items: insuranceValues,
          },
          {
            key: "debt",
            label: "Debt",
            value: latestMonth.debt.value || 0,
            items: debtValues,
          },
          {
            key: "assets",
            label: "Assets",
            value: ((latestMonth.property && latestMonth.property.value) || 0) + ((latestMonth.vehicle && latestMonth.vehicle.value) || 0) + ((latestMonth.pension && latestMonth.pension.value) || 0),
            items: assetValues,
          }
        ], d => -d.value)}
        onClick={key => { if (dashboardData[key] && dashboardData[key].length) setScreen(key) }}
      />}
      {renderCircleGroup(dashboardData)}
    </div>
  )
}

export default Radial
