import { find, each, reduce } from "lodash"
import React, { useState } from "react"
import Turbolinks from "turbolinks"
import { format as formatDate } from "date-fns"

import { dictionary } from "../../../helpers/types"
import { putRequest } from "../../../helpers/api"
import Modal from "../../Modal"
import MoneyInput from "../../inputs/MoneyInput"
import DateInput from "../../inputs/DateInput"
import RadioInput from "../../inputs/RadioInput"

interface Props {
  assetId: number
  open: boolean
  onClose(): void
  values: dictionary
}

function getInitialValues(values: dictionary): dictionary {
  const keys = ["asset_sub_type","provider_name","current_value","policy_reference","policy_start_date","interest_rate", "mortgage_term", "property_id", "income_protection", "multiple_mortgages"]

  return reduce(keys, (memo, key) => {
    memo[key] = values[key]
    return memo
  }, {})
}

const EditForm = ({ assetId, open, values, onClose }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [form, setValues] = useState({ ...getInitialValues(values) })

  return (
    <Modal title="Edit mortgage" open={open} onClose={onClose} showClose={true}>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (isSaving) return
          setIsSaving(true)
          let errors = []

          each(form, (value, field) => {
            if (["policy_reference","policy_start_date","interest_rate", "mortgage_term", "property_id", "income_protection", "multiple_mortgages"].indexOf(field) > -1 ) return
            if (value == null || value.length < 1) {
              errors.push({ field, errors: ["is required"] })
            }
          })

          if (errors.length) {
            setErrors(errors)
            setIsSaving(false)
            return
          }

          const resp = await putRequest(`/api/assets/${assetId}`, form)
          const json = await resp.json()

          if (resp.status === 200) {
            Turbolinks.visit()
          } else {
            setErrors(json.validation_errors)
          }

          setIsSaving(false)
        }}
      >
        <div className="form-field -maxlength">
          <label htmlFor="asset_sub_type">Mortgage type</label>
          <select id="asset_sub_type" value={form.asset_sub_type} onChange={e => setValues({ ...form, asset_sub_type: e.target.value })}>
            <option value="capital">Capital</option>
            <option value="interest_only">Interest only</option>
            <option value="fixed">Fixed rate</option>
            <option value="discounted">Discounted rate</option>
            <option value="tracker">Tracker</option>
            <option value="svr">SVR (Standard variable rate)</option>
          </select>
          {find(errors, row => row.field === "asset_sub_type") && <span className="hint -error">{find(errors, row => row.field === "asset_sub_type").errors.join(", ")}</span>}
        </div>

        {form.asset_sub_type === "interest_only" &&
          <div className="form-field -maxlength">
            <label htmlFor="interest_rate">Interest rate</label>
            <input type="text" id="interest_rate" value={form.interest_rate || ""} onChange={e => setValues({ ...form, interest_rate: e.target.value })} />
            {find(errors, row => row.field === "interest_rate") && <span className="hint -error">{find(errors, row => row.field === "interest_rate").errors.join(", ")}</span>}
          </div>
        }

        <div className="form-field -maxlength">
          <label htmlFor="provider_name">Provider name</label>
          <input type="text" id="provider_name" placeholder="e.g. Santander" value={form.provider_name} onChange={e => setValues({ ...form, provider_name: e.target.value })} />
          {find(errors, row => row.field === "provider_name") && <span className="hint -error">{find(errors, row => row.field === "provider_name").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="current_value">Mortgage amount</label>
          <MoneyInput id="current_value" defaultValue={form.current_value} onChange={current_value => setValues({ ...form, current_value })} />
          {find(errors, row => row.field === "current_value") && <span className="hint -error">{find(errors, row => row.field === "current_value").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="policy_reference">Mortgage account number</label>
          <input type="text" id="policy_reference" value={form.policy_reference || ""} onChange={e => setValues({ ...form, policy_reference: e.target.value })} />
          {find(errors, row => row.field === "policy_reference") && <span className="hint -error">{find(errors, row => row.field === "policy_reference").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="mortgage_term">Mortgage term (years)</label>
          <input type="text" id="mortgage_term" value={form.mortgage_term || ""} onChange={e => setValues({ ...form, mortgage_term: e.target.value })} />
          {find(errors, row => row.field === "mortgage_term") && <span className="hint -error">{find(errors, row => row.field === "mortgage_term").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label htmlFor="policy_start_date">Date mortgage started</label>
          <DateInput value={form.policy_start_date || ""} onDayChange={date => setValues({ ...form, policy_start_date: formatDate(date, "YYYY-MM-DD") })} />
          {find(errors, row => row.field === "policy_start_date") && <span className="hint -error">{find(errors, row => row.field === "policy_start_date").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label>Do you have mortgage income protection?</label>
          <RadioInput
            value={form.income_protection}
            onChange={income_protection => setValues({ ...form, income_protection })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "income_protection") && <span className="hint -error">{find(errors, row => row.field === "income_protection").errors.join(", ")}</span>}
        </div>

        <div className="form-field -maxlength">
          <label>Are there multiple mortgages on this property?</label>
          <RadioInput
            value={form.multiple_mortgages}
            onChange={multiple_mortgages => setValues({ ...form, multiple_mortgages })}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
          {find(errors, row => row.field === "multiple_mortgages") && <span className="hint -error">{find(errors, row => row.field === "multiple_mortgages").errors.join(", ")}</span>}
        </div>

        <div className="flex">
          <button type="submit" className="button -primary mr4" disabled={isSaving}>
            { isSaving ? "Saving..." : "Save changes" }
          </button>
          <button onClick={onClose} className="button">Cancel</button>
        </div>
      </form>
    </Modal>
  )
}

export default EditForm
