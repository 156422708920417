import React, { useState } from "react"

import RadioInput from "../inputs/RadioInput"

const ProblemUpdate = ({ solicitor, onContinue }) => {
  const [reason, setReason] = useState("")
  const [error, setError] = useState(null)

  return (
    <div>
      <h1 className="page-title">How did you update your will?</h1>
      <RadioInput
        stacked
        name="will-problem-updated-reason"
        value={reason}
        onChange={value => setReason(value)}
        options={[
          { value: "updated_same_solicitor", label: `I updated my will with ${solicitor.name}` },
          { value: "updated_new_solicitor", label: "I updated my will with another solicitor" },
        ]}
      />
      {error &&
        <span className="dark-red db mv3">{error}</span>
      }
      <button onClick={() => {
        if (reason.length) {
          setError(null)
          onContinue(reason)
        } else {
          setError("Please select an option")
        }
      }} className="button -primary mt5">Continue</button>
    </div>
  )
}

export default ProblemUpdate
