import { startsWith } from "lodash"
import React, { useState, useEffect } from "react"
import Autosuggest from "react-autosuggest"
import Highlighter from "react-highlight-words"
import styled from "@emotion/styled"

const SuggestionsContainer = styled.div`
  background: #fff;
  position: relative;
  width: 100%;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

type setDataFn = (data: string[]) => void

type Props = {
  placeholder: string
  value: string
  filterString?: string
  onChange(value: string): void
  getData(setData: setDataFn): void
}

const AutoCompleteInput: React.FC<Props> = ({ value, placeholder, filterString, onChange, getData }) => {
  const [data, setData] = useState<string[]>([])
  const [suggestions, setSuggestions] = useState<string[]>([])

  useEffect(() => {
    getData(setData)
  }, [filterString])

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => {
        setSuggestions(data.filter(str => startsWith(str.toLowerCase(), value.toLowerCase())))
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([])
      }}
      getSuggestionValue={suggestion => suggestion}
      renderSuggestion={(suggestion, { query, isHighlighted }) => (
        <div className={`pa3 bb bl br b--light-gray ${isHighlighted && 'bg-near-white'}`}>
          <Highlighter
            highlightClassName="bg-light-yellow pa1 br2"
            searchWords={[query]}
            autoEscape={true}
            textToHighlight={suggestion}
          />
        </div>
      )}
      renderSuggestionsContainer={({ containerProps , children, query }) => (
          <SuggestionsContainer {... containerProps}>
            <div className="w-100 absolute bg-white z-1">
              {children}
            </div>
          </SuggestionsContainer>
      )}
      inputProps={{
        placeholder,
        value,
        onChange: (e, { newValue }) => onChange(newValue)
      }}
    />
  )
}

export default AutoCompleteInput
