import React, { useState, useEffect } from "react"
import { connect } from "react-refetch"
import { parse as parseDate, format } from "date-fns"
import Highlighter from "react-highlight-words"

import MoreMenu from "../MoreMenu"
import ContactsIcon from "../../../assets/images/icons/contacts.svg"

let firstRender = true

export const StatusList = ({ currentUserId, statusesFetch, performSearch, onEdit }) => {
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (firstRender) {
      firstRender = false
    } else {
      performSearch(searchTerm)
    }
  })

  if (statusesFetch.pending) {
    return (
      <div className="mv6 light-silver f3">
        Loading&hellip;
      </div>
    )
  } else if (statusesFetch.rejected) {
    return (<div>Error loading statuses, try again.</div>)
  } else if (statusesFetch.fulfilled) {
    const { data } = statusesFetch.value
    return (
      <React.Fragment>
        <div className="search-pill mb5 ba b--light-gray bg-white br-pill">
          <label htmlFor="update-search-box" className="flex items-center">
            <i className="material-icons blue">search</i>
            <input
              id="update-search-box"
              type="text"
              placeholder="Search status updates..."
              className="bn outline-0 br-pill"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </label>
        </div>
        {data.length < 1 &&
          <div className="mv6 light-silver f3">
            No updates have been added yet.
          </div>
        }
        {data.map(status => (
          <div key={status.id} className="content-box -tight mb5">
            <div className="inner">

              <h4>
                <Highlighter
                  highlightClassName="bg-light-yellow pa1 br2"
                  searchWords={searchTerm.split(" ")}
                  autoEscape={true}
                  textToHighlight={status.subject}
                />
              </h4>
              <p className="lh-copy">
                <Highlighter
                  highlightClassName="bg-light-yellow pa1 br2"
                  searchWords={searchTerm.split(" ")}
                  autoEscape={true}
                  textToHighlight={status.comments}
                />
              </p>
              {status.attachments.length > 0 &&
                <p>
                  {status.attachments.map(attachment => (
                    <a href={attachment.file_url} target="_blank" key={attachment.id} className="inline-flex items-center gray no-underline dim mr5 f6">
                      <i className="material-icons mr1">attach_file</i>
                      <span>{attachment.title || attachment.filename}</span>
                    </a>
                  ))}
                </p>
              }
            </div>
            <footer className="f6 lh-copy">
              <div className="flex items-center">
                <div className="mr3">
                  <strong>{status.user.full_name}</strong><br />
                  Posted {format(parseDate(status.created_at), "Do MMM YY")}
                  {status.next_review_on &&
                    <React.Fragment>&nbsp;&bull; Next Review Date {format(parseDate(status.next_review_on), "Do MMM YY")}</React.Fragment>
                  }
                  {status.user_id === currentUserId &&
                    <>
                      &nbsp;&bull;&nbsp;
                      <a
                        href="#"
                        className="no-underline dim f6"
                        onClick={e => {
                          e.preventDefault()
                          onEdit(status)
                        }}
                      >Edit</a>
                    </>}
                </div>
                {status.notifications_sent_to.length > 0 &&
                  <MoreMenu
                    style={{ flex: 1, minWidth: 39 }}
                    menuStyle={{ minWidth: 240, right: -10 }}
                    icon={({ toggleMenu }) => (
                      <div className="flex items-center justify-end pointer" title={`${status.notifications_sent_to.length} contacts notified.`} onClick={toggleMenu}>
                        <img src={ContactsIcon} alt="Contacts notified" className="mr3" /> {status.notifications_sent_to.length}
                      </div>
                    )}
                  >
                    {() => status.notifications_sent_to.map((contact, index) => (
                      <div key={contact} className={(index + 1) < status.notifications_sent_to.length ? "mb3" : ""}>
                        {contact}&nbsp;&nbsp;
                        <span className="light-silver">{format(parseDate(status.notifications_sent_at), "Do MMM YY")}</span>
                      </div>
                    ))}
                  </MoreMenu>
                }
              </div>
            </footer>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default connect(({ clientId, version }) => ({
  statusesFetch: {
    url: `/api/status_updates?client_id=${clientId}`,
    comparison: `${clientId}:${version}`
  },
  performSearch: (term) => ({
    statusesFetch: {
      url: `/api/status_updates?client_id=${clientId}&query=${term}`,
      comparison: `${clientId}:${term}:${version}`,
      refreshing: true
    },
  })
}))(StatusList)
