export const DOCUMENT_TYPES = [
  "Affidavit", "Appointment of Additional Administrator", "Codicil", "Death Certificate", "Decree Absolute",
  "Enduring Power of Attorney", "Ex parte application", "Grant of Probate", "Guarantee Bond",
  "Inheritance Tax Document", "Judges Order", "Lasting Power of Attorney", "Letters of Administration",
  "Masters Order", "Notice of Application", "Other Communication", "Power of Attorney", "Probate Engrossment",
  "Renunciation of Executor", "Retraction of Renunciation", "Sureties Guarantee", "Trust Corporation", "Will",
  "Bankruptcy Search", "Build Over Agreement", "Caretaker Agreement", "Companies Office Search", "Contract",
  "Covenants", "Deed of Assignment", "Department of the Environment Property Certificate",
  "Energy Performance Certificate", "Enforcement Judgement Office Search", "Fixtures and Fittings List",
  "Home Buyers Survey", "Land Certificate", "Land Registry Folio", "Land Registry Instrument",
  "Land Registry Map", "Lease", "Local Council Property Certificate", "Memorial", "Mortgage Deed",
  "Mortgage Offer", "National House Build Council Certificate", "NI Water Map",
  "Northern Ireland Co-ownership Housing Association", "Northern Ireland Housing Executive ",
  "Ordnance Survey Map (ACE Map)", "Other Communication", "Post contract enquires", "Pre-contract enquires",
  "Quotation", "Registered Assurance ", "Registered Fee Farm Grant", "Registered Fee Farm Lease",
  "Registered Fee Farm Transfer", "Registry of Deeds Search", "SDLT Forms", "Special Conditions ",
  "Statutory Charges Register Search", "Vacate", "Bank", "Beneficiary", "Broker", "Building Society",
  "Client", "Court Office", "Department of Environment Property Certificate", "Enforcement of Judgment Office",
  "Estate Agent", "Executor", "Financial Institution", "Friendly society", "Funeral Services", "HMRC",
  "Land Registry Northern Ireland", "Landlord", "Law Searchers", "Lender",
  "Local Council Property Certificate ", "Management Agent", "Other", "Probate Office", "Purchaser Solicitor",
  "Registry of Deeds", "Registry of Deeds Office", "Social Security Benefit",
  "Surveyor", "Trustee", "Vendor Solicitor"
]
